import {
  startOfMonth,
  getDay,
  getDate,
  getMonth,
  getYear,
  lastDayOfMonth,
  subDays,
  addDays,
  eachDayOfInterval,
  eachMonthOfInterval,
  isSameMonth,
  isToday,
  isBefore,
  isAfter,
  format,
  isMatch,
  isSameDay,
  isValid,
} from "date-fns";

import { Month, MonthDays } from "./types";

export const getMonths = (minDate: Date, maxDate: Date): Month[] => {
  const monthsArr: Month[] = [];
  if (minDate && maxDate) {
    const minMaxMonths = eachMonthOfInterval({
      start: minDate,
      end: maxDate,
    });

    minMaxMonths.forEach((month) => {
      monthsArr.push({
        month: getMonth(month) + 1,
        year: getYear(month),
      });
    });
  }
  return monthsArr;
};

export const getMonthDays = (monthDate: Date, minDate?: Date, maxDate?: Date): MonthDays => {
  let monthStartDate = startOfMonth(monthDate);
  let monthEndDate = lastDayOfMonth(monthDate);

  const daysNeededForLastMonth = (getDay(monthStartDate) + 6) % 7;
  const daysNeededForNextMonth = (7 - getDay(monthEndDate)) % 7;

  monthStartDate = subDays(monthStartDate, daysNeededForLastMonth);
  monthEndDate = addDays(monthEndDate, daysNeededForNextMonth);

  return eachDayOfInterval({ start: monthStartDate, end: monthEndDate }).map((date) => ({
    date,
    dateString: format(date, "yyyy-MM-dd"),
    label: getDate(date),
    isCurrentMonth: isSameMonth(monthDate, date),
    isToday: isToday(date),
    isDisabled:
      (!!minDate && !isSameDay(date, minDate) && isBefore(date, minDate)) || (!!maxDate && isAfter(date, maxDate)),
  }));
};

export const getIsInRange = (date: Date, startDate?: Date, endDate?: Date) => {
  if (startDate && endDate) {
    return isAfter(date, startDate) && isBefore(date, endDate);
  }
  return false;
};

export function dateToString(date: Date): string {
  return format(date, "d/M/y");
}

export function stringToDate(dateString: string): Date | undefined {
  if (dateString.length < 8 || !isMatch(dateString, "d/M/y")) {
    return undefined;
  }

  const splitDate = dateString.split("/");
  const date = new Date(parseInt(splitDate[2]), parseInt(splitDate[1]) - 1, parseInt(splitDate[0]));

  if (!isValid(date)) return undefined;

  return date;
}
