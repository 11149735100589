import {
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  subMonths,
  subQuarters,
  subWeeks,
} from "date-fns";

import { dateRange, DateRangePreset } from "./types";

export const defaultPresets: DateRangePreset[] = [
  dateRange({
    label: "This week",
    presetId: "THIS_WEEK",
    startDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
    endDate: new Date(),
  }),
  dateRange({
    label: "This month",
    presetId: "THIS_MONTH",
    startDate: startOfMonth(new Date()),
    endDate: new Date(),
  }),
  dateRange({
    label: "Last week",
    presetId: "LAST_WEEK",
    startDate: subWeeks(startOfWeek(new Date(), { weekStartsOn: 1 }), 1),
    endDate: subWeeks(endOfWeek(new Date(), { weekStartsOn: 1 }), 1),
  }),
  dateRange({
    label: "Last month",
    presetId: "LAST_MONTH",
    startDate: startOfMonth(subMonths(new Date(), 1)),
    endDate: endOfMonth(subMonths(new Date(), 1)),
  }),
  dateRange({
    label: "Last quarter",
    presetId: "LAST_QUARTER",
    startDate: startOfQuarter(subQuarters(new Date(), 1)),
    endDate: endOfQuarter(subQuarters(new Date(), 1)),
  }),
  dateRange({
    label: "Last 12 months",
    presetId: "LAST_12_MONTHS",
    startDate: subMonths(new Date(), 12),
    endDate: new Date(),
  }),
  dateRange({
    label: "All time",
    presetId: "ALL_TIME",
  }),
];
