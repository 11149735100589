import { isAfter, isBefore, isSameDay, startOfDay } from "date-fns";
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";

import useRefOf from "../../../../../../../shared/hooks/useRefOf";
import { dateToString, stringToDate } from "../../utils";
import "../DateRangeInput/styles.scss";

// These are form components: they can return invalid data but will mark themselves as invalid when they do.

export default function DateInput({
  value,
  onChange,
  required,
  minDate,
  maxDate,
  placeholder = "DD/MM/YYYY",
}: {
  value: Date | null;
  onChange: (value: Date | null) => void;
  required?: boolean;
  minDate?: Date;
  maxDate?: Date;
  placeholder?: string;
}) {
  const [text, setText] = useState("");
  const input = useRef<HTMLInputElement | null>(null);

  const textRef = useRefOf(text);

  // When the value prop is changed, update the input UNLESS the user is still working on it.
  useEffect(() => {
    if (document.activeElement === input.current) return;
    setText(value ? dateToString(value) : "");
  }, [textRef, value]);

  // When the text value is changed, update the returned value IF the text value can be parsed as a date.
  const update = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setText(e.target.value);
      if (!e.target.value) {
        onChange(null);
        return;
      }
      const parsedText = stringToDate(e.target.value);
      if (!parsedText) {
        input.current?.setCustomValidity("Invalid date format");
        return;
      }
      onChange(startOfDay(parsedText));
    },
    [onChange],
  );

  // When the value changes in either of the above ways, run validation.
  useEffect(() => {
    input.current?.setCustomValidity("");
    if (!value) return;
    if (minDate && isBefore(value, minDate)) {
      input.current?.setCustomValidity("Selected date is too early");
      return;
    }
    if (maxDate && isAfter(value, maxDate)) {
      input.current?.setCustomValidity("Selected date is too late");
      return;
    }
  }, [maxDate, minDate, value]);

  // When the component loses focus, rewrite the contents to the canonical format IF it doesn't change the meaning. (If it does change the meaning, the input ought to be invalid anyway.)
  const blur = useCallback(() => {
    if (!text || !value) return;
    const parsedText = stringToDate(text);
    if (!parsedText) return;
    if (isSameDay(parsedText, value)) {
      setText(dateToString(value));
    }
  }, [text, value]);

  return (
    <div className="c-date-range__input-wrapper c-date-range__input-wrapper--start-date">
      <input
        className="c-date-range__input"
        placeholder={placeholder}
        type="text"
        value={text}
        onChange={update}
        onBlur={blur}
        required={required}
        ref={input}
      />
    </div>
  );
}
