import MiniSearch, { SearchOptions } from "minisearch";
import { useMemo } from "react";

export interface SearchableItem {
  id: string;
}

export default function useSearch<T extends SearchableItem>({
  items,
  fields,
  searchText,
  alwaysShow,
  searchOptions,
  emptyWithNoSearchTerm,
}: {
  items: T[];
  fields: Array<keyof T>;
  searchText: string;
  alwaysShow?: string[];
  searchOptions?: SearchOptions & { initialsMatch?: boolean };
  emptyWithNoSearchTerm?: boolean;
}): T[] {
  // Build search engine
  const search = useMemo(() => {
    const search = new MiniSearch({
      fields: fields as string[],
      searchOptions: { prefix: true, fuzzy: 0.4, combineWith: "AND", ...(searchOptions ?? {}) },
    });
    search.addAll(items);
    return search;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(fields), items, JSON.stringify(searchOptions)]);
  // Filter items
  return useMemo(
    () => {
      if (searchText === "") {
        return emptyWithNoSearchTerm ? [] : items;
      }
      const matches: T[] = search.search(searchText).map((item) => items.find((i) => i.id === item.id)!);
      for (const item of items) {
        if (matches.includes(item)) continue;
        if (alwaysShow?.includes(item.id)) {
          matches.push(item);
          continue;
        }
        const rawSearch = searchText.toLowerCase(),
          initialQuery = rawSearch.replace(/[^a-z0-9]/g, "");
        if (searchOptions?.initialsMatch && rawSearch !== "") {
          for (const field of fields) {
            // @ts-ignore
            const fieldValue: string = item[field].toLowerCase();
            if (
              (initialQuery && matchInitials(initialQuery, fieldValue.split(/\s+/g))) ||
              fieldValue.startsWith(rawSearch)
            ) {
              matches.push(item);
              break;
            }
          }
        }
      }
      return matches;
    },
    // Don't updated the filter simply because "alwaysShow" changes — that just means selected items vanish when you
    // deselect them, which is more annoying than useful. Therefore:
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [emptyWithNoSearchTerm, search, searchText],
  );
}

// Checks if the query string is a plausible acronym for the full text.
// eg, for "University of Culture Shift", we might accept:
// "CS", "UCS", "UoCS", "UofCS", "UniCuSh" or "UCul"
// (case sensitive; we assume everything has been lowercased already)
function matchInitials(initials: string, words: string[]) {
  if (initials === "") return true;
  for (let i = 0; i < words.length; ++i) {
    if (initials[0] === words[i][0] && matchInitials(initials.substr(1), [words[i].substr(1), ...words.slice(i + 1)])) {
      return true;
    }
  }
  return false;
}
