import mixpanel from "mixpanel-browser";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import { FormCategory, ReportSearch } from "../../../../../__generated__/graphql";
import Divider from "../../../../../shared/components/design-system/Divider";
import { LabelHeading } from "../../../../../shared/components/design-system/InputLabel";
import ButtonSet from "../../../../../shared/components/design-system/component-groups/ButtonSet";
import {
  SectionFooter,
  SectionHeader,
} from "../../../../../shared/components/design-system/component-groups/section-header-footer";
import { useDebouncedValue } from "../../../../../shared/hooks/useDebounce";
import useRefOf from "../../../../../shared/hooks/useRefOf";
import { hasFilterChanged } from "../../../hooks/filtering/helpers";
import { FilterSettings } from "../../../hooks/filtering/useFilterFromUrlParams";
import useOrgData from "../../../hooks/useOrgData";
import { Column } from "../../../hooks/useReportFields";
import useSelf from "../../../hooks/useSelf";
import Button from "../Button";
import CollapsiblePanel from "../CollapsiblePanel";
import SearchInput from "../SearchInput";
import { SearchableList } from "../SearchableList";
import SidePanel, { ScrollableContent } from "../SidePanel/index";
import ToggleSwitch from "../ToggleSwitch";
import ChoiceFilter, { ChoiceSearchParamKeys } from "./ChoiceFilter";
import DateRangeFilter from "./DateRangeFilter";
import FormFilter from "./FormFilter";
import QuestionFilter from "./QuestionFilter";
import usePanels, { Panel } from "./usePanels";

export const filterSearchOptions = { fuzzy: false };

export default function FilterPanel({
  defaultPanels,
  showQuickFilters,
  className,
  settings,
  category,
}: {
  defaultPanels: Column[];
  showQuickFilters?: boolean;
  className?: string;
  settings: FilterSettings;
  category: FormCategory;
}) {
  const { filter, setFilter, clearFilters, defaults } = settings;
  const { outcomes } = useOrgData();
  const filterHasChanged = useMemo(() => hasFilterChanged(filter, defaults), [filter, defaults]);
  const [showAllFilters, setShowAllFilters] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string | null>(null);
  const debouncedSearchText = useDebouncedValue(searchText, 100);
  const searchApplied = debouncedSearchText ? debouncedSearchText.length > 2 : false;
  const self = useSelf();

  const panels = usePanels(
    // The search does its own filtering so mark everything as "shown" for that case.
    showAllFilters || searchApplied,
    defaultPanels.map((filter) => filter.id),
    !!filter.excludeSpam,
    settings,
    category,
  );

  // Arguably "showFilterPanel" should be passed up through the context,
  // but life is just way too short for that kind of pedantry.
  const { state } = useLocation() as { state?: { showFilterPanel?: boolean } };
  const [isOpen, setIsOpen] = useState(state?.showFilterPanel || false);

  const openFilterPanel = useCallback(async () => {
    mixpanel.track("Opened the filter panel");
    setIsOpen(true);
  }, []);

  const closeFilterPanel = useCallback(() => {
    mixpanel.track("Closed the filter panel");
    setIsOpen(false);
  }, []);

  const handleExcludeSpamChange = useCallback(
    (checked: boolean) => {
      mixpanel.track("Changed the exclude spam filter", { enabled: checked.toString() });
      const nextFilter = { ...filter, excludeSpam: checked };
      if (checked && nextFilter.outcome) {
        const spamOutcomeIds = outcomes.filter(({ suppressForAnalytics }) => suppressForAnalytics).map(({ id }) => id);
        nextFilter.outcome = nextFilter.outcome.filter((outcomeId) => !spamOutcomeIds.includes(outcomeId!));
      }
      setFilter(nextFilter);
    },
    [filter, setFilter, outcomes],
  );

  const quickFiltersStatus = useMemo(
    () => ({
      assignedToMe: filter.assignedTo?.length === 1 && filter.assignedTo[0] === self.id,
    }),
    [filter.assignedTo, self.id],
  );

  const handleToggleMyReports = useCallback(() => {
    mixpanel.track("Toggled the 'My reports' quick filter");
    setFilter({ ...filter, assignedTo: quickFiltersStatus.assignedToMe ? [] : [self.id] });
  }, [filter, setFilter, quickFiltersStatus.assignedToMe, self.id]);

  const [openPanels, setOpenPanels] = useState<string[]>([]);
  const [closedSearchPanels, setClosedSearchPanels] = useState<string[]>([]);

  useEffect(() => setClosedSearchPanels([]), [searchApplied]);

  // reset state when the panel opens (but not when the filter changes)
  const filterRef = useRefOf(filter);
  const panelsRef = useRefOf(panels);
  const defaultsRef = useRefOf(defaultPanels);
  useEffect(() => {
    if (isOpen) {
      const filter = filterRef.current;
      const panels = panelsRef.current;
      const defs = defaultsRef.current;
      setShowAllFilters(false);
      setClosedSearchPanels([]);
      setSearchText(null);
      const activeFilters = [
        ...Object.keys(filter).filter(
          (key) => !!filter[key as keyof ReportSearch] && key !== "excludeSpam" && key !== "question",
        ),
        ...(filter.questions ?? []).map(({ questionId }) => `question-${questionId}`),
      ].filter((id) => panels.some((panel) => panel.id === id));
      if (activeFilters.length) setOpenPanels(activeFilters);
      else if (defs.length < 3) setOpenPanels(defs.map((col) => col.id));
      else setOpenPanels([]);
    }
  }, [isOpen, defaultsRef, panelsRef, filterRef]);

  const handlePanelToggle = useCallback(
    (panelId: string) => (open: boolean) => {
      if (open) {
        setOpenPanels((openPanels) => [...openPanels, panelId]);
      } else {
        setOpenPanels((openPanels) => openPanels.filter((openPanel) => openPanel !== panelId));
      }
    },
    [],
  );

  const handlePanelToggleWhenSearchApplied = useCallback(
    (panelId: string) => (open: boolean) => {
      if (open) {
        setClosedSearchPanels((closedPanels) => closedPanels.filter((closedPanel) => closedPanel !== panelId));
      } else {
        setClosedSearchPanels((closedPanels) => [...closedPanels, panelId]);
      }
    },
    [],
  );

  const handleShowAllFilters = useCallback(() => {
    mixpanel.track("Selected show more filters");
    setShowAllFilters(true);
  }, []);

  const handleShowFewerFilters = useCallback(() => {
    mixpanel.track("Selected show fewer filters");
    setShowAllFilters(false);
  }, []);

  const handleClearAllFilters = useCallback(() => {
    mixpanel.track("Selected reset filters", { location: "Filter panel" });
    clearFilters();
  }, [clearFilters]);

  useEffect(() => {
    if (debouncedSearchText === "") {
      mixpanel.track("Cleared find a filter", { location: "Search field" });
    } else if (debouncedSearchText) {
      mixpanel.track("Changed find a filter");
    }
  }, [debouncedSearchText]);

  const handleClearSearch = useCallback(() => {
    mixpanel.track("Cleared find a filter", { location: "Button" });
    setSearchText(null);
  }, []);

  const searchTextToUse = searchApplied ? debouncedSearchText! : "";

  return (
    <>
      <Button variant="action" onClick={openFilterPanel} className={className} icon="filter">
        Set filters
      </Button>
      <SidePanel isOpen={isOpen} onClose={closeFilterPanel} closeOnClickOutside>
        <SectionHeader title="Filter reports" />
        <ScrollableContent>
          {showQuickFilters && (
            <>
              <LabelHeading>Quick filters</LabelHeading>
              <Button
                size="small"
                onClick={handleToggleMyReports}
                className="ds-mt-3"
                variant={quickFiltersStatus.assignedToMe ? "primary" : "secondary"}
              >
                My reports
              </Button>
              <Divider tight />
            </>
          )}

          <ToggleSwitch
            label="Hide spam reports"
            className="ds-mb-5"
            checked={filter.excludeSpam === true}
            onChange={handleExcludeSpamChange}
          />

          <div className="ds-mt-4">
            <SearchInput value={searchText ?? ""} onChange={setSearchText} placeholder="Find a filter" />
          </div>
          <SearchableList<Panel>
            items={panels}
            fields={["matchText"]}
            searchText={searchTextToUse}
            component={(panel: Panel) => (
              <>
                <CollapsiblePanel
                  id={panel.id}
                  title={panel.name}
                  isOpen={searchApplied ? !closedSearchPanels.includes(panel.id) : openPanels.includes(panel.id)}
                  onChange={searchApplied ? handlePanelToggleWhenSearchApplied(panel.id) : handlePanelToggle(panel.id)}
                >
                  {panel.id === "updatedAt" || panel.id === "createdAt" ? (
                    <DateRangeFilter filterKey={panel.id} filterSettings={settings} />
                  ) : panel.question ? (
                    <QuestionFilter
                      question={panel.question}
                      optionSearch={searchTextToUse}
                      filterSettings={settings}
                    />
                  ) : panel.forms ? (
                    <FormFilter optionSearch={searchTextToUse} filterSettings={settings} category={category} />
                  ) : panel.choices ? (
                    <ChoiceFilter
                      panelName={panel.name}
                      filterKey={panel.id as ChoiceSearchParamKeys}
                      optionSearch={searchTextToUse}
                      choices={panel.choices}
                      filterSettings={settings}
                    />
                  ) : null}
                </CollapsiblePanel>
              </>
            )}
            emptyComponent={<p className="filter-panel__no-filter-match">No matching filters found.</p>}
            searchOptions={filterSearchOptions}
          />
          <ButtonSet direction="vertical" className="ds-mt-3">
            {showAllFilters ? (
              <Button variant="ghost" onClick={handleShowFewerFilters}>
                Show fewer filters
              </Button>
            ) : (
              <Button variant="ghost" onClick={handleShowAllFilters}>
                Show more filters
              </Button>
            )}
          </ButtonSet>
        </ScrollableContent>
        <SectionFooter>
          {!searchApplied ? (
            <ButtonSet align="end" key="normal">
              <Button variant="ghost" onClick={handleClearAllFilters} disabled={!filterHasChanged}>
                Reset filters
              </Button>
              <Button variant="primary" onClick={closeFilterPanel}>
                Done
              </Button>
            </ButtonSet>
          ) : (
            <ButtonSet direction="vertical" key="searching">
              <Button variant="secondary" onClick={handleClearSearch} icon="arrowLeft" iconSide="left">
                Back
              </Button>
            </ButtonSet>
          )}
        </SectionFooter>
      </SidePanel>
    </>
  );
}
