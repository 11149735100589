import { Auth, CognitoUser } from "@aws-amplify/auth";
import * as Sentry from "@sentry/browser";
import { Hub } from "aws-amplify";
import React, { useCallback, useEffect, useState } from "react";

import ButtonLink from "../../../../../shared/components/design-system/Button/ButtonLink";
import LoadingPage from "../../global-furniture/LoadingPage";
import ConfirmForgotPasswordForm from "../ConfirmForgotPasswordForm";
import ConfirmSignInForm from "../ConfirmSignInForm";
import ForcePasswordChangeForm from "../ForcePasswordChangeForm";
import ForgotPasswordForm from "../ForgotPasswordForm";
import SignInForm from "../SignInForm";
import { SignInCard, SignInMain } from "./containers";
import "./styles.scss";

// These are the pages in the form
type FormState =
  | "loading"
  | "signIn"
  | "confirmSignIn"
  | "forcePasswordChange"
  | "forgotPassword"
  | "forgotPasswordConfirm";

const normaliseUsername = (username: string) => username.toLocaleLowerCase();

// Why is this all the way up here and not part of the React state? Well,
// Amplify does all of this very very early, and before our useEffects get
// set up so we have to catch it early if there was a SAML error
let samlError: Error | null = null;
// we can't use the real type as the signature here https://github.com/aws-amplify/amplify-js/issues/7188
Hub.listen("auth", (data: { payload: { event: string; data?: Error } }) => {
  if (data.payload.event === "cognitoHostedUI_failure") {
    samlError = data.payload.data!;
  }
});

export default function SignIn({ onSignedIn }: { onSignedIn: () => void }) {
  const [state, setState] = useState<FormState>("loading");
  const [toast, setToast] = useState<string | null>(null);
  const [username, setUsername] = useState<string | null>(null);
  const [user, setUser] = useState<CognitoUser | null>(null);
  const [isSigningIn, setIsSigningIn] = useState(false);

  useEffect(() => {
    document.title = "Culture Shift | Sign In";

    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          onSignedIn();
          setState("signIn");
        }
      })
      .catch(() => {
        if (samlError) {
          const message = decodeURIComponent(samlError.message.replaceAll("+", " "));
          setToast(message);
        }
        setState("signIn");
      });
  }, [onSignedIn]);

  const switchTo = useCallback((page: FormState) => {
    setState(page);
    setToast(null);
  }, []);

  const signIn = useCallback(
    async (username: string, password: string) => {
      setIsSigningIn(true);
      try {
        const user = await Auth.signIn(normaliseUsername(username), password);
        if (user.challengeName === "SMS_MFA" || user.challengeName === "SOFTWARE_TOKEN_MFA") {
          setUser(user);
          switchTo("confirmSignIn");
        } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          setUser(user);
          switchTo("forcePasswordChange");
        } else {
          onSignedIn();
          switchTo("signIn");
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        if (err.code === "PasswordResetRequiredException") {
          switchTo("forgotPasswordConfirm");
          setToast("Your password must be reset to continue.");
          setUsername(username);
          await Auth.forgotPassword(normaliseUsername(username));
        } else if (err.code === "NotAuthorizedException") {
          switchTo("signIn");
          setToast(
            "Your login attempt failed. This may be due to your password not being recognised, or the security system blocking the attempt for another reason.",
          );
        } else if (err.code === "UserNotFoundException") {
          switchTo("signIn");
          setToast("Your username was not recognised.");
        } else {
          switchTo("signIn");
          captureUnknownError(err);
        }
      }
      setIsSigningIn(false);
    },
    [onSignedIn, switchTo],
  );

  const submitMfaCode = useCallback(
    async (mfaCode: string, remember: boolean) => {
      try {
        await Auth.confirmSignIn(user, mfaCode, "SOFTWARE_TOKEN_MFA");
        if (remember) await Auth.rememberDevice();
        setState("signIn");
        onSignedIn();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        if (err.code === "CodeMismatchException") {
          setToast("Your authentication code wasn’t recognised.");
        } else if (err.code === "NotAuthorizedException") {
          // The case where this has come up is when the session is being reused illegally. I don't know how to make
          // that clearer because it's not clear enough that I know what it means, but the code is vague enough that
          // there are probably other reasons it will appear. Just show the message from AWS and pray we never have to translate it.
          setToast(err.message || "We were unable to authenticate your account, please try again later.");
        } else if (err.code === "PasswordResetRequiredException") {
          switchTo("forgotPassword");
          setToast("Your password must be reset to continue.");
        } else {
          captureUnknownError(err);
        }
      }
    },
    [onSignedIn, switchTo, user],
  );

  const startForgotPassword = useCallback(() => {
    switchTo("forgotPassword");
  }, [switchTo]);

  const sendForgotPasswordCode = useCallback(
    async (username: string) => {
      try {
        await Auth.forgotPassword(normaliseUsername(username));
        switchTo("forgotPasswordConfirm");
        setUsername(username);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        if (err.code === "UserNotFoundException") {
          setToast("Unable to send a verification code to this email address");
        } else {
          captureUnknownError(err);
        }
      }
    },
    [switchTo],
  );

  const resendForgotPasswordCode = useCallback(async () => {
    if (!username) {
      console.error("Attempted to re-send password code, but username was never set?");
      return;
    }

    try {
      await Auth.forgotPassword(normaliseUsername(username));
    } catch (err) {
      captureUnknownError(err);
    }
  }, [username]);

  const resetPassword = useCallback(
    async (verificationCode: string, newPassword: string) => {
      if (!username) {
        console.error("Attempted to reset password, but username was never set?");
        return;
      }

      try {
        await Auth.forgotPasswordSubmit(normaliseUsername(username), verificationCode, newPassword);
        setState("signIn");
        setToast("Your password has been changed, please log in using your new password");
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        if (err.code === "CodeMismatchException") {
          setToast("Your verification code wasn’t recognised.");
        } else if (err.code === "LimitExceededException") {
          setToast("You have made too many attempts. Please try again later.");
        } else if (err.code === "InvalidPasswordException") {
          setToast(
            "Your password must be at least eight characters, and must not be a dictionary word or a commonly used password.",
          );
        } else if (err.code === "NotAuthorizedException") {
          setToast(
            "Your initial temporary password has expired. Please contact an administrator to reset your password.",
          );
        } else {
          captureUnknownError(err);
        }
      }
    },
    [username],
  );

  const setForceChangedPassword = useCallback(
    async (newPassword: string) => {
      if (!user) {
        console.error("Attempted to force change password, but user was never set?");
        return;
      }

      try {
        await Auth.completeNewPassword(user, newPassword, {});
        setState("signIn");
        setToast("Your password has been changed, please log in using your new password");
      } catch (err) {
        // @ts-ignore
        if (err?.code === "InvalidPasswordException") {
          setToast(
            "Your password must be at least eight characters, and must not be a dictionary word or a commonly used password.",
          );
        } else {
          captureUnknownError(err);
        }
      }
    },
    [user],
  );

  const reset = useCallback(() => {
    switchTo("signIn");
  }, [switchTo]);

  if (state === "loading") {
    return <LoadingPage />;
  }

  if (state === "signIn") {
    return (
      <SignInForm onSignIn={signIn} isSigningIn={isSigningIn} toast={toast} startForgotPassword={startForgotPassword} />
    );
  }

  return (
    <SignInMain>
      <SignInCard toast={toast}>
        {state === "forgotPassword" ? <ForgotPasswordForm onSubmit={sendForgotPasswordCode} /> : null}
        {state === "forgotPasswordConfirm" ? (
          <ConfirmForgotPasswordForm onResend={resendForgotPasswordCode} onSubmit={resetPassword} />
        ) : null}
        {state === "confirmSignIn" ? <ConfirmSignInForm onSubmit={submitMfaCode} /> : null}
        {state === "forcePasswordChange" ? <ForcePasswordChangeForm onSubmit={setForceChangedPassword} /> : null}
      </SignInCard>
      <ButtonLink isQuiet isOverBg className="ds-mt-7" variant="secondary" onClick={reset}>
        &larr; Back to sign in
      </ButtonLink>
    </SignInMain>
  );

  function captureUnknownError(error: unknown) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const err: any = error;
    setToast(err instanceof Error ? err.message : "An unknown error occurred");
    Sentry.captureException(err instanceof Error ? err : new Error(err.message ?? "An unknown error occurred"), {
      fingerprint: ["{{ default }}", err.code ?? err.message],
      extra: { code: err.code },
    });
  }
}
