import { AnalyticsCode, AnalyticsSplit, ReportSearch } from "../../../../../../__generated__/graphql";
import {
  CustomGroupConfiguration,
  DimensionGroupConfiguration,
  InDetailChartOptions,
  OptionGroup,
} from "../../../../../../shared/components/charts/types";
import { AnalyticsQueryResult } from "../../useAnalyticsQuery";

export type SegmentIds = Omit<AnalyticsQueryResult, "segments" | "value">;

export function categoryMatchesFilter(
  category: AnalyticsQueryResult,
  filter: ReportSearch | undefined | null,
  split: AnalyticsSplit,
  options: InDetailChartOptions,
): boolean {
  if (category.groupId) return true;
  switch (split.code) {
    case AnalyticsCode.Form:
      if (!category.form && !options.showNotAnswered) return false;
      return !filter?.form || filter.form.includes(category.form?.id ?? "");
    case AnalyticsCode.Status:
      return !filter?.status || filter.status.includes(category.status!);
    case AnalyticsCode.Outcome:
      return !filter?.outcome || filter.outcome.includes(category.outcome?.id ?? null);
    case AnalyticsCode.Age:
    case AnalyticsCode.AnonymousReason:
    case AnalyticsCode.Disability:
    case AnalyticsCode.Ethnicity:
    case AnalyticsCode.Faculty:
    case AnalyticsCode.Location:
    case AnalyticsCode.Gender:
    case AnalyticsCode.Trans:
    case AnalyticsCode.IncidentType:
    case AnalyticsCode.Religion:
    case AnalyticsCode.Role:
    case AnalyticsCode.SexualIdentity:
    case AnalyticsCode.SuspectedCause:
    case AnalyticsCode.MessageOptIn:
    case AnalyticsCode.CustomQuestion:
      if (!category.optionId && !options.showNotAnswered) return false;
      if (!filter?.questions) return true;
      {
        const questionFilter = filter.questions!.find((filter) => filter.questionId === category.question?.id);
        if (!questionFilter) return true;
        return questionFilter.value.includes(category.optionId!);
      }
    case null:
      return false;
  }
}

function isNotAnswered(ids: SegmentIds) {
  return !ids.form && !ids.groupId && !ids.optionId && !ids.outcome && !ids.question && !ids.status;
}

function groupLabel(a: SegmentIds, { groups }: CustomGroupConfiguration) {
  return groups.find((g) => (g as OptionGroup).id === a.groupId)?.label ?? a.label;
}

export function order(a: SegmentIds, b: SegmentIds, groups: DimensionGroupConfiguration | null, split: AnalyticsSplit) {
  if (isNotAnswered(a)) return 1;
  if (isNotAnswered(b)) return -1;

  if (groups && split.groups && groups.basis !== "NONE") {
    if (a.groupId) {
      if (!b.groupId) return -1;
      if (groups.basis === "CUSTOM") {
        const aLabel = groupLabel(a, groups);
        const bLabel = groupLabel(b, groups);
        return aLabel.localeCompare(bLabel);
      }
      const aPos = split.groups.findIndex((g) => g.id === a.groupId);
      const bPos = split.groups.findIndex((g) => g.id === b.groupId);
      return aPos - bPos;
    }
    if (b.groupId) return 1;
  }

  return a.label.localeCompare(b.label);
}
