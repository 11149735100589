import classNames from "classnames";
import { format } from "date-fns";
import React, { FunctionComponent, useCallback } from "react";

import { Day } from "../../../../types";
import "./styles.scss";

interface CalendarDayProps {
  onClick?: (date: Date) => void;
  day: Day;
  onMouseEnter?: (day: Day) => void;
  onMouseLeave?: (day: Day) => void;
  isInRange?: boolean;
  isStartDate?: boolean;
  isEndDate?: boolean;
}

const CalendarDay: FunctionComponent<CalendarDayProps> = (props) => {
  const { onClick, day, onMouseEnter, onMouseLeave, isInRange, isStartDate, isEndDate } = props;

  const className = classNames({
    "c-calendar-day": true,
    "is-current-month": day.isCurrentMonth,
    "is-outside-month": !day.isCurrentMonth,
    "is-selected": day.isStartDate || day.isEndDate,
    "is-today": day.isToday,
    "is-disabled": day.isDisabled,
    "is-in-range": isInRange,
    "is-start-date": isStartDate,
    "is-end-date": isEndDate,
  });

  const handleClick = useCallback(() => {
    if (onClick && !day.isDisabled) {
      onClick(day.date);
    }
  }, [day.date, day.isDisabled, onClick]);

  const handleOnMouseEnter = useCallback(() => {
    if (onMouseEnter) {
      onMouseEnter(day);
    }
  }, [day, onMouseEnter]);

  const handleOnMouseLeave = useCallback(() => {
    if (onMouseLeave) {
      onMouseLeave(day);
    }
  }, [day, onMouseLeave]);

  return (
    <button
      type="button"
      className={className}
      aria-label={format(day.date, "do MMMM yyyy")}
      onClick={handleClick}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      disabled={day.isDisabled}
      tabIndex={-1}
    >
      <span className="c-calendar-day__label">{day.label}</span>
    </button>
  );
};

export default CalendarDay;
