import React, { useMemo } from "react";

import { OverTimeData } from "../../../../../../../../shared/components/charts/types";
import { RequiredNumericInput } from "../../../../../../../../shared/components/design-system/TextInput/NumericInput";
import { MONTHS } from "../../../../../analytics/AnalyticsOverTimePage/MonthSelect";
import tableStyles from "../../../../../analytics/tables.module.scss";

export default function InDetailDataEditor({
  value,
  onChange,
  year,
  comparisonYear,
  month,
}: {
  value: OverTimeData;
  onChange: React.Dispatch<React.SetStateAction<OverTimeData | null>>;
  year: string;
  comparisonYear: string | null;
  month: string;
}) {
  const years = comparisonYear ? [year, comparisonYear] : [year];

  const months = useMemo(() => {
    const months = [];
    for (let i = MONTHS.findIndex((m) => m.value === month); months.length < 12; ++i) {
      months.push(MONTHS[i % MONTHS.length].label);
    }
    return months;
  }, [month]);

  const updateValue = (monthIndex: number, yearIndex: number) => (newValue: number) =>
    onChange((value) => ({
      ...value,
      years: value!.years.map((year, i) =>
        i === yearIndex
          ? {
              ...year,
              months: year.months.map((month, j) => (j === monthIndex ? { ...month, count: newValue } : month)),
            }
          : year,
      ),
    }));

  return (
    <div className={tableStyles.Container}>
      <table className={tableStyles.Table}>
        <thead>
          <tr>
            <th />
            {years.map((year) => (
              <th key={year}>{year}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {months.map((month, i) => (
            <tr key={i}>
              <th>{month}</th>
              {value.years.map((year, j) => (
                <td>
                  <RequiredNumericInput
                    value={year.months[i].count}
                    onChange={updateValue(i, j)}
                    label={`${month} ${years[j]}`}
                    labelVisuallyHidden
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
