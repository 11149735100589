import React, { FunctionComponent } from "react";

import "./styles.scss";

interface SkipToContentProps {
  skipTo?: string;
}

const SkipToContent: FunctionComponent<SkipToContentProps> = ({ skipTo }) => (
  <a className="skip-to-content" href={skipTo ?? "#main-content"}>
    Skip to content
  </a>
);

export default SkipToContent;
