import React from "react";

import {
  OverTimeChartBlock,
  OverTimeChartBlockOptions,
} from "../../../../../../../../shared/block-editor-data/chart-block-types";
import OverTimeBarChart from "../../../../../../../../shared/components/charts/OverTimeBarChart";
import OverTimeTable from "../../../../../../../../shared/components/charts/OverTimeTable";
import tableStyles from "../../../../../analytics/tables.module.scss";
import { registerNodeCheck } from "../../BlockContainer/Caption/is";
import { createCustomBlock } from "../../utils";
import editableBlockComponent from "../block";
import EditOverTimeChartDialog from "./EditOverTimeChartDialog";

const OverTimeChartBlockComponent = editableBlockComponent(OverTimeChartBlockChart, EditOverTimeChartDialog, {
  chartType: "over-time",
});

function OverTimeChartBlockChart({ options }: { options: OverTimeChartBlockOptions }) {
  if (options.chartType === "TABLE") {
    return (
      <div className={tableStyles.Container}>
        <OverTimeTable
          data={options.data}
          className={tableStyles.Table}
          month={options.month}
          year={options.year}
          comparisonYear={options.comparisonYear}
        />
      </div>
    );
  }
  return (
    <OverTimeBarChart
      startMonth={options.month}
      data={options.data}
      year={options.year}
      comparisonYear={options.comparisonYear}
    />
  );
}

const overTimeChartPlugin = createCustomBlock<OverTimeChartBlock, OverTimeChartBlockOptions>(
  "cs-analytics-chart--over-time",
  OverTimeChartBlockComponent,
);

export default overTimeChartPlugin;

registerNodeCheck(overTimeChartPlugin.$is);
