import React from "react";

import "./styles.scss";

export type BadgeVariant = "default" | "info" | "success" | "danger" | "warning" | "primary" | "mustard" | "teal";

export type BadgeProps = {
  className?: string;
  variant?: BadgeVariant;
  label: string;
};

/** @deprecated — use the one in shared */
export default function Badge(props: BadgeProps) {
  const classes = `
    c-badge
    c-badge--${props.variant ?? "default"}
    ${props.className ?? ""}
  `;

  return <span className={classes}>{props.label}</span>;
}
