import { useCallback } from "react";

import { PublicationMode } from "../../../../../__generated__/graphql";
import React from "../../../../../shared/block-editor-data/react";
import { useConfirm } from "../../../hooks/useAlert";
import useAsyncFunctionWithLoading from "../../../hooks/useAsyncFunctionWithLoading";
import Button from "../../design-system/Button";
import { usePersistence } from "./persistence";

export default function UnpublishButton() {
  const confirm = useConfirm();

  const { savedDocument: doc, unpublish, loading } = usePersistence();
  const [wrappedUnpublish, unpublishing] = useAsyncFunctionWithLoading(unpublish);

  const onClick = useCallback(async () => {
    if (
      doc!.published &&
      doc!.published.publishedVersion!.id !== doc!.currentDraft!.id &&
      !(await confirm({
        title: "Unpublish document",
        okCaption: "Unpublish",
        cancelCaption: "Cancel",
        okVariant: "primary",
        children:
          "Unpublishing this document will delete the currently published version. Only the updated draft version will remain.",
      }))
    ) {
      return;
    }

    await wrappedUnpublish();
  }, [confirm, doc, wrappedUnpublish]);

  if (doc?.published?.publicationMode !== PublicationMode.Public) return null;

  return (
    <Button variant="secondary" onClick={onClick} disabled={loading} loading={unpublishing}>
      Unpublish
    </Button>
  );
}
