import { useCallback } from "react";
import React from "react";

import { PublicationMode } from "../../../../../__generated__/graphql";
import { useConfirm } from "../../../hooks/useAlert";
import useAsyncFunctionWithLoading from "../../../hooks/useAsyncFunctionWithLoading";
import Button from "../../design-system/Button";
import { usePersistence } from "./persistence";

export default function PublishChangesButton() {
  const confirm = useConfirm();

  const { savedDocument: doc, isDirty, publish, loading, checkDocumentHasName } = usePersistence();
  const [wrappedPublish, publishing] = useAsyncFunctionWithLoading(publish);

  const onClick = useCallback(async () => {
    if (!checkDocumentHasName()) return;

    if (
      !(await confirm({
        title: "Publish changes",
        okCaption: isDirty ? "Save and publish" : "Publish changes",
        cancelCaption: "Cancel",
        okVariant: "primary",
        children: isDirty
          ? "You need to save your changes to publish them. This will overwrite the existing published version."
          : "Publishing your changes will overwrite the existing published version with the current draft",
      }))
    ) {
      return;
    }

    await wrappedPublish(PublicationMode.Public);
  }, [confirm, isDirty, checkDocumentHasName, wrappedPublish]);

  if (doc?.published?.publicationMode !== PublicationMode.Public) return null;
  const hasPublishedChanges = doc?.published?.publishedVersion?.id !== doc?.currentDraft?.id;
  if (!isDirty && !hasPublishedChanges) return null;

  return (
    <Button variant="secondary" onClick={onClick} disabled={loading} loading={publishing}>
      Publish changes
    </Button>
  );
}
