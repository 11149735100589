import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { COMMAND_PRIORITY_EDITOR, createCommand, LexicalCommand } from "lexical";
import { useContext, useEffect } from "react";

import { Caption } from "../../../../../../../shared/block-editor-data/types";
import { NestedContext, NestedEditorConfig } from "../NestedContext";
import $insertBlockNode from "../block-nodes/insert-block-node";
import { $createTableNodeWithDimensions, TableNode } from "./TableNode";

export type InsertTableCommandPayload = Readonly<{
  columns: number;
  rows: number;
  caption: Caption;
}>;

export const INSERT_TABLE_COMMAND: LexicalCommand<InsertTableCommandPayload> = createCommand();

export function TablePlugin({
  nestedEditorConfig,
  children,
}: {
  nestedEditorConfig: NestedEditorConfig;
  children: JSX.Element | Array<JSX.Element>;
}): JSX.Element | null {
  const [editor] = useLexicalComposerContext();
  const cellContext = useContext(NestedContext);

  useEffect(() => {
    if (!editor.hasNodes([TableNode])) {
      throw new Error("TablePlugin: TableNode not registered on editor");
    }

    cellContext.set(nestedEditorConfig, children);

    return editor.registerCommand<InsertTableCommandPayload>(
      INSERT_TABLE_COMMAND,
      ({ columns, rows, caption }) => {
        $insertBlockNode($createTableNodeWithDimensions(columns, rows, caption));
        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    );
  }, [cellContext, children, editor, nestedEditorConfig]);

  return null;
}
