import React, { FunctionComponent } from "react";

import { Permission } from "../../../../../__generated__/graphql";
import { OrgSwitcher } from "../../../hooks/useOrgData";
import CombinedHeader from "../../design-system/PageHeader/CombinedHeader";

type AnalyticsHeaderProps = {
  srTitle: string;
};
const AnalyticsHeader: FunctionComponent<AnalyticsHeaderProps> = ({ srTitle }) => (
  <CombinedHeader title="Analytics">
    <OrgSwitcher permission={Permission.Analytics} />
  </CombinedHeader>
);

export default AnalyticsHeader;
