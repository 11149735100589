import mixpanel from "mixpanel-browser";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { ReportSearch } from "../../../../../../../__generated__/graphql";
import { MergedOptionSet, OptionGroup } from "../../../../../../../shared/components/charts/types";
import "../../../../../../../shared/components/design-system/InputLabel/styles.scss";
import LoadingSpinner from "../../../../../../../shared/components/design-system/LoadingSpinner";
import { useToast } from "../../../../../../../shared/components/design-system/Toaster/context";
import {
  SectionFooter,
  SectionHeader,
} from "../../../../../../../shared/components/design-system/component-groups/section-header-footer";
import TabbedContent, { Tab } from "../../../../../../../shared/components/design-system/tabs/TabbedContent";
import useRefOf from "../../../../../../../shared/hooks/useRefOf";
import { useOpenableWithTracking } from "../../../../../hooks/useBoolean";
import useOrgData from "../../../../../hooks/useOrgData";
import Button from "../../../../design-system/Button";
import GenericError from "../../../../design-system/GenericError";
import "../../../../design-system/OptionGroup/styles.scss";
import SidePanel, { ScrollableContent } from "../../../../design-system/SidePanel";
import "../../../../design-system/SortableList/DraggableItem/styles.scss";
import useSiteData from "../../../../sites-management/useSiteData";
import { ungroupedSplit } from "../../../split";
import useAnalyticsQuery from "../../../useAnalyticsQuery";
import { SplitSettings } from "../../useUrlSplitDimension";
import GroupingFormat from "./GroupingFormat";
import { OptionList } from "./components";
import { getGroupPreview } from "./default-groups";
import { applyGroup, isChecked } from "./grouper";
import styles from "./styles.module.scss";

export default function GroupingButton({
  firstSplit,
  secondSplit,
  className,
  filter,
}: {
  firstSplit: SplitSettings;
  secondSplit: SplitSettings;
  className?: string;
  filter: ReportSearch;
}) {
  const { isOpen, open, close } = useOpenableWithTracking("Analytics grouping panel");
  const [which, setWhich] = useState("MAIN");
  useEffect(() => {
    if (isOpen) setWhich("MAIN");
  }, [isOpen]);

  return (
    <>
      <Button onClick={open} variant="action" className={className} icon="organisations">
        Edit groups
      </Button>
      <SidePanel isOpen={isOpen} onClose={close}>
        <SectionHeader title="Edit groups" />
        <TabbedContent tab={which} onChangeTab={setWhich} className={styles.TabPanelContainer}>
          <GroupingTab
            label="Question"
            id="MAIN"
            filter={filter}
            settings={firstSplit}
            selectedTab={which}
            sidebarIsOpen={isOpen}
            close={close}
          />
          {secondSplit.analyticsCode || secondSplit.questionId ? (
            <GroupingTab
              label="Split"
              id="SPLIT"
              filter={filter}
              settings={secondSplit}
              selectedTab={which}
              sidebarIsOpen={isOpen}
              close={close}
            />
          ) : null}
        </TabbedContent>
      </SidePanel>
    </>
  );
}

function GroupingTab({
  label,
  id,
  settings,
  filter,
  selectedTab,
  sidebarIsOpen,
  close,
}: {
  label: string;
  id: string;
  settings: SplitSettings;
  filter: ReportSearch;
  selectedTab: string;
  sidebarIsOpen: boolean;
  close: () => void;
}) {
  const orgData = useOrgData();
  const site = useSiteData();
  const toast = useToast();
  const { setGroups } = settings;

  const testSplit = useMemo(() => ungroupedSplit(settings), [settings]);
  const { data, loading, error } = useAnalyticsQuery([
    { organisationId: orgData.organisation.id, splits: [testSplit], search: filter, language: site?.language },
  ]);

  const viewOptions = useMemo(
    () => getGroupPreview(settings.groups, data, orgData, testSplit),
    [data, orgData, settings.groups, testSplit],
  );

  const [checked, setChecked] = useState<string[]>([]);
  useEffect(() => setChecked([]), [selectedTab, sidebarIsOpen]);
  const checkedCount = useMemo(
    () =>
      viewOptions.reduce((a, n) => {
        if ("ids" in n) return isChecked(checked, n) ? a + 1 : a;
        return n.members.reduce((a, n) => (isChecked(checked, n) ? a + 1 : a), a);
      }, 0),
    [checked, viewOptions],
  );

  const [touchedGroups, setTouchedGroups] = useState<string[]>([]);
  const viewOptionsRef = useRefOf(viewOptions);
  useEffect(() => {
    setTouchedGroups(viewOptionsRef.current.map((group) => (group as OptionGroup).id).filter((x) => x));
  }, [viewOptionsRef, sidebarIsOpen]);

  const touch = useCallback(
    (id: string) => setTouchedGroups((touched) => [...touched.filter((x) => x !== id), id]),
    [],
  );

  const createGroup = useCallback(() => {
    try {
      const newOptions = applyGroup(checked, null, false, viewOptions);
      setGroups(newOptions);
      setChecked([]);
    } catch (e) {
      // This should always be a user error so no need to bother Sentry about it
      toast.error(e);
    }
    mixpanel.track("Created an analytics group", { numberOfMembers: checkedCount });
  }, [checkedCount, checked, viewOptions, setGroups, toast]);

  const addToGroup = useCallback(
    (groupId: string) => {
      try {
        const newOptions = applyGroup(checked, groupId, false, viewOptions);
        setGroups(newOptions);
        setChecked([]);
      } catch (e) {
        // This should always be a user error so no need to bother Sentry about it
        toast.error(e);
      }
      mixpanel.track("Added items to an analytics group", { numberOfNewMembers: checkedCount });
    },
    [checkedCount, checked, viewOptions, setGroups, toast],
  );

  const renameGroup = useCallback(
    (groupId: string, label: string) => {
      touch(groupId);
      setGroups({
        basis: "CUSTOM",
        groups: viewOptions.map((group) => {
          const { id, members } = group as OptionGroup;
          if (id !== groupId) return group;
          return { id, label, members };
        }),
      });
    },
    [touch, setGroups, viewOptions],
  );

  const ungroup = useCallback(
    ({ ids }: MergedOptionSet) => {
      try {
        const newOptions = applyGroup(ids, null, true, viewOptions);
        setGroups(newOptions);
      } catch (e) {
        // This should always be a user error so no need to bother Sentry about it
        toast.error(e);
      }
      mixpanel.track("Removed an item from an analytics group");
    },
    [viewOptions, setGroups, toast],
  );

  const cancelCheckboxes = useCallback(() => {
    setChecked([]);
    mixpanel.track("Cleared all grouping checkboxes", { checkedCount });
  }, [checkedCount]);

  if (error) {
    return (
      <Tab label={label} id={id} className={styles.TabbedArea}>
        <ScrollableContent>
          <GenericError error={error} />
        </ScrollableContent>
        <SectionFooter>
          <Button onClick={close}>Done</Button>
        </SectionFooter>
      </Tab>
    );
  }

  if (loading) {
    return (
      <Tab label={label} id={id} className={styles.TabbedArea}>
        <ScrollableContent>
          <LoadingSpinner />
        </ScrollableContent>
      </Tab>
    );
  }

  return (
    <Tab label={label} id={id} className={styles.TabbedArea}>
      <ScrollableContent>
        <GroupingFormat
          currentCode={testSplit.code}
          currentOptions={settings.groups}
          setCurrentOptions={setGroups}
          data={data}
        />
        <OptionList
          groups={viewOptions}
          checked={checked}
          touched={touchedGroups}
          setChecked={setChecked}
          addToGroup={addToGroup}
          renameGroup={renameGroup}
          ungroup={ungroup}
        />
      </ScrollableContent>
      <SectionFooter>
        {checkedCount ? (
          <>
            <Button onClick={cancelCheckboxes} variant="ghost">
              Cancel
            </Button>
            <Button onClick={createGroup}>Create group</Button>
          </>
        ) : (
          <Button onClick={close}>Done</Button>
        )}
      </SectionFooter>
    </Tab>
  );
}
