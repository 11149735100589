import { isBefore } from "date-fns";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import useTrigger from "../../../../../hooks/useTrigger";
import { dateRange, DateRange } from "../../types";
import DisplayCalendar from "./DisplayCalendar";
import "./styles.scss";

export default function RangeCalendar({
  minDate,
  maxDate,
  value,
  onChange,
}: {
  minDate: Date;
  maxDate: Date;
  value?: DateRange;
  onChange: (value: DateRange) => void;
}) {
  const [focusedDate, setFocusedDate] = useState<Date | null>(null);

  const [tempStartDate, setTempStartDate] = useState<Date | null>(null);

  const { ref, trigger } = useTrigger(value?.startDate ?? null);

  useEffect(() => {
    if (value?.presetId && value.startDate) trigger(value.startDate);
    // We only want to scroll when value.presetValue changes. Since it is not possible for value?.presetValue to change without value changing, we're safe without listing both in the dependencies.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, value?.presetId]);

  const handleOnClickDay = useCallback(
    (dateSelected: Date) => {
      if (!tempStartDate) {
        setTempStartDate(dateSelected);
        return;
      }

      onChange(
        dateRange({
          startDate: dateSelected,
          endDate: tempStartDate,
        }),
      );
    },
    [onChange, tempStartDate],
  );

  useEffect(() => setTempStartDate(null), [value]);

  const highlight: DateRange | null = useMemo(() => {
    if (tempStartDate) {
      return forceForwards({
        startDate: tempStartDate,
        endDate: focusedDate ?? tempStartDate,
      });
    }
    if (value) return forceForwards(value);
    return null;
  }, [tempStartDate, value, focusedDate]);

  return (
    <DisplayCalendar
      highlight={highlight}
      minDate={minDate}
      maxDate={maxDate}
      onClickDay={handleOnClickDay}
      onHoverChange={setFocusedDate}
      scrollTo={ref}
    />
  );
}

function forceForwards(range: DateRange) {
  if (!range.endDate || !range.startDate) return range;
  if (!isBefore(range.endDate, range.startDate)) return range;
  return {
    endDate: range.startDate,
    startDate: range.endDate,
  };
}
