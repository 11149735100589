import React, { FormEvent } from "react";

import { UrlInput } from "../../../../../shared/components/design-system/TextInput/LinkInput";
import StringInput from "../../../../../shared/components/design-system/TextInput/StringInput";
import { SectionHeader } from "../../../../../shared/components/design-system/component-groups/section-header-footer";
import useAsyncFunctionWithLoading, { useAsyncCallbackWithLoading } from "../../../hooks/useAsyncFunctionWithLoading";
import Button from "../../design-system/Button";
import ConfirmDeleteButton from "../../design-system/Button/specifics/ConfirmDeleteButton";
import { usePersistence } from "./persistence";

export default function InsightsExternalLinkPage({ onClose }: { onClose: () => void }) {
  const {
    name,
    setName,
    externalLinkUrl,
    setExternalLinkUrl,
    loading,
    saveFormRef,
    savedDocument: doc,
    del,
    save,
  } = usePersistence();

  const [wrappedDelete, deleting] = useAsyncFunctionWithLoading(del);

  const [onSubmit, publishing] = useAsyncCallbackWithLoading(
    async (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      await save({}, "savedLink");
    },
    [save],
  );

  return (
    <form onSubmit={onSubmit} ref={saveFormRef}>
      <SectionHeader title={doc ? doc.name : "Create link"} />

      <StringInput placeholder="Page Name" label="Name" value={name} onChange={setName} required />
      <UrlInput
        placeholder="https://www.example.com/"
        label="Link URL"
        value={externalLinkUrl ?? ""}
        onChange={setExternalLinkUrl}
        required
      />

      <div className="ds-mt-7 ds-hstack">
        {doc ? (
          <ConfirmDeleteButton
            title="Delete link?"
            variant="dangerTertiary"
            track="Insight reports"
            loading={deleting}
            disabled={loading}
            type="button"
            onConfirm={() => wrappedDelete("deletedLink")}
            confirmBody="This will permanently delete this link"
            confirmText="Delete"
          >
            Delete link
          </ConfirmDeleteButton>
        ) : (
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
        )}

        <Button
          className="ds-ml-auto"
          type="submit"
          variant="primary"
          loadingText="Saving"
          disabled={loading}
          loading={publishing}
        >
          Save
        </Button>
      </div>
    </form>
  );
}
