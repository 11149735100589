import { $insertDataTransferForRichText } from "@lexical/clipboard";
import { $getRoot, $getSelection, $parseSerializedNode, RangeSelection } from "lexical";
import { useEffect } from "react";

import { EmptyDocument, RichTextDocument } from "../../../../../shared/block-editor-data/types";
import { useActiveEditor } from "./hooks";

export function initialContent(
  /** Either a RichTextDocument made in Lexical, null for the default empty document, or an HTML snippet as a string to be converted */
  defaultValue: RichTextDocument | string | null | undefined,
) {
  return () => {
    const { root } = defaultValue && typeof defaultValue !== "string" ? defaultValue : EmptyDocument;
    return $parseSerializedNode(root);
  };
}

export default function InitialHtmlPlugin({ html }: { html: string }) {
  const editor = useActiveEditor();
  useEffect(() => {
    const clipboardData = new DataTransfer();
    clipboardData.items.add(html, "text/html");
    editor.update(() => {
      $getRoot().select();
      $insertDataTransferForRichText(clipboardData, $getSelection() as RangeSelection, editor);
    });
    // We 100% do NOT want this to re-run whenever the "editor" variable changes,
    // as that causes the original document to be appended every time you click on a nested editor or back to the main one,
    // which is altoghether too much, so:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
}
