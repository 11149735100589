import React, { useEffect, useState } from "react";

import { useTrackingPreference } from ".";
import Alert from "../../../../../shared/components/design-system/Alert";
import { ExternalLink } from "../../../../../shared/components/design-system/Button/Link";
import { useToast } from "../../../../../shared/components/design-system/Toaster/context";
import styles from "../../../../../shared/components/design-system/Toaster/styles.module.scss";
import useRefOf from "../../../../../shared/hooks/useRefOf";
import useSelf from "../../../hooks/useSelf";

export function useShownAnalyticsPrompt() {
  const self = useSelf();
  // This state is never updated, it just stores what the value was when the app started:
  const [shownAnalyticsPrompt] = useState(self.acceptTracking === "ASK");
  return shownAnalyticsPrompt;
}

export default function useAnalyticsOptIn() {
  const toast = useToast();
  const preferences = useRefOf(useTrackingPreference());
  const shownAnalyticsPrompt = useShownAnalyticsPrompt();
  useEffect(() => {
    if (shownAnalyticsPrompt) {
      const id = toast.showExtra(AnalyticsOptInControlAlert, {
        toggle(value: boolean) {
          void preferences.current?.toggle(value);
          toast.removeExtra(id);
        },
      });
    }
  }, [preferences, shownAnalyticsPrompt, toast]);
}

function AnalyticsOptInControlAlert({ toggle }: { toggle: (value: boolean) => void }) {
  return (
    <Alert
      className={styles.toastAlert}
      actions={[
        { variant: "secondary", onClick: () => toggle(false), label: "Decline" },
        { variant: "secondary", onClick: () => toggle(true), label: "Accept" },
      ]}
    >
      We would like to set analytics cookies to help us improve the dashboard by collecting and reporting on information
      about how you use it. For more information, please see{" "}
      <ExternalLink to="https://www.culture-shift.co.uk/dashboard-privacy-notice/">
        the Culture Shift privacy notice
      </ExternalLink>
      .
    </Alert>
  );
}
