import * as Sentry from "@sentry/browser";
import { Auth } from "aws-amplify";
import React, { useCallback, useEffect, useState } from "react";

import ButtonLink from "../../../../../shared/components/design-system/Button/ButtonLink";
import ButtonSet from "../../../../../shared/components/design-system/component-groups/ButtonSet";
import { isLocalStorageAvailable } from "../../../utils";
import Button from "../../design-system/Button";
import SearchableSelect from "../../design-system/SearchableList/SearchableSelect";
import { SignInCard, SignInMain } from "../SignInPage/containers";
import "./styles.scss";

interface SsoFormProps {
  onClose: () => void;
  toast: string | null;
}

interface Provider {
  id: string;
  label: string;
}

function ChooseSso({ onClose, toast }: SsoFormProps) {
  const [selectedProvider, setSelectedProvider] = useState<string | null>(null);

  const [loading, setLoading] = useState(true);
  const [providers, setProviders] = useState<Provider[]>([]);
  useEffect(() => {
    fetch(`${import.meta.env.REACT_APP_API_URL}/sso-providers`)
      .then(async (result) => {
        const { providers } = await result.json();
        setProviders(
          providers.map(({ name, slug }: { name: string; slug: string }) => ({
            label: name,
            id: slug,
          })),
        );
        setLoading(false);
      })
      .catch((error) => {
        Sentry.captureException(error);
        // If the list of providers fails to load, fall back to the old selection screen.
        void Auth.federatedSignIn();
      });
    // I assume OAuthSignIn will never change; either way I'm reluctant to add it to the dependencies here because changing it shouldn't trigger another fetch.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [attemptedSignIn, setAttemptedSignIn] = useState(false);
  const onSignIn = useCallback(() => {
    if (selectedProvider) {
      if (isLocalStorageAvailable()) localStorage.setItem("ssoProvider", selectedProvider);
      void Auth.federatedSignIn({ customProvider: selectedProvider });
    } else {
      setAttemptedSignIn(true);
    }
  }, [selectedProvider]);

  useEffect(() => setSelectedProvider(localStorage.getItem("ssoProvider")), []);

  return (
    <SignInMain>
      <SignInCard toast={toast}>
        <h1 className="ds-h3 sign-in-form__header">Single sign-on</h1>
        <p className="sign-in-form__description">Please enter your organisation below</p>
        <SearchableSelect
          label="Organisation"
          className="sign-in-form__dropdown"
          loading={loading}
          items={providers}
          fields={["label"]}
          searchOptions={{ initialsMatch: true }}
          value={loading ? "" : selectedProvider}
          onSelect={setSelectedProvider}
          required={attemptedSignIn ? "Please select an organisation" : undefined}
          autoFocus
        />
        <ButtonSet direction="vertical">
          <Button onClick={onSignIn} variant="primary" size="large">
            Sign in with SSO
          </Button>
        </ButtonSet>
      </SignInCard>
      <ButtonLink isQuiet isOverBg onClick={onClose} className="ds-mt-7" variant="secondary">
        Sign in with email
      </ButtonLink>
    </SignInMain>
  );
}

export default ChooseSso;
