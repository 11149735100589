import { useCallback, useState } from "react";

// Allows you to trigger an action inside a child component without declaring a provider or

// usage (inner component):

// useEffect(() => alert(ref.current), [ref]);

// usage (outer component):

// const trigger = useTrigger<string>();
// return <>
//   <button onClick={() => trigger.trigger("test")} />
//   <Inner trigger={trigger}/>
// </>

// Like a regular ref, but WILL become reference-unequal if the trigger is called — regardless of whether the value changes.
export type TriggerRef<T> = { current: T };

export type Trigger<T> = {
  // Call this to trigger the event:
  trigger: (value: T) => void;
  ref: TriggerRef<T>;
};

export default function useTrigger<T>(initialValue: T): Trigger<T> {
  const [value, setValue] = useState({ current: initialValue });

  const trigger = useCallback((value: T) => setValue({ current: value }), []);

  return { trigger, ref: value };
}
