import gql from "graphql-tag";

import { documentFields } from "../../../graphql/fragments";

export const DOCUMENT_QUERY = gql`
  query getDocument($documentId: ID!) {
    document(id: $documentId) {
      ...documentFields
    }
  }
  ${documentFields}
`;

export const CREATE_DOCUMENT = gql`
  mutation createDocument(
    $siteId: ID!
    $name: String!
    $documentType: DocumentType!
    $body: String!
    $makePreviewable: Boolean!
    $externalLinkUrl: String
  ) {
    createDocument(
      site: $siteId
      name: $name
      documentType: $documentType
      body: $body
      makePreviewable: $makePreviewable
      externalLinkUrl: $externalLinkUrl
    ) {
      ...documentFields
    }
  }
  ${documentFields}
`;

export const UPDATE_DOCUMENT = gql`
  mutation updateDocument(
    $documentId: ID!
    $name: String!
    $body: String!
    $makePreviewable: Boolean!
    $externalLinkUrl: String
    $updatePublishedVersion: Boolean!
  ) {
    updateDocument(
      id: $documentId
      name: $name
      body: $body
      makePreviewable: $makePreviewable
      externalLinkUrl: $externalLinkUrl
      updatePublishedVersion: $updatePublishedVersion
    ) {
      ...documentFields
    }
  }
  ${documentFields}
`;

export const DELETE_DOCUMENT = gql`
  mutation deleteDocument($id: ID!) {
    deleteDocument(id: $id) {
      id
      documents {
        id
      }
    }
  }
`;

export const PUBLISH_DOCUMENT = gql`
  mutation publishDocument($id: ID!, $mode: PublicationMode!) {
    publishDocument(id: $id, mode: $mode) {
      id
      published {
        publishedVersion {
          id
        }
        publicationMode
      }
    }
  }
`;

export const UNPUBLISH_DOCUMENT = gql`
  mutation unpublishDocument($id: ID!) {
    unpublishDocument(id: $id) {
      id
      published {
        publishedVersion {
          id
        }
        publicationMode
      }
    }
  }
`;
