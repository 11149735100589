import React, { useCallback } from "react";

import { getEnglishMonthShortName, yearLabel } from "../../../../../shared/components/charts/months";
import { InDetailData, OverTimeData } from "../../../../../shared/components/charts/types";
import Button from "../../design-system/Button";

type Csv = (string | number)[][];

export default function ExportTableDataButton({ data, disabled }: { data?: Csv; disabled?: boolean }) {
  const download = useCallback(() => {
    if (!data) return;
    const a = document.createElement("a");
    a.download = "table.csv";
    a.href = `data:text/csv,${encodeURI(csv(data))}`;
    a.click();
  }, [data]);

  return (
    <Button variant="action" onClick={download} disabled={disabled || !data} icon="arrowToBottom">
      Download
    </Button>
  );
}

function escape(str: string) {
  if (!/[,"]/.test(str)) return str;
  return `"${str.replaceAll('"', '""')}"`;
}

function csv(data: Csv) {
  return data.map((row) => row.join(",")).join("\n");
}

export function inDetailCsv(series: InDetailData): Csv {
  if (!series.splits) {
    return series.categories.map((category) => [escape(category.label), category.count]);
  }
  return [
    ["", ...series.splits.map((split) => escape(split.label)), "Total"],
    ...series.categories.map((category) => [
      escape(category.label),
      ...category.segments!.map((segment) => segment.count),
      category.count,
    ]),
  ];
}

export function overTimeCsv(
  series: OverTimeData,
  {
    year,
    month,
    comparisonYear,
    duration,
  }: {
    year: string;
    month: string;
    comparisonYear: string | null;
    duration: string;
  },
): Csv {
  if (!comparisonYear) return series.years[0].months.map((data, i) => [getEnglishMonthShortName(month, i), data.count]);
  return [
    ["", yearLabel(year, month, duration), yearLabel(comparisonYear, month, duration)],
    ...series.years[0].months.map((data, i) => [
      getEnglishMonthShortName(month, i),
      data.count,
      series.years[1].months[i].count,
    ]),
  ];
}
