import { useCallback, useMemo, useState } from "react";

import { ReportSearch } from "../../../../../../../__generated__/graphql";
import { FilterSettings } from "../../../../../hooks/filtering/useFilterFromUrlParams";

export function useFilterSettings(defaultValue: ReportSearch) {
  const defaults = useMemo<ReportSearch>(() => ({}), []);
  const [filter, setFilter] = useState(defaultValue);
  const clearFilters = useCallback(() => setFilter(defaults), [defaults]);
  return useMemo<FilterSettings>(
    () => ({
      filter,
      setFilter,
      clearFilters,
      forbiddenFilters: {
        createdAt: true,
        updatedAt: false,
        status: false,
        assignedTo: false,
        outcome: false,
        form: false,
        questions: false,
        excludeSpam: false,
      },
      defaults,
    }),
    [clearFilters, defaults, filter],
  );
}
