/**
 * Portions of this file are copyright (c) Meta Platforms, Inc. and affiliates.
 */
import type {
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  LexicalEditor,
  LexicalNode,
  NodeKey,
  SerializedLexicalNode,
  Spread,
} from "lexical";
import { DecoratorNode } from "lexical";
import * as React from "react";
import { Suspense } from "react";

import { Caption, Table, TableCell, TableRow } from "../../../../../../../shared/block-editor-data/types";
import { registerNodeCheck } from "../BlockContainer/Caption/is";
import { createBlankCaption, createUID, emptyNestedEditorJSON } from "../utils";

export type Rows = Array<TableRow>;

export const cellHTMLCache: Map<string, string> = new Map();
export const cellTextContentCache: Map<string, string> = new Map();

const TableComponent = React.lazy(
  // @ts-ignore
  () => import("./TableComponent"),
);

function createCell(json?: string | null): TableCell {
  return {
    id: createUID(),
    json: json ?? emptyNestedEditorJSON,
    type: "cell",
    direction: "ltr",
    format: "",
    version: 1,
    indent: 0,
  };
}

export function createRow(): TableRow {
  return {
    cells: [],
    id: createUID(),
    version: 1,
  };
}

export type SerializedTableNode = Spread<Table, SerializedLexicalNode>;

export function extractRowsFromHTML(tableElem: HTMLTableElement, columnHeader: boolean, rowHeader: boolean): Rows {
  const rowElems = tableElem.querySelectorAll("tr");
  const rows: Rows = [];
  for (let y = 0; y < rowElems.length; y++) {
    const rowElem = rowElems[y];
    const cellElems = rowElem.querySelectorAll("td,th");
    if (!cellElems || cellElems.length === 0) {
      continue;
    }
    const cells: Array<TableCell> = [];
    for (let x = 0; x < cellElems.length; x++) {
      const cell = createCell();

      cells.push(cell);
    }
    const row = createRow();
    row.cells = cells;
    rows.push(row);
  }
  return rows;
}

function convertTableElement(domNode: HTMLElement): null | DOMConversionOutput {
  const rowElems = domNode.querySelectorAll("tr");

  // TODO: if a table has merged cells, this calculation could be wrong, and later rows could also be wrong. One day we might support merged cells, and before then we might account for them more correctly in this function, but for now, we just pretend they don't exist and hope nobody tries to paste one in.
  const rowLength = domNode.querySelectorAll("tr:first-child > td, tr:first-child > th").length;

  if (!rowElems || rowElems.length === 0) {
    return null;
  }
  const rows: Rows = [];

  for (let y = 0; y < rowElems.length; y++) {
    const rowElem = rowElems[y];
    const cellElems = rowElem.querySelectorAll("td,th");
    if (!cellElems || cellElems.length === 0) {
      continue;
    }
    const cells: Array<TableCell> = [];
    for (let x = 0; x < rowLength; x++) {
      const cellElem = cellElems[x] as HTMLElement;
      const json = cellElem?.textContent
        ? JSON.stringify({
            root: {
              children: [
                {
                  children: [
                    {
                      detail: 0,
                      format: 0,
                      mode: "normal",
                      style: "",
                      // TODO: In principle we could preserve styling and paragraphs etc when pasting into tables, but for the time being that's not worth the effort.
                      text: cellElem.textContent,
                      type: "text",
                      version: 1,
                    },
                  ],
                  direction: null,
                  format: "",
                  indent: 0,
                  type: "paragraph",
                  version: 1,
                },
              ],
              direction: null,
              format: "",
              indent: 0,
              type: "root",
              version: 1,
            },
          })
        : null;

      const cell = createCell(json);
      cells.push(cell);
    }
    const row = createRow();
    row.cells = cells;
    rows.push(row);
  }

  return {
    node: $createTableNode(
      !domNode.querySelector("td:first-child"),
      !rowElems[0].querySelector("td"),
      rows,
      createBlankCaption(),
    ),
  };
}

export function exportTableCellsToHTML(
  rows: Rows,
  rect?: { startX: number; endX: number; startY: number; endY: number },
): HTMLElement {
  const table = document.createElement("table");
  const colGroup = document.createElement("colgroup");
  const tBody = document.createElement("tbody");
  const firstRow = rows[0];

  for (let x = rect != null ? rect.startX : 0; x < (rect != null ? rect.endX + 1 : firstRow.cells.length); x++) {
    const col = document.createElement("col");
    colGroup.append(col);
  }

  for (let y = rect != null ? rect.startY : 0; y < (rect != null ? rect.endY + 1 : rows.length); y++) {
    const { cells } = rows[y];
    const rowElem = document.createElement("tr");

    for (let x = rect != null ? rect.startX : 0; x < (rect != null ? rect.endX + 1 : cells.length); x++) {
      const cell = cells[x];
      const cellElem = document.createElement("td");
      cellElem.innerHTML = cellHTMLCache.get(cell.json) || "";
      rowElem.appendChild(cellElem);
    }
    tBody.appendChild(rowElem);
  }

  table.appendChild(colGroup);
  table.appendChild(tBody);
  return table;
}

export class TableNode extends DecoratorNode<JSX.Element> {
  __rows: Rows;
  __columnHeader: boolean;
  __rowHeader: boolean;
  __caption: Caption;

  static getType(): string {
    return "table";
  }

  static clone(node: TableNode): TableNode {
    return new TableNode(node.__columnHeader, node.__rowHeader, node.__caption, Array.from(node.__rows), node.__key);
  }

  static importJSON(serializedNode: SerializedTableNode): TableNode {
    return $createTableNode(
      serializedNode.columnHeader,
      serializedNode.rowHeader,
      serializedNode.rows,
      serializedNode.caption,
    );
  }

  exportJSON(): SerializedTableNode {
    return {
      rows: this.__rows,
      type: "table",
      version: 1,
      columnHeader: this.__columnHeader,
      rowHeader: this.__rowHeader,
      indent: 0,
      caption: this.__caption,
    };
  }

  static importDOM(__columnHeader: boolean, __rowHeader: boolean): DOMConversionMap | null {
    return {
      table: (_node: Node) => ({
        conversion: convertTableElement,
        priority: 0,
      }),
    };
  }

  exportDOM(): DOMExportOutput {
    return { element: exportTableCellsToHTML(this.__rows) };
  }

  isInline() {
    return false;
  }

  constructor(columnHeader: boolean, rowHeader: boolean, caption: Caption, rows?: Rows, key?: NodeKey) {
    super(key);
    this.__rows = rows || [];
    this.__columnHeader = columnHeader;
    this.__rowHeader = rowHeader;
    this.__caption = caption;
  }

  createDOM(): HTMLElement {
    const div = document.createElement("div");
    div.style.display = "contents";
    return div;
  }

  updateDOM(): false {
    return false;
  }

  updateCellJSON(x: number, y: number, json: string): void {
    const self = this.getWritable();
    const rows = self.__rows;
    const row = rows[y];
    const cells = row.cells;
    const cell = cells[x];
    const cellsClone = Array.from(cells);
    const cellClone = { ...cell, json };
    const rowClone = { ...row, cells: cellsClone };
    cellsClone[x] = cellClone;
    rows[y] = rowClone;
  }

  insertColumnAt(x: number, y: number): void {
    const self = this.getWritable();
    const rows = self.__rows;
    for (let y = 0; y < rows.length; y++) {
      const row = rows[y];
      const cells = row.cells;
      const cellsClone = Array.from(cells);
      const rowClone = { ...row, cells: cellsClone };
      cellsClone.splice(x, 0, createCell());
      rows[y] = rowClone;
    }
  }

  deleteColumnAt(x: number): void {
    const self = this.getWritable();
    const rows = self.__rows;
    if (x === 0) {
      self.__columnHeader = false;
    }
    for (let y = 0; y < rows.length; y++) {
      const row = rows[y];
      const cells = row.cells;
      const cellsClone = Array.from(cells);
      const rowClone = { ...row, cells: cellsClone };
      cellsClone.splice(x, 1);
      rows[y] = rowClone;
    }
  }

  insertRowAt(y: number, x: number): void {
    const self = this.getWritable();
    const rows = self.__rows;
    const prevRow = rows[y] || rows[y - 1];
    const cellCount = prevRow.cells.length;
    const row = createRow();
    for (let x = 0; x < cellCount; x++) {
      const cell = createCell();
      row.cells.push(cell);
    }
    rows.splice(y, 0, row);
  }

  deleteRowAt(y: number): void {
    const self = this.getWritable();
    const rows = self.__rows;
    if (y === 0) {
      self.__rowHeader = false;
    }
    rows.splice(y, 1);
  }

  updateRowType(y: number): void {
    const self = this.getWritable();
    if (self.__rowHeader === true) {
      self.__rowHeader = false;
    } else {
      self.__rowHeader = true;
    }
  }

  updateColumnType(): void {
    const self = this.getWritable();
    if (self.__columnHeader === true) {
      self.__columnHeader = false;
    } else {
      self.__columnHeader = true;
    }
  }

  updateCaptionJSON(json: string): void {
    const self = this.getWritable();
    self.__caption = { ...self.__caption, json };
  }

  decorate(_: LexicalEditor, config: EditorConfig): JSX.Element {
    return (
      <Suspense>
        <TableComponent
          nodeKey={this.__key}
          theme={config.theme}
          columnHeader={this.__columnHeader}
          rowHeader={this.__rowHeader}
          rows={this.__rows}
          caption={this.__caption}
        />
      </Suspense>
    );
  }
}

export function $isTableNode(node: LexicalNode | null | undefined): node is TableNode {
  return node instanceof TableNode;
}

export function $createTableNode(columnHeader: boolean, rowHeader: boolean, rows: Rows, caption: Caption): TableNode {
  return new TableNode(columnHeader, rowHeader, caption, rows);
}

export function $createTableNodeWithDimensions(rowCount: number, columnCount: number, caption: Caption): TableNode {
  const rows: Rows = [];
  const rowHeader = true;
  const columnHeader = false;
  for (let y = 0; y < columnCount; y++) {
    const row: TableRow = createRow();
    rows.push(row);
    for (let x = 0; x < rowCount; x++) {
      row.cells.push(createCell());
    }
  }
  return new TableNode(columnHeader, rowHeader, caption, rows);
}

registerNodeCheck($isTableNode);
