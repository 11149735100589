import React, { forwardRef } from "react";

import BaseButton, { ActionButtonProps, ButtonProps } from "../../../../../shared/components/design-system/Button";

/** @deprecated — use the one in shared */
export type ButtonWrapperProps = Omit<ButtonProps & { iconButton?: false }, "iconButton" | "type" | "label" | "to"> & {
  type?: "button" | "submit";
  children: string;
} & ActionButtonProps;

/** @deprecated This is just a wrapper now around the "base" Button class in `shared`, best to use that insteda */
const Button = forwardRef<HTMLButtonElement, ButtonWrapperProps>(({ type, children, className, ...props }, ref) => (
  <BaseButton ref={ref} className={className} {...props} submit={type === "submit"} label={children} />
));

export default Button;
