import React from "react";

import Select from "../../design-system/Select";
import MonthSelect from "./MonthSelect";
import YearSelect from "./YearSelect";
import styles from "./index.module.scss";
import { OverTimeYearSettings } from "./useUrlYearSettings";

export default function TimeframeSelection({
  settings: { year, setYear, comparisonYear, setComparisonYear, month, setMonth, duration, setDuration },
}: {
  settings: OverTimeYearSettings;
}) {
  return (
    <>
      <Select
        value={duration}
        onChange={setDuration}
        label="Duration"
        size="medium"
        className={styles.OverTimeHeader__duration}
      >
        <option value="3">3 months</option>
        <option value="6">6 months</option>
        <option value="12">12 months</option>
      </Select>
      <YearSelect
        label="Reporting year"
        onChange={setYear}
        value={year}
        className={styles.OverTimeHeader__year}
        required
        month={month}
        duration={duration}
        trackingText="Changed Analytics over time year"
      />
      <MonthSelect
        label="Starting month"
        onChange={setMonth}
        value={month}
        className={styles.OverTimeHeader__startMonth}
        trackingText="Changed Analytics over time start month"
      />
      <YearSelect
        label="Comparison year"
        onChange={setComparisonYear}
        value={comparisonYear}
        className={styles.OverTimeHeader__comparisonYear}
        exclude={year}
        month={month}
        duration={duration}
        trackingText="Changed Analytics over time compare-to year"
      />
    </>
  );
}
