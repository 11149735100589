import gql from "graphql-tag";

export const siteFields = gql`
  fragment siteFields on Site {
    id
    name
    urls {
      id
      host
      status
      isCanonical
      cnameTarget
      certificateCnameOrigin
      certificateCnameTarget
      certificateValidUntil
    }
    language
    noticeText
    noticeVisible
    noticeStartDate
    noticeEndDate
    sla
    slaVisible
    hiddenFromSearchEngines
    hideReportPage
    branding {
      heroLogo
      contactEmail
      contactOrgName
      contactStreet1
      contactStreet2
      contactStreet3
      contactLocality
      contactRegion
      contactPostcode
      contactCountry
      contactMapUrl
      feedbackTitle
      feedbackText
      feedbackLink
      facebook
      twitter
      youtube
      instagram
      linkedin
      footerLinks {
        name
        link
      }
      headerLinks {
        name
        link
      }
      homepageHero {
        type
        titleImage
        text
        image
        backgroundColour
        foregroundColour
        bubbleLeft
        bubbleRight
      }
      campaignsHero {
        type
        text
        image
        backgroundColour
        foregroundColour
        bubbleLeft
        bubbleRight
      }
      reportHero {
        type
        text
        image
        backgroundColour
        foregroundColour
        bubbleLeft
        bubbleRight
      }
      primaryColour
      primaryColourInverse
      fontSheetUrl
      primaryFont
      secondaryFont
      googleTagId
    }
  }
`;

export const categoryFields = gql`
  fragment categoryFields on Category {
    id
    name
    imageUrl
    articles {
      id
    }
    slug
  }
`;
export const documentFields = gql`
  fragment documentFields on Document {
    id
    name
    documentType
    slug
    currentDraft {
      id
      body
    }
    published {
      publishedVersion {
        id
      }
      publicationMode
      path
    }
    externalLinkUrl
  }
`;

export const campaignFields = gql`
  fragment campaignFields on Campaign {
    id
    name
    subtitle
    imageUrl
    published
    contentType
    body
    externalLinkUrl
    slug
  }
`;

export const riskAssessmentDetails = gql`
  fragment riskAssessmentDetails on RiskAssessment {
    id
    author {
      id
      name
    }
    formVersion
    createdAt
    draft
    answers {
      questionId
      optionId
      freeText
      riskLevel
    }
  }
`;

export const questionFields = gql`
  fragment questionFields on Question {
    id
    title
    shortTitle
    titleTranslations {
      text
    }
    contextAwareTitles {
      title
      when {
        questionId
        answers
      }
      titleTranslations {
        text
      }
    }
    analyticsCode
    type
    category
    optionGroups {
      id
      groupName
      groupNameTranslations {
        text
      }
      options {
        id
        value
        valueTranslations {
          text
        }
      }
    }
    deletedOptions {
      id
      value
      groupId
    }
    deletedOptionGroups {
      id
      groupName
    }
    answersHidden
    required
  }
`;
