import { useCallback, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

/*
This hook is typed to the specific parameters it's expecting, so this is fine:

  const { getParam } = useUrlParams<"count" | "page">();
  const count = getParam("count");

but this would not build:

  const { getParam } = useUrlParams<"page">();
  const count = getParam("count");
*/

// When we accept an update for a URL parameter, we can take a string, a string array (which means "create separate parameters for each value") or null (which means "delete this parameter").
export type UrlParamPayload = string | string[] | null;

// This type signature really just means { [key: T]: UrlParamPayload }, but you can't use type arguments there, so we have to specify that using Partial<Record<T, UrlParamPayload>>. We still need { [key: string]: UrlParamPayload }, though, because you can't pass Partial<Record<T, UrlParamPayload>> through Object.entities and get neatly typed output. They're nothing fancy, though — you can build one of these objects just by saying { page: "1", count: null }.
export type UrlParamUpdates<T extends string> = Partial<Record<T, UrlParamPayload>> & {
  [key: string]: UrlParamPayload;
};

function applyUpdate(params: URLSearchParams, key: string, value: UrlParamPayload) {
  if (typeof value === "string") {
    params.set(key, value);
    return;
  }
  params.delete(key);
  if (value) {
    for (const val of value) params.append(key, val);
  }
}

function applyUpdates(params: URLSearchParams, updates: UrlParamUpdates<string>) {
  for (const [key, value] of Object.entries(updates)) {
    applyUpdate(params, key, value);
  }
}

// TODO: why is this not just a useMemo?
let params: URLSearchParams;

function useApplyUpdatesandNavigate() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    params = new URLSearchParams(location.search);
  }, [location.search]);

  return useCallback(
    (updates: UrlParamUpdates<string>) => {
      applyUpdates(params, updates);
      // Wrapping this in a `setTimeout` means that if two navigations happen in one event loop,
      // only the last one will apply. Because `params` is shared, this will apply both updates,
      // effectively batching the two requests into a single history event.
      setTimeout(() => {
        navigate({ search: params.toString() });
      });
    },
    [navigate],
  );
}

export function useUrlParams<T extends string>() {
  const location = useLocation();
  const applyUpdatesandNavigate = useApplyUpdatesandNavigate();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const getParam = useCallback<(key: T) => string | null>((key: T) => params.get(key), [params]);
  const getParamArray = useCallback<(key: T) => string[]>((key: T) => params.getAll(key), [params]);

  const setParam = useCallback(
    (key: T, value: UrlParamPayload) => {
      applyUpdatesandNavigate({ [key]: value });
    },
    [applyUpdatesandNavigate],
  );

  const linkWithParam = useCallback(
    (key: T, value: UrlParamPayload) => {
      const clonedParams = new URLSearchParams(params);
      applyUpdate(clonedParams, key, value);
      return `?${clonedParams.toString()}`;
    },
    [params],
  );

  const setParams = useCallback(
    (updates: UrlParamUpdates<T>) => {
      applyUpdatesandNavigate(updates);
    },
    [applyUpdatesandNavigate],
  );

  const linkWithParams = useCallback(
    (updates: UrlParamUpdates<T>) => {
      const clonedParams = new URLSearchParams(params);
      applyUpdates(clonedParams, updates);
      return `?${clonedParams.toString()}`;
    },
    [params],
  );

  return useMemo(
    () => ({
      search: location.search,
      params,
      getParam,
      getParamArray,
      setParam,
      linkWithParam,
      setParams,
      linkWithParams,
    }),
    [getParam, getParamArray, linkWithParam, linkWithParams, location.search, params, setParam, setParams],
  );
}

// A small version of this function that can be used to create external links without the complexity of the hook (which addresses a different use case anyway)
export function linkToUrlWithParams(url: string, params: UrlParamUpdates<string>): string {
  const builder = new URLSearchParams();
  applyUpdates(builder, params);
  return `${url}?${builder.toString()}`;
}
