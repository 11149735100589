import * as Sentry from "@sentry/browser";
import _ from "lodash";
import mixpanel from "mixpanel-browser";
import React, { RefObject, useCallback, useState } from "react";

import { useToast } from "../../../../../shared/components/design-system/Toaster/context";
import Button from "../../design-system/Button";

export default function ExportContainerButton({
  disabled,
  container,
}: {
  disabled?: boolean;
  container: RefObject<HTMLElement>;
}) {
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const download = useCallback(async () => {
    try {
      setLoading(true);
      await downloadChartAsImage(container.current!);
    } catch (error) {
      toast.danger(
        "There was an error downloading your chart. The error has been recorded for investigation. In the meantime, consider using a screengrab for this image.",
      );
      Sentry.captureException(error);
    }
    setLoading(false);
  }, [container, toast]);

  return (
    <>
      <Button variant="action" onClick={download} loading={loading} disabled={disabled} icon="arrowToBottom">
        Download
      </Button>
    </>
  );
}

function downloadChartAsImage(el: HTMLElement) {
  mixpanel.track("Downloaded chart");
  const width = Math.round(el.clientWidth);
  const height = Math.round(el.clientHeight);
  const svg = `<svg
    width="${width}" height="${height}"
    viewBox="0 0 ${width} ${height}"
    xmlns="http://www.w3.org/2000/svg"
  >
    <style type="text/css">
      ${[...document.styleSheets]
        .flatMap(({ cssRules }) => [...cssRules].map(({ cssText }) => _.escape(cssText)))
        .join("\n")}
      * {
        font-family: sans-serif;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
      }
      .export-container {
        container: exportable / size;
        position: absolute;
        inset: 0;
        box-sizing: border-box;
      }
      /* If we're exporting a card, we want the contents, not the card itself. */
      .export-container > * {
        background: none !important;
        box-shadow: none !important;
      }
    </style>
    <rect x="0" y="0" width="${width}" height="${height}" fill="#fff" rx="8" />
    <foreignObject x="0" width="${width}" y="0" height="${height}">
      <div xmlns="http://www.w3.org/1999/xhtml" class="export-container">
        ${el.outerHTML}
      </div>
    </foreignObject>
  </svg>`;
  return new Promise<void>((resolve, reject) => {
    const img = new Image();
    img.addEventListener("load", () => {
      const canvas = document.createElement("canvas");
      // Export at double size, since it's an SVG anyway and it gives a crisper, more versatile output - TODO: this would be a nice thing to make as an option some time
      canvas.width = width * 2;
      canvas.height = height * 2;
      canvas.getContext("2d")!.drawImage(img, 0, 0, width, height, 0, 0, canvas.width, canvas.height);
      const a = document.createElement("a");
      a.download = "chart.png";
      a.href = canvas.toDataURL();
      a.click();
      resolve();
    });
    img.addEventListener("error", (event) => {
      Sentry.setExtra("svg", svg);
      reject(event.error);
    });
    img.src = `data:image/svg+xml,${encodeURIComponent(svg)}`;
  });
}
