import { max, min } from "date-fns";
import React, { FocusEvent, useCallback, useRef } from "react";

import "../../styles.scss";
import { dateRange, DateRange } from "../../types";
import DateInput from "../DateInput";

// These are form components: they can return invalid data but will mark themselves as invalid when they do.

export default function DateRangeInput({
  value,
  onChange,
  required,
  minDate,
  maxDate,
  allowOpenEnded,
}: {
  value: DateRange | null;
  onChange: (value: DateRange | null) => void;
  required?: boolean;
  minDate?: Date;
  maxDate?: Date;
  allowOpenEnded?: boolean;
}) {
  const update = useCallback(
    (startDate: Date | undefined, endDate: Date | undefined) => {
      if (!startDate && !endDate) {
        onChange(null);
        return;
      }
      onChange(dateRange({ startDate, endDate }, { allowBackwards: true }));
    },
    [onChange],
  );

  const setStart = useCallback(
    (start: Date | null) => update(start ?? undefined, value?.endDate),
    [update, value?.endDate],
  );

  const setEnd = useCallback(
    (end: Date | null) => update(value?.startDate, end ?? undefined),
    [update, value?.startDate],
  );

  const ref = useRef<HTMLDivElement>(null);
  const blur = useCallback(
    (e: FocusEvent) => {
      // We only want to fire this if focus has left the entire div
      if (isInside(e.relatedTarget, ref.current!)) return;
      // If we've set a value, validate it fully — including swapping the start and end dates if needed
      if (value) onChange(dateRange(value));
    },
    [onChange, value],
  );

  return (
    <div className="c-date-range" ref={ref} onBlur={blur}>
      <DateInput
        placeholder="Start date"
        value={value?.startDate ?? null}
        onChange={setStart}
        minDate={minDate}
        maxDate={coalesced(min, maxDate, value?.endDate)}
        required={value ? !allowOpenEnded : required}
      />
      <DateInput
        placeholder="End date"
        value={value?.endDate ?? null}
        onChange={setEnd}
        minDate={coalesced(max, minDate, value?.startDate)}
        maxDate={maxDate}
        required={value ? !allowOpenEnded : required}
      />
    </div>
  );
}

function coalesced(callback: (dates: Date[]) => Date, a?: Date, b?: Date) {
  if (!a) return b;
  if (!b) return a;
  return callback([a, b]);
}

function isInside(inner: Element | null, outer: Element) {
  for (let el: Element | null = inner; el; el = el.parentElement) {
    if (el === outer) return true;
  }
  return false;
}
