import React, { useMemo } from "react";

import { TriggerRef } from "../../../../../hooks/useTrigger";
import { DateRange } from "../../types";
import DisplayCalendar from "./DisplayCalendar";
import "./styles.scss";

export default function DateCalendar({
  minDate,
  maxDate,
  value,
  onChange,
  scrollTo,
}: {
  minDate: Date;
  maxDate: Date;
  value?: Date;
  onChange: (value: Date) => void;
  scrollTo: TriggerRef<Date | null>;
}) {
  const highlight: DateRange | null = useMemo(() => (value ? { startDate: value, endDate: value } : null), [value]);
  return (
    <DisplayCalendar
      highlight={highlight}
      minDate={minDate}
      maxDate={maxDate}
      onClickDay={onChange}
      scrollTo={scrollTo}
    />
  );
}
