import React, { FunctionComponent, useMemo } from "react";

import { useUrlParams } from "../../../hooks/useUrlParams";
import TabBar from "../../design-system/TabBar";

function filterUrlParameters(
  { url, params: paramsToKeep }: { url: string; params: string[] },
  params: URLSearchParams,
): string {
  const newParams = new URLSearchParams();
  for (const key of paramsToKeep) {
    const value = params.get(key);
    if (value) {
      newParams.set(key, value);
    }
  }
  const search = newParams.toString();
  return search ? `${url}?${search}` : url;
}

function AnalysisNavigator<T extends string>({
  items,
  active,
  className,
}: {
  items: Array<{
    label: string;
    url: string;
    params: string[];
    tag: T;
  }>;
  active: T;
  className?: string;
}) {
  const { params } = useUrlParams();
  const tabs = useMemo(
    () =>
      items.map((item) => ({
        label: item.label,
        link: filterUrlParameters(item, params),
        isActive: active === item.tag,
      })),
    [active, items, params],
  );
  return <TabBar items={tabs} className={className} />;
}

const reportsItems = [
  {
    label: "At a glance",
    url: "/analytics/at-a-glance",
    params: ["period", "start", "end", "org"],
    tag: "at-a-glance",
  },
  {
    label: "In detail",
    url: "/analytics/in-detail",
    params: ["period", "start", "end", "org", "code"],
    tag: "in-detail",
  },
  {
    label: "Over time",
    url: "/analytics/over-time",
    params: ["org", "code"],
    tag: "over-time",
  },
];

export const ReportsAnalysisNavigator: FunctionComponent<{
  active: "at-a-glance" | "in-detail" | "over-time";
  className?: string;
}> = ({ active, className }) => <AnalysisNavigator items={reportsItems} active={active} className={className} />;
