import mixpanel from "mixpanel-browser";
import React, { useCallback } from "react";

import Button from "../../../../../../../shared/components/design-system/Button";
import { useToast } from "../../../../../../../shared/components/design-system/Toaster/context";
import { Translatable } from "../../../../../../../shared/components/translation";
import { importTranslationsBackground } from "../../../../../../../shared/translation/initialisation";
import styles from "./styles.module.scss";
import english from "./translations/en-GB.json";

const clipboardAvailable = !!navigator.clipboard?.writeText;

importTranslationsBackground("CopyButton", { "en-GB": english });

export default function CopyButton({
  text,
  tracker,
  label = "CopyButton:defaultLabel",
  ...iconProps
}: { text: string; tracker?: string; label?: Translatable } & (
  | { iconButton?: false }
  | { iconButton: true; size?: "small" | "xsmall" }
)) {
  const toast = useToast();
  const onClick = useCallback(async () => {
    try {
      if (tracker) mixpanel.track(tracker);
      await navigator.clipboard?.writeText(text);
      toast.success("CopyButton:success");
    } catch (e) {
      toast.danger("CopyButton:error");
    }
  }, [text, tracker, toast]);

  if (!clipboardAvailable) return null;

  return (
    <Button
      variant={iconProps.iconButton ? "ghost" : "secondary"}
      onClick={onClick}
      icon="clone"
      {...iconProps}
      label={label}
    />
  );
}

export function CopyableText({ className, text, tracker }: { className?: string; text: string; tracker?: string }) {
  return (
    <div className={`${styles.CopyableText} ${className ?? ""}`}>
      <span className={styles.text}>{text}</span>
      <CopyButton text={text} tracker={tracker} iconButton size="small" />
    </div>
  );
}
