import React from "react";

import TextInput, { TextInputProps, TextInputType } from "./base";

const EMAIL_ADDRESS = /^.+@.+\..+$/;

/** Exported only for tests */
export const link: TextInputType<string> = {
  inputType: "text",
  toString(value) {
    if (value.startsWith("mailto:")) {
      return value.substring(7);
    }
    return value;
  },
  parse(value) {
    if (!value) return { invalid: false, value: "" };
    if (value.startsWith("https://") || value.startsWith("http://") || value.startsWith("/")) {
      return { invalid: false, value };
    }
    if (value.length > 0 && !EMAIL_ADDRESS.test(value) && !/^https?:\/\//.test(value)) {
      return { invalid: false, value: "https://" + value };
    }
    if (EMAIL_ADDRESS.test(value)) {
      if (value.startsWith("mailto:")) return { invalid: "Do not include the 'mailto' prefix" };
      return { invalid: false, value: `mailto:${value}` };
    }
    return { invalid: "Please enter a valid URL or email address" };
  },
};

/**
 * Allows users to input a URL or email address
 */
export default function LinkInput(props: TextInputProps<string>) {
  return <TextInput type={link} {...props} />;
}

const email: TextInputType<string> = {
  inputType: "email",
  toString(value) {
    return value;
  },
  parse(value) {
    return { invalid: false, value };
  },
};

/**
 * Allows users to input a URL or email address
 */
export function EmailInput(props: TextInputProps<string>) {
  return <TextInput type={email} {...props} />;
}

const url: TextInputType<string> = {
  inputType: "url",
  toString(value) {
    return value;
  },
  parse(value) {
    if (value.length > 0 && !EMAIL_ADDRESS.test(value) && !/^https?:\/\//.test(value)) {
      return { invalid: false, value: "https://" + value };
    }
    return { invalid: false, value };
  },
};

/**
 * Allows users to input a URL or email address
 */
export function UrlInput(props: TextInputProps<string>) {
  return <TextInput type={url} {...props} />;
}
