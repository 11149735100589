import { useMemo } from "react";

import { AnalyticsCode, FormCategory, QuestionAnalyticsCode } from "../../../__generated__/graphql";
import useOrgData from "../hooks/useOrgData";
import useSelf from "../hooks/useSelf";

export type AnalyticsCodeDescription = {
  code: string;
  label: string;
  long: string;
};

const surveyFormCode = {
  // This code has a different description for surveys, but it's (currently) the only one, so rather than have a whole system for handling that kind of thing we just sub in this one if it's needed.
  code: AnalyticsCode.Form,
  label: "Survey form",
  long: "Which form did people complete?",
};

/** These are the analytics codes for doing analytics on — they include ones that aren't questions, such as form and outome. */
export const analyticsCodeDescriptions = [
  {
    code: AnalyticsCode.IncidentType,
    label: "Incident type",
    long: "What are people reporting?",
  },
  {
    // This one's not a question!
    code: AnalyticsCode.Form,
    label: "Report form",
    long: "How are people reporting incidents?",
  },
  {
    // This one's not a question!
    code: AnalyticsCode.Outcome,
    label: "Outcome",
    long: "What happened to submitted reports?",
  },
  {
    code: AnalyticsCode.Role,
    label: "Relationship to organisation",
    long: "Who's reporting?",
  },
  {
    code: AnalyticsCode.Faculty,
    label: "Department",
    long: "What departments are reporting?",
  },
  {
    code: AnalyticsCode.Location,
    label: "Location",
    long: "Where did the incidents occur?",
  },
  {
    code: AnalyticsCode.AnonymousReason,
    label: "Anonymous reason",
    long: "Why are people reporting anonymously?",
  },
  { code: AnalyticsCode.Gender, label: "Gender", long: "Which genders are reporting?" },
  // These are null because you should just use the text of the question here (since "are they trans" and "do they identify with their birth gender" make "yes" and "no" mean different things)
  { code: AnalyticsCode.Trans, label: null, long: null },
  {
    code: AnalyticsCode.Ethnicity,
    label: "Ethnicity",
    long: "Which races/ethnicities are reporting?",
  },
  {
    code: AnalyticsCode.SexualIdentity,
    label: "Sexual identity",
    long: "Which sexual identities are reporting?",
  },
  { code: AnalyticsCode.Age, label: "Age", long: "What are the ages of the reporters?" },
  {
    code: AnalyticsCode.Disability,
    label: "Disability",
    long: "Do the reporters identify with any disabilities?",
  },
  {
    // This one is a question but heads up: it just returns the question tagged with this analytics code, NOT a boolean for whether the user actually opted in to messaging.
    code: AnalyticsCode.MessageOptIn,
    label: "Opt-in to messaging",
    long: "Does the reporter wish to be contacted for messaging?",
  },
  {
    code: AnalyticsCode.Religion,
    label: "Faith/religion",
    long: "What are the faiths/religions of the reporters?",
  },
  {
    code: AnalyticsCode.SuspectedCause,
    label: "Suspected factor",
    long: "Why did the incidents occur?",
  },
] as const;

/** These are the analytics codes that can be applied to questions — they include ones that don't really make sense to do analytics on */
export const questionAnalyticsCodeDescriptions = [
  { code: QuestionAnalyticsCode.IncidentType, label: "Incident type" },
  { code: QuestionAnalyticsCode.Role, label: "Relationship to organisation" },
  { code: QuestionAnalyticsCode.Faculty, label: "Department" },
  { code: QuestionAnalyticsCode.Location, label: "Location" },
  { code: QuestionAnalyticsCode.AnonymousReason, label: "Anonymous reason" },
  { code: QuestionAnalyticsCode.Gender, label: "Gender" },
  { code: QuestionAnalyticsCode.Trans, label: "Trans?" },
  { code: QuestionAnalyticsCode.Ethnicity, label: "Ethnicity" },
  { code: QuestionAnalyticsCode.SexualIdentity, label: "Sexual identity" },
  { code: QuestionAnalyticsCode.Age, label: "Age" },
  { code: QuestionAnalyticsCode.Disability, label: "Disability" },
  { code: QuestionAnalyticsCode.MessageOptIn, label: "Opt-In to Messaging" },
  { code: QuestionAnalyticsCode.Religion, label: "Faith/religion" },
  { code: QuestionAnalyticsCode.SuspectedCause, label: "Suspected factor" },
  { code: QuestionAnalyticsCode.ReportedBy, label: "Name of reporter" },
  { code: QuestionAnalyticsCode.ContactDetails, label: "Contact details" },
] as const;

export function useAvailableAnalyticsCodes(category: FormCategory): {
  /** These are the built-in codes, so that's things like GENDER that tag questions but also things like OUTCOME and FORM. */
  commonCodes: AnalyticsCodeDescription[];
  /** These are made-up codes of the form "custom-[some uuid]" which just mean "this question in particular". It's null if you aren't allowed to do this, which usually means you're on the main report screen and don't have the "analyse any question" feature gate. */
  customCodes: AnalyticsCodeDescription[] | null;
  /** This is just a single array with both of those things in. */
  allCodes: AnalyticsCodeDescription[];
} {
  const { questions } = useOrgData();
  const self = useSelf();

  const common = useMemo(() => {
    const validDescriptions: AnalyticsCodeDescription[] = [];
    for (const description of analyticsCodeDescriptions) {
      // Codes that are always allowed:
      if (description.code === AnalyticsCode.Form) {
        validDescriptions.push(category === FormCategory.Survey ? surveyFormCode : description);
        continue;
      }
      // Codes that are always allowed for reports but never for survey responses:
      if (description.code === AnalyticsCode.Outcome) {
        if (category === FormCategory.Reporting) validDescriptions.push(description);
        continue;
      }
      // The rest should be question codes:
      const exampleQuestion = questions.find(
        (q) =>
          q.analyticsCode &&
          (q.analyticsCode as unknown as AnalyticsCode) === description.code &&
          q.category === category,
      );
      if (!exampleQuestion) continue;
      validDescriptions.push({
        code: description.code,
        label: description.label ?? exampleQuestion.shortTitle,
        long: description.long ?? exampleQuestion.title,
      });
    }
    return validDescriptions;
  }, [category, questions]);

  const custom = useMemo(() => {
    if (!self.org.featureGates.includes("analyseAnyQuestion")) return null;
    const analysableQuestions = questions.filter((q) => {
      // Remove any question that doesn't have options, since we can't analyse that
      if (!q.optionGroups) return false;
      // Remove any question that's in the wrong category
      if (q.category !== category) return false;
      // Remove any question that is already uniquely identified by an analytics code
      if (!q.analyticsCode) return true;
      if (questions.some((other) => other.id !== q.id && other.analyticsCode === q.analyticsCode)) return true;
      return false;
    });
    return analysableQuestions.map((q) => ({
      code: `custom-${q.id}`,
      label: q.shortTitle,
      long: q.title,
    }));
  }, [category, questions, self.org.featureGates]);

  const all = useMemo(() => (custom ? [...common, ...custom] : common), [common, custom]);

  return { commonCodes: common, customCodes: custom, allCodes: all };
}
