import gql from "graphql-tag";

export const UPLOAD_IMAGE = gql`
  mutation uploadImage($siteId: ID!) {
    uploadImage(siteId: $siteId) {
      uploadUrl
      publicUrl
    }
  }
`;

export const REORDER_ARTICLES_MUTATION = gql`
  mutation reorderArticles($siteId: ID!, $order: [CategoryOrderInput!]!) {
    reorderArticles(siteId: $siteId, order: $order) {
      id
      categories {
        id
        articles {
          id
          name
          order
        }
      }
    }
  }
`;

export const REORDER_CATEGORIES_MUTATION = gql`
  mutation reorderCategories($siteId: ID!, $categories: [ID!]!) {
    reorderCategories(siteId: $siteId, categories: $categories) {
      id
      categories {
        id
        name
        order
        articles {
          id
          name
          order
        }
      }
    }
  }
`;

export const REORDER_FORMS_MUTATION = gql`
  mutation reorderForms($siteId: ID!, $forms: [ID!]!) {
    reorderForms(siteId: $siteId, forms: $forms) {
      id
      forms {
        id
        name
        order
      }
    }
  }
`;
