import { useApolloClient } from "@apollo/client";
import gql from "graphql-tag";

import { AnalyticsQuery, AnalyticsQueryVariables, AnalyticsSplit } from "../../../../__generated__/graphql";
import useAsyncMemo from "../../../../shared/hooks/useAsyncMemo";
import useDeepEquality from "../../../../shared/hooks/useDeepEquality";

// This file defines a hook which accepts an array of AnalyticsQuery objects and runs them all against the API.

export type AnalyticsQueryResult = Omit<AnalyticsQuery["reportsAnalysis"][number], "__typename">;

export type AnalyticsQueryProps = AnalyticsQueryVariables & { splits?: AnalyticsSplit[] };

// Results are returned as an array of these:

export interface AnalyticsResult {
  query: AnalyticsQueryProps;
  data: AnalyticsQueryResult[];
}

// This is useful because Apollo's useQuery (and all its helpful TypeScript type generation) very much assumes you have a fixed query and only the parameters change. For the Over Time analytics, at least, we now support both 12- and 24-month queries and that could soon expand to 36- and 48- month versions. Rather than have hardcoded versions of the already-verbose query at each length we might want (and have to maintain them whenever the analytics API signature changes), this file dynamically runs 12 or 24 queries which Apollo will batch automatically.

// This GraphQL fragment defines the return type. It has more than we need for Over Time in case we want to use it for a future version of In Detail (and all the extra data is trivially null so it shouldn't materially affect the API speed or payload size)
const QUERY = gql`
  query analytics($organisationId: ID, $splits: [AnalyticsSplit!], $search: ReportSearch, $language: String) {
    reportsAnalysis(organisationId: $organisationId, splits: $splits, search: $search, language: $language) {
      label
      value
      status
      question {
        id
      }
      form {
        id
      }
      outcome {
        id
      }
      optionId
      groupId
      segments {
        label
        value
        status
        question {
          id
        }
        form {
          id
        }
        outcome {
          id
        }
        optionId
        groupId
      }
    }
  }
`;

export type UseAnalyticsQueryResult = {
  loading: boolean;
  data: { query: AnalyticsQueryProps; data: AnalyticsQueryResult[] }[] | null;
  error: Error | null;
};

// NOTE: This function could be trivially genericised to something like
// useRepeatedQuery<analytics, AnalyticsQuery, "reportsAnalysis">("reportsAnalysis", QUERY, variables)"
// if we find we need that, but for readability it's concrete for now.

export default function useAnalyticsQuery(queries: AnalyticsQueryProps[], skip?: boolean): UseAnalyticsQueryResult {
  const memoisedQueries = useDeepEquality(queries);

  // We can't call useQuery a variable number of times because of the Almighty and Unquestionable Rules of Hooks
  // (praise be upon them) so we have to fetch the client this way and run the queries old-school, then store
  // them in our own data structure
  const apollo = useApolloClient();

  const result = useAsyncMemo(async () => {
    if (skip) return []; // Just any valid result object is fine here, we'll never see it.

    const data = await Promise.all(
      memoisedQueries.map((variables) =>
        apollo.query<AnalyticsQuery, AnalyticsQueryVariables>({ query: QUERY, variables }).then((result) => {
          if (result.error) throw result.error;
          if (result.errors?.[0]) throw result.errors[0];
          return result.data.reportsAnalysis;
        }),
      ),
    );

    return memoisedQueries.map((query, i) => ({ query, data: data[i] }));
  }, [memoisedQueries, apollo, skip]);

  if (skip) return { loading: false, data: null, error: null };

  return result;
}
