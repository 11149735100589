import { useFocusRing } from "@react-aria/focus";
import { VisuallyHidden } from "@react-aria/visually-hidden";
import classNames from "classnames";
import React, { ChangeEvent, useCallback, useEffect, useRef } from "react";

import useLoadingDelay from "../../../../../shared/components/design-system/Button/useLoadingDelay";
import LoadingSpinner from "../../../../../shared/components/design-system/LoadingSpinner";
import styles from "./styles.module.scss";

// "input" visually resembles any other input label - small, heavy text
// "default" is the larger text we usuallyuse for toggles
// "no-label" hides the label and just renders the toggle itself as a little inline block
const VARIANT_CLASSES = {
  input: "c-input-label",
  default: undefined,
  "no-label": styles.visuallyHideLabel,
};

export interface ToggleSwitchProps {
  variant?: keyof typeof VARIANT_CLASSES;
  label: string;
  className?: string;
  checked: boolean;
  disabled?: boolean;
  loading?: boolean;
  invalidReason?: string | null;
  onChange: (checked: boolean) => void;
  disableLoadingDelay?: boolean;
}

export default function ToggleSwitch({
  variant = "default",
  label,
  className,
  checked,
  disabled,
  loading,
  onChange,
  invalidReason,
  disableLoadingDelay,
}: ToggleSwitchProps) {
  const ref = useRef<HTMLInputElement | null>(null);
  const { isFocusVisible, focusProps } = useFocusRing();
  const isLoading = useLoadingDelay({ disable: disableLoadingDelay, loading });

  const innerOnChange = useCallback((e: ChangeEvent<HTMLInputElement>) => onChange(e.target.checked), [onChange]);

  useEffect(() => ref.current?.setCustomValidity(invalidReason ?? ""), [invalidReason]);

  const toggleSwitchClass = classNames(
    {
      [styles.checked]: checked,
      [styles.disabled]: disabled,
      [styles.focueVisible]: isFocusVisible,
    },
    styles.ToggleSwitch,
    VARIANT_CLASSES[variant],
    className,
  );

  return (
    <label className={toggleSwitchClass}>
      <span className={styles.labelSpan}>{label}</span>
      <VisuallyHidden>
        <input
          {...focusProps}
          ref={ref}
          type="checkbox"
          onChange={innerOnChange}
          disabled={isLoading || disabled}
          checked={checked}
        />
      </VisuallyHidden>
      {isLoading ? <LoadingSpinner size="small" /> : <span className={styles.Toggle} />}
    </label>
  );
}
