import { GetSelfQuery } from "../../../__generated__/graphql";
import useOrg from "./useOrg";

export type Self = GetSelfQuery["self"] & { isSuperAdmin: boolean };

/** @deprecated No longer does anything really, but wraps the new useOrg hook so no code changes are needed */
export default function useSelf(): Self {
  const { loggedInUser, isSuperAdmin } = useOrg();
  return { ...loggedInUser, isSuperAdmin };
}
