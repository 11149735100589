import mixpanel from "mixpanel-browser";
import React, { useCallback, useMemo } from "react";

import { yearLabel } from "../../../../../shared/components/charts/months";
import Select from "../../design-system/Select";

export const MIN_YEAR = 2015;
export const MAX_YEAR = new Date().getFullYear();

type Params = {
  label: string;
  value: string | null;
  className?: string;
  exclude?: string;
  month: string;
  duration: string;
  trackingText: string;
  required?: boolean;
} & (
  | { onChange: (value: string | null) => void }
  | {
      onChange: (value: string) => void;
      required: true;
    }
);

export default function YearSelect({
  label,
  value,
  className,
  exclude,
  month,
  duration,
  trackingText,
  required,
  onChange,
}: Params) {
  const options = useMemo(() => {
    const years: { label: string; value: string }[] = [];
    for (let year = MIN_YEAR; year <= MAX_YEAR; year++) {
      const value = year.toString();
      if (value === exclude) continue;
      years.push({
        label: yearLabel(value, month, duration),
        value,
      });
    }
    return years;
  }, [duration, exclude, month]);

  const changeYear = useCallback(
    (value: string) => {
      mixpanel.track(trackingText, { year: value });
      // If 'required' is set there should never be a falsey value anyway, but apparently TypeScript knows that the argument type of onChange depends on the value of required so we might as well take advantage of that.
      if (required) onChange(value);
      else onChange(value || null);
    },
    [required, onChange, trackingText],
  );

  return (
    <Select label={label} size="medium" onChange={changeYear} value={value ?? ""} className={className}>
      {required ? null : <option value="">None</option>}
      {options.map(({ value, label }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </Select>
  );
}
