import { isEqual } from "lodash";
import { useCallback } from "react";

import { useConfirm } from "../../../../../hooks/useAlert";

export default function useCancelWithConfirm<T>(
  cancel: () => void,
  cleanValue: T,
  currentValue: T,
  ...otherChecks: boolean[]
) {
  const confirm = useConfirm();

  const isClean = otherChecks.every((isClean) => isClean) && isEqual(cleanValue, currentValue);

  return useCallback(async () => {
    if (
      isClean ||
      (await confirm({
        title: "Discard unsaved changes?",
        okCaption: "Discard changes",
        okVariant: "primary",
        cancelCaption: "Cancel",
        children: "If you leave this page all unsaved changes will be lost.",
      }))
    ) {
      cancel();
    }
  }, [isClean, confirm, cancel]);
}
