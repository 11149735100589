import mixpanel from "mixpanel-browser";
import React, { FunctionComponent } from "react";

import Alert from "../../../../shared/components/design-system/Alert";
import Container from "../design-system/Container";

const onEmailClick = (billingWarning: string) => () => {
  mixpanel.track("Clicked billing warning email link", { Warning: billingWarning });
};

export const BillOverdue: FunctionComponent = () => (
  <Container className="ds-mt-7">
    <Alert title="Urgent billing issue" variant="danger">
      Your annual license fee is now due. In order to avoid suspension of your site, please ensure payment is made. If
      you have any questions, please contact Culture Shift on 07908 814 006 or email{" "}
      <a href="mailto:accounts@culture-shift.co.uk" onClick={onEmailClick("Bill overdue")}>
        accounts@culture-shift.co.uk
      </a>
      .
    </Alert>
  </Container>
);

export const ContractExpiryDue: FunctionComponent = () => (
  <Container className="ds-mt-7">
    <Alert title="Your contract will expire soon" variant="warning">
      Please contact your Customer Success Manager, or contact the Accounts team on 07908 814 006 or email{" "}
      <a href="mailto:accounts@culture-shift.co.uk" onClick={onEmailClick("Contract expiry due")}>
        accounts@culture-shift.co.uk
      </a>
      .
    </Alert>
  </Container>
);
