import React, { MouseEvent, useCallback, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";

import Icon, { IconSlug } from "../../../../../shared/components/design-system/Icon";
import NewTag from "../../design-system/NewTag";
import Tooltip from "../../design-system/Tooltip";
import "./styles.scss";

export default function NavigationItem({
  title,
  closeMobileNavigation,
  icon,
  onClick,
  to,
  subPages = [], // Other 'folders' that cause this tab to highlight as active
  neverActive,
  isNew = false,
  isNavCollapsed,
}: {
  to: string;
  subPages?: string[];
  title: string;
  onClick?: (event: MouseEvent) => void;
  icon: IconSlug;
  neverActive?: boolean;
  isNew?: boolean;
  closeMobileNavigation: () => void;
  isNavCollapsed: boolean;
}) {
  const navItemRef = useRef<HTMLLIElement>(null);
  const isInternal = to.startsWith("/");

  const handleNavLinkClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      onClick?.(event);
      closeMobileNavigation();
      if (isInternal) {
        (event.target as HTMLAnchorElement).blur();
      }
    },
    [isInternal, onClick, closeMobileNavigation],
  );

  // NavLink can calculate isActive for the main target, but we have to do it anyway for the sub-pages so we might as well use the same code for both.
  const { pathname } = useLocation();
  const isActive =
    !neverActive && isInternal && [to, ...subPages].some((url) => pathname === url || pathname.startsWith(`${url}/`));

  return (
    <li ref={navItemRef} className="c-navigation__item">
      <NavLink
        onClick={handleNavLinkClick}
        className={`c-navigation__link ${isActive ? "is-active" : ""}`}
        to={`${to}#main-content`}
        target={isInternal ? undefined : "_blank"}
      >
        <NavLinkContent isNew={isNew} icon={icon} isNavCollapsed={isNavCollapsed} title={title} />
        {isNavCollapsed ? <Tooltip anchorRef={navItemRef}>{title}</Tooltip> : null}
      </NavLink>
    </li>
  );
}

export function NavLinkContent({
  isNew,
  icon,
  isNavCollapsed,
  title,
}: {
  isNew: boolean;
  icon: IconSlug;
  isNavCollapsed: boolean;
  title: string;
}) {
  return (
    <>
      <span className="c-navigation__link-icon" aria-hidden="true">
        <Icon icon={icon} />
      </span>
      {isNew ? (
        <NewTag visible={!isNavCollapsed} position="right">
          <span className="c-navigation__link-label">{title}</span>
        </NewTag>
      ) : (
        <span className="c-navigation__link-label">{title}</span>
      )}
    </>
  );
}
