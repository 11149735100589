import { ApolloClient, ApolloLink, ApolloProvider, createHttpLink, InMemoryCache } from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { removeTypenameFromVariables } from "@apollo/client/link/remove-typename";
import * as Sentry from "@sentry/browser";
import { SentryLink } from "apollo-link-sentry";
import { Auth } from "aws-amplify";
import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useFlag } from "react-unleash-flags";

import LoadingSpinner from "../../../../shared/components/design-system/LoadingSpinner";
import { useToast } from "../../../../shared/components/design-system/Toaster/context";
import useRefOf from "../../../../shared/hooks/useRefOf";
import SignInPage from "../auth/SignInPage";
import EmptyStateBanner from "../design-system/EmptyStateBanner";

const logOutContext = createContext<() => void>(() => null);

/** Wraps its children in a context that provides access to our GraphQL API, as well as handling Cognito login and logout. Must be inside a ToastProvider as it uses toasts to communicate with the user. */
export default function AppWithApollo({ children }: PropsWithChildren<unknown>) {
  const versionMismatchFeatureFlag = useFlag("version_mismatch_notice")?.enabled;
  const batchFlagRef = useRefOf(useFlag("api-batching"));
  const toast = useToast();

  const [error, setError] = useState<Error | null>(null);
  const [awaitingLogOut, setAwaitingLogOut] = useState(false);
  const [loggedOut, setLoggedOut] = useState(false);
  const [showVersionMismatchToast, setShowVersionMismatchToast] = useState(false);

  const logOut = useCallback(async () => {
    setAwaitingLogOut(true);
    await Auth.signOut();
    setAwaitingLogOut(false);
    setLoggedOut(true);
  }, []);

  const apolloClient = useMemo(
    () =>
      new ApolloClient({
        link: ApolloLink.from([
          removeTypenameFromVariables(),
          new SentryLink({
            uri: `${import.meta.env.REACT_APP_API_URL}/graphql`,
            setTransaction: false,
            attachBreadcrumbs: {
              includeQuery: true,
              includeVariables: false,
              includeError: true,
              includeCache: false,
            },
          }),
          onError(({ graphQLErrors, networkError }) => {
            if (
              networkError &&
              typeof networkError === "object" &&
              "statusCode" in networkError &&
              networkError.statusCode >= 500
            ) {
              Sentry.captureException(networkError);
            }
            for (const graphQLError of graphQLErrors ?? []) {
              if (
                graphQLError.extensions?.code === "FORBIDDEN" ||
                graphQLError.extensions?.code === "UNAUTHORIZED" ||
                graphQLError.message === "Your session has expired"
              ) {
                continue;
              }
              // this seems to be needed to ensure the breadcrumbs get correctly set
              setImmediate(() =>
                Sentry.captureException(new Error(graphQLError.message), {
                  fingerprint: ["{{ default }}", graphQLError.message],
                  extra: graphQLError.extensions
                    ? { code: graphQLError.extensions.code, ...(graphQLError.extensions.exception ?? {}) }
                    : {},
                }),
              );
            }
          }),
          new ApolloLink((operation, forward) =>
            forward(operation).map((response) => {
              const {
                response: { headers },
              } = operation.getContext();
              const serverVersion = headers?.get("CultureShift-Version");
              if (
                import.meta.env.REACT_APP_SENTRY_RELEASE &&
                serverVersion !== import.meta.env.REACT_APP_SENTRY_RELEASE
              ) {
                setShowVersionMismatchToast(true);
              }
              return response;
            }),
          ),
          setContext(async (_, context) => {
            try {
              const session = await Auth.currentSession();

              if (!session.isValid()) {
                console.log("Session is invalid — logging out");
                await logOut();
                return;
              }

              return {
                ...context,
                headers: {
                  ...context.headers,
                  authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
                },
                doNotBatch: context.doNotBatch || !batchFlagRef.current?.enabled,
              };
            } catch (e) {
              // Yep, Amplify throws strings in some situations — eg, "No current user" is thrown as a string — but it's easier to just treat everything the same, so:
              const errorString = (e as Error)?.message || (e as string);
              switch (errorString) {
                case "No current user":
                case "Refresh Token has expired":
                  console.log(errorString, "— logging out");
                  await logOut();
                  break;
                default:
                  setError(e as Error);
                  break;
              }
            }
          }),
          ApolloLink.split(
            (operation) => operation.getContext().doNotBatch,
            createHttpLink({ uri: `${import.meta.env.REACT_APP_API_URL}/graphql` }),
            new BatchHttpLink({ uri: `${import.meta.env.REACT_APP_API_URL}/graphql`, batchMax: 25, batchInterval: 5 }),
          ),
        ]),
        cache: new InMemoryCache({
          possibleTypes: {
            CustomReportAccessFilter: ["CustomReportAccessFilterByQuestion", "CustomReportAccessFilterByForm"],
          },
        }),
        defaultOptions: { watchQuery: { fetchPolicy: "cache-and-network" } },
      }),
    [batchFlagRef, logOut],
  );

  useEffect(() => {
    if (showVersionMismatchToast && versionMismatchFeatureFlag) {
      toast.warning("Please refresh your browser", { title: "New version available" });
    }
  }, [showVersionMismatchToast, toast, versionMismatchFeatureFlag]);

  const onLogIn = useCallback(() => {
    setError(null);
    setLoggedOut(false);
  }, []);

  if (awaitingLogOut) return <LoadingSpinner className="ds-mt-8" />;
  if (error) return <EmptyStateBanner error={error} body="An unexpected error occurred during sign-in" />;
  if (loggedOut) return <SignInPage onSignedIn={onLogIn} />;

  return (
    <logOutContext.Provider value={logOut}>
      <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
    </logOutContext.Provider>
  );
}

export function useLogOut() {
  return useContext(logOutContext);
}
