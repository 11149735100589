import classNames from "classnames";
import React, { forwardRef, ReactNode } from "react";

import styles from "./index.module.scss";

export interface CardProps {
  children: ReactNode;
  className?: string;
  contentClassName?: string;
  image?: string;
  id?: string;
  compact?: boolean;
}

const Card = forwardRef<HTMLDivElement, CardProps>(
  ({ children, className, contentClassName: contentClassNameProp, image, id, compact }, ref) => {
    const rootClassName = classNames(styles.Card, className, compact ? styles.compact : null);

    const contentClassName = classNames(styles.content, contentClassNameProp);

    return (
      <div className={rootClassName} ref={ref} id={id}>
        {image ? (
          <div className={styles.media}>
            <img className={styles.mediaImage} src={image} alt="" />
          </div>
        ) : null}
        <div className={contentClassName}>{children}</div>
      </div>
    );
  },
);

export default Card;
