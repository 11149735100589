import classNames from "classnames";
import React, {
  DetailedHTMLProps,
  FocusEvent,
  FormEvent,
  FunctionComponent,
  InputHTMLAttributes,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { v4 as uuid } from "uuid";

import InputLabel from "../InputLabel";
import "./styles.scss";

export interface TextInputProps
  extends Pick<
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    | "id"
    | "value"
    | "className"
    | "placeholder"
    | "onChange"
    | "onPaste"
    | "onFocus"
    | "onBlur"
    | "onKeyDown"
    | "onInvalid"
    | "disabled"
    | "required"
    | "max"
    | "min"
    | "minLength"
    | "maxLength"
    | "pattern"
    | "type"
    | "autoComplete"
    | "autoFocus"
  > {
  inputClassName?: string;
  label?: string;
  labelVisuallyHidden?: boolean;
  size?: "small" | "medium";
  // Set to "true" to trigger a validation check when the user leaves the control:
  validateOnBlur?: boolean;
  // A message to display if the value is invalid. In-built browser validation messages are used as a fallback.
  defaultValidationError?: string;
  // Optionally suppress the text below the control, usually because it interferes with page layout.
  hideValidation?: boolean;
  // Any falsey value in customValidity is treated as "the value is valid". (Though this may be overruled by other validity checks such as `required`.)
  customValidity?: string | null | false;
  // As above, but shows even if the control has not been touched. This is useful for server-side errors, which do not necessarily require the user to have touched the control or for the HTML "invalid" event to fire.
  serverValidity?: string | null | false;
  // Text to display before value
  valuePrefix?: ReactNode;
  // Text to display after value
  valueSuffix?: ReactNode;
}

/**
 * @deprecated - usually you want StringInput but the other specifically-typed versions are good too
 */
const TextInput: FunctionComponent<TextInputProps> = ({
  id = uuid(),
  className,
  inputClassName,
  label,
  labelVisuallyHidden,
  size = "large",
  defaultValidationError,
  customValidity,
  serverValidity,
  value,
  validateOnBlur,
  hideValidation,
  onBlur: onBlurProp,
  onInvalid: onInvalidProp,
  valuePrefix,
  valueSuffix,
  disabled,
  ...inputProps
}) => {
  const [isInvalid, setIsInvalid] = useState(!!customValidity);
  const [touched, setTouched] = useState(false);
  const ref = useRef<HTMLInputElement | null>(null);

  useEffect(() => ref.current?.setCustomValidity(customValidity || ""), [customValidity]);

  useEffect(() => {
    if (customValidity || serverValidity) setIsInvalid(true);
    else setIsInvalid(!ref.current?.validity.valid);
  }, [customValidity, serverValidity, value]);

  const onInvalid = useCallback(
    (ev: FormEvent<HTMLInputElement>) => {
      setIsInvalid(true);
      setTouched(true);
      onInvalidProp?.(ev);
    },
    [onInvalidProp],
  );

  const onBlur = useCallback(
    (ev: FocusEvent<HTMLInputElement>) => {
      // This triggers `onInvalid` if the check fails:
      if (validateOnBlur) ref.current!.checkValidity();
      onBlurProp?.(ev);
    },
    [onBlurProp, validateOnBlur],
  );

  const showValidationErrors = serverValidity || (touched && isInvalid);

  const rootElClassName = classNames({
    "c-text-input": true,
    "is-medium": size === "medium",
    "is-invalid": showValidationErrors,
    [`${className}`]: className,
    "has-prefix": !!valuePrefix,
    "has-suffix": !!valueSuffix,
    "is-disabled": disabled,
  });

  const inputElClassName = classNames({
    "c-text-input__input": true,
    [`${inputClassName}`]: inputClassName,
  });

  const focusInput = () => {
    ref?.current?.focus();
  };

  return (
    <div className={rootElClassName}>
      {label ? (
        <InputLabel htmlFor={id} visuallyHidden={labelVisuallyHidden}>
          {label}
        </InputLabel>
      ) : null}
      <div className="c-text-input__input-wrapper">
        {valuePrefix && (
          <div className="c-text-input__prefix" onClick={focusInput}>
            {valuePrefix}
          </div>
        )}
        <input
          ref={ref}
          id={id}
          className={inputElClassName}
          value={value}
          onInvalid={onInvalid}
          onBlur={onBlur}
          disabled={disabled}
          {...inputProps}
        />

        {(showValidationErrors || valueSuffix) && (
          <div className="c-text-input__suffix" onClick={focusInput}>
            {valueSuffix}
            {showValidationErrors ? <span className="c-text-input__invalid-icon">!</span> : null}
          </div>
        )}
      </div>
      {showValidationErrors && !hideValidation ? (
        <p className="c-text-input__validation c-text-input__validation--custom">
          {ref.current?.validity.valid
            ? serverValidity
            : customValidity || defaultValidationError || ref.current?.validationMessage || serverValidity}
        </p>
      ) : null}
    </div>
  );
};

export default TextInput;
