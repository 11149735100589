import mixpanel from "mixpanel-browser";
import React, {
  PropsWithChildren,
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";

import RadioGroup, { RadioPanelContents } from ".";
import Alert from "../../../../../shared/components/design-system/Alert";
import Button from "../../../../../shared/components/design-system/Button";
import Dialog from "../../../../../shared/components/design-system/Dialog";
import {
  SectionFooter,
  SectionHeader,
} from "../../../../../shared/components/design-system/component-groups/section-header-footer";
import useBoolean from "../../../hooks/useBoolean";
import styles from "./styles.module.scss";

export interface SelectOption<T extends string> {
  id: T;
  title: string;
  body: string;
}

export interface SelectProps<T extends string> {
  title: string;
  label: string;
  okCaption: string;
  cancelCaption?: string;
  options: SelectOption<T>[];
  defaultValue?: T | null;
  trackingId: string;
  alert?: ReactElement;
  size?: "small" | "medium";
}

const context = createContext<(value: SelectProps<string>) => Promise<string | null>>(async () => null);

export function SelectProvider({ children }: PropsWithChildren<unknown>) {
  const [props, setProps] = useState<SelectProps<string> | null>(null);

  const [selection, setSelection] = useState("");
  const { value: validationVisible, setTrue: showValidation, setFalse: hideValidation } = useBoolean();

  const resolveRef = useRef<(value: string | null) => void>(() => null);

  const select = useCallback(
    (props: SelectProps<string>) =>
      new Promise<string | null>((resolve) => {
        resolveRef.current = resolve;
        setProps(props);
        setSelection(props.defaultValue ?? "");
        hideValidation();
        mixpanel.track("Opened selection panel", { id: props.trackingId });
      }),
    [hideValidation],
  );

  const onOk = useCallback(() => {
    resolveRef.current(selection);
    setProps(null);
    if (props) mixpanel.track("Closed selection panel", { id: props.trackingId, button: "ok", selection });
  }, [props, selection]);

  const onCancel = useCallback(() => {
    resolveRef.current(null);
    setProps(null);
    if (props) mixpanel.track("Closed selection panel", { id: props.trackingId, button: "cancel" });
  }, [props]);

  return (
    <context.Provider value={select}>
      {props ? (
        <Dialog isOpen onClose={onCancel} size={props.size ?? "small"}>
          <form
            onSubmit={onOk}
            onInvalid={showValidation}
            className={validationVisible ? styles.validationVisible : ""}
          >
            <SectionHeader title={props.title} />
            {props.alert ? (
              <Alert variant="info" className="ds-mb-5">
                {props.alert}
              </Alert>
            ) : null}
            <RadioGroup
              label={props.label}
              options={props.options.map(({ title, id, body }) => ({
                id,
                label: <RadioPanelContents title={title} body={body} />,
              }))}
              value={selection}
              onChange={setSelection}
              variant="panels"
              visuallyHideLabel
              required
            />
            <SectionFooter>
              <Button label={props.cancelCaption ?? "Cancel"} variant="ghost" onClick={onCancel} />
              <Button submit label={props.okCaption} />
            </SectionFooter>
          </form>
        </Dialog>
      ) : null}
      {children}
    </context.Provider>
  );
}

export default function useSelect() {
  return useContext(context) as <T extends string>(props: SelectProps<T>) => Promise<T | null>;
}
