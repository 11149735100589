import { format, getMonth } from "date-fns";
import React, { useCallback, useEffect, useMemo, useRef } from "react";

import { TriggerRef } from "../../../../../hooks/useTrigger";
import { DateRange, Day } from "../../types";
import { getMonths } from "../../utils";
import CalendarMonth from "./CalendarMonth";
import Weekdays from "./Weekdays";
import "./styles.scss";

const SCROLL_OFFSET = 48;

export default function DisplayCalendar({
  minDate,
  maxDate,
  highlight,
  onClickDay,
  onHoverChange,
  scrollTo,
}: {
  minDate: Date;
  maxDate: Date;
  highlight: DateRange | null;
  onClickDay: (date: Date) => void;
  onHoverChange?: (date: Date | null) => void;
  scrollTo?: TriggerRef<Date | null>;
}) {
  const now = useMemo(() => new Date(), []);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const months = useMemo(() => getMonths(minDate, maxDate), [maxDate, minDate]);

  const scrollToMonth = useCallback((dateOfMonth: Date) => {
    const monthId = format(dateOfMonth, "yyyy-M");
    const elementToScrollTo = document.getElementById(monthId);
    const topPos = elementToScrollTo?.offsetTop;
    if (elementToScrollTo && topPos && scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = topPos - SCROLL_OFFSET;
    }
  }, []);

  useEffect(() => {
    if (scrollTo) scrollToMonth(scrollTo.current || now);
  }, [scrollToMonth, scrollTo, now]);

  const handleDayMouseEnter = useCallback((day: Day) => onHoverChange?.(day.date), [onHoverChange]);
  const handleDayMouseLeave = useCallback(() => onHoverChange?.(null), [onHoverChange]);

  return (
    <div className="c-calendar" aria-hidden={true}>
      <div ref={scrollContainerRef} className="c-calendar__scroll-container">
        <div className="c-calendar__content-wrapper">
          <div className="c-calendar__header">
            <Weekdays />
          </div>
          {months
            ? months.map(({ month, year }) => (
                <CalendarMonth
                  key={`${year}-${month}`}
                  onClickDay={onClickDay}
                  year={year}
                  month={month}
                  startDate={highlight?.startDate ?? null}
                  endDate={highlight?.endDate ?? null}
                  minDate={minDate && getMonth(minDate) + 1 === month ? minDate : undefined}
                  maxDate={maxDate && getMonth(maxDate) + 1 === month ? maxDate : undefined}
                  handleDayMouseEnter={handleDayMouseEnter}
                  handleDayMouseLeave={handleDayMouseLeave}
                />
              ))
            : null}
        </div>
      </div>
    </div>
  );
}
