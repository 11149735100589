import { useQuery } from "@apollo/client";
import mixpanel from "mixpanel-browser";
import React, { FunctionComponent, useEffect } from "react";
import { useParams } from "react-router-dom";

import { DocumentType, GetDocumentQuery, GetDocumentQueryVariables } from "../../../../../__generated__/graphql";
import LoadingSpinner from "../../../../../shared/components/design-system/LoadingSpinner";
import { importTranslationsBackground } from "../../../../../shared/translation/initialisation";
import useEditSession from "../../../hooks/useEditSession";
import { useUrlParams } from "../../../hooks/useUrlParams";
import Container from "../../design-system/Container";
import GenericError from "../../design-system/GenericError";
import CombinedHeader from "../../design-system/PageHeader/CombinedHeader";
import useBreadcrumbs from "../useBreadcrumbs";
import { DocumentEditorWithPersistence } from "./persistence";
import { DOCUMENT_QUERY } from "./queries";
import english from "./translations/en-GB.json";

importTranslationsBackground("documentEditor", { "en-GB": english });

const InsightsPage: FunctionComponent = () => {
  const { documentId } = useParams<"organisationId" | "documentId">();
  const { getParam } = useUrlParams<"documentType" | "template">();
  const isLink = getParam("documentType") === "DATA_INSIGHT_REPORT_LINK";

  useEffect(() => {
    document.title = documentId ? "Culture Shift | Edit Document" : "Culture Shift | Create Document";
    mixpanel.track(
      isLink
        ? documentId
          ? "Visited Edit Link page"
          : "Visited Create Link page"
        : documentId
        ? "Visited Edit Document page"
        : "Visited Create Document page",
      { id: getParam("template") },
    );
  }, [documentId, getParam, isLink]);

  // This is a little inefficient, in that when you hit 'save' for the first time on a new document it'll redirect to include the document ID in the URL, which will trigger this fetch, which is a bit pointless since we already know what the document looks like and also will cause a pointless loading spinner. But it's not that bad and it's *much easier to build* this way so that, at least for now, is what we're doing.
  const { data, loading, error } = useQuery<GetDocumentQuery, GetDocumentQueryVariables>(DOCUMENT_QUERY, {
    variables: { documentId: documentId! },
    skip: !documentId,
  });

  useEditSession("document", documentId);
  const breadcrumbs = useBreadcrumbs({ siteTab: "insights" });
  const title = data?.document.name ?? "Insights report";
  if (isLink && !documentId) {
    return <DocumentEditorWithPersistence externalLink documentType={DocumentType.DataInsightReport} />;
  }

  if (isLink && data) {
    return (
      <DocumentEditorWithPersistence
        externalLink
        documentType={DocumentType.DataInsightReport}
        savedDocument={data.document}
      />
    );
  }
  if (loading) {
    return (
      <Container>
        <CombinedHeader className="ds-py-6" title="Insights report" visuallyHideTitle breadcrumbs={breadcrumbs} />
        <section>
          <LoadingSpinner />
        </section>
      </Container>
    );
  }

  if (!documentId) {
    return (
      <DocumentEditorWithPersistence documentType={DocumentType.DataInsightReport} template={getParam("template")} />
    );
  }

  if (error || !data) {
    return (
      <Container>
        <CombinedHeader
          className="ds-py-6"
          title={title}
          visuallyHideTitle
          breadcrumbs={breadcrumbs}
          loading={loading}
        />
        <GenericError error={error} />
      </Container>
    );
  }

  return <DocumentEditorWithPersistence savedDocument={data.document} documentType={DocumentType.DataInsightReport} />;
};

export default InsightsPage;
