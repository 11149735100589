import mixpanel from "mixpanel-browser";
import React, { useCallback, useState } from "react";
import AnimateHeight from "react-animate-height";

import Icon from "../../../../../shared/components/design-system/Icon";
import { FunctionComponent } from "../../util";
import "./styles.scss";

const CollapsiblePanel: FunctionComponent<{
  id: string;
  title: string;
  isOpen: boolean;
  onChange: (open: boolean) => void;
}> = ({ id, title, isOpen, onChange, children }) => {
  const [closeAnimationInProgress, setCloseAnimationInProgress] = useState<boolean>(false);

  const handleAnimationStart = useCallback(() => {
    setCloseAnimationInProgress(true);
  }, []);

  const handleAnimationEnd = useCallback(() => {
    setCloseAnimationInProgress(false);
  }, []);

  const handleOpen = useCallback(() => {
    mixpanel.track("Opened panel", { title });
    onChange(true);
  }, [onChange, title]);

  const handleClose = useCallback(() => {
    mixpanel.track("Closed panel", { title });
    setCloseAnimationInProgress(true);
    onChange(false);
  }, [onChange, title]);

  return (
    <>
      <div className="c-collapsible-panel" onClick={isOpen ? handleClose : handleOpen}>
        <h3 id={`${id}-title`} className="c-collapsible-panel__title">
          <span className="is-sr-only">
            {isOpen ? "Close panel:" : "Open panel:"} {title}
          </span>{" "}
          {title}
        </h3>

        <Icon
          icon="chevronDown"
          className={`c-collapsible-panel__icon c-collapsible-panel__icon--${isOpen ? "is-open" : "is-closed"}`}
        />
      </div>
      <AnimateHeight
        id={id}
        className="c-collapsible-panel__content"
        duration={200}
        height={isOpen ? "auto" : 0}
        onHeightAnimationStart={handleAnimationStart}
        onHeightAnimationEnd={handleAnimationEnd}
        aria-labelledby={`${id}-title`}
        role="region"
        hidden={!isOpen && !closeAnimationInProgress}
      >
        {children}
      </AnimateHeight>
    </>
  );
};

export default CollapsiblePanel;
