import { sanitize } from "isomorphic-dompurify";

import { UserInputError } from "../errors";
import { ListItem, RichTextDocument, RootNode, TextBlock, TopLevelNode } from "./types";

const ALLOWED_CHILDREN = {
  root: new Set([
    "heading",
    "paragraph",
    "list",
    "table",
    "html-snippet",
    "image",
    "cs-analytics-chart--in-detail",
    "cs-analytics-chart--over-time",
  ]),
  paragraph: new Set(["text", "link", "linebreak"]),
  listitem: new Set(["text", "link", "linebreak", "list"]),
  heading: new Set(["text"]),
  link: new Set(["text", "linebreak"]),
  list: new Set(["listitem"]),
  text: null,
  linebreak: null,
  "cs-analytics-chart--in-detail": null,
  "cs-analytics-chart--over-time": null,
  table: null,
  image: null,
  "html-snippet": null,
};

const TYPEABLE_NODES = new Set(["heading", "paragraph", "list"]);

const ALLOWED_IN_NESTED = new Set(["paragraph"]);

// Note: the tests for this are in the NormalisationPlugin folder in the UI because they share a lot of code with the tests for that plugin.

export default function validateRichTextDocument(doc: string, isNested = false) {
  let document: RichTextDocument | null = null;
  const errors: DocumentError[] = [];

  try {
    document = JSON.parse(doc);
    if (document!.root.type !== "root") {
      errors.push({
        error: `Unexpected ${document!.root.type} as root`,
        level: "error",
      });
    }

    if (isNested) {
      for (const child of document!.root.children) {
        if (!ALLOWED_IN_NESTED.has(child.type)) {
          errors.push({
            error: `Unexpected ${child.type} in nested editor`,
            level: "error",
          });
        }
      }
    }

    validateNode(document!.root, errors);
  } catch (exception) {
    return {
      errors: [{ error: `Invalid document format`, level: "error" } as DocumentError],
      document,
      exception: exception as Error,
    };
  }

  return { errors, document };
}

interface DocumentError {
  error: string;
  level: "error" | "warning";
  exception?: Error;
}

function validateNode(node: RootNode | TextBlock | ListItem | TopLevelNode, errors: DocumentError[]) {
  const allowedChildren = ALLOWED_CHILDREN[node.type];
  if ("children" in node && node.children.length) {
    if (!allowedChildren) {
      errors.push({
        error: `Unexpected children in ${node.type}`,
        level: "error",
      });
    } else {
      for (const child of node.children) {
        if (!allowedChildren.has(child.type)) {
          errors.push({
            error: `Unexpected ${child.type} in ${node.type}`,
            level: "error",
          });
        }
        validateNode(child, errors);
      }
    }
  }

  if ("caption" in node) {
    if (node.caption.type !== "caption") {
      errors.push({
        error: `Unexpected ${node.caption.type} as caption`,
        level: "warning",
      });
    }
    const { errors: subErrors } = validateRichTextDocument(node.caption.json, true);
    errors.push(...subErrors);
  }

  switch (node.type) {
    case "root":
      if (node.children.length === 0) {
        errors.push({
          error: `Empty document`,
          level: "error",
        });
      } else {
        if (!TYPEABLE_NODES.has(node.children[0].type)) {
          errors.push({
            error: `First node is not typable`,
            level: "error",
          });
        }
        if (!TYPEABLE_NODES.has(node.children[node.children.length - 1].type)) {
          errors.push({
            error: `Last node is not typable`,
            level: "error",
          });
        }
        for (let i = 1; i < node.children.length; ++i) {
          if (!TYPEABLE_NODES.has(node.children[i - 1].type) && !TYPEABLE_NODES.has(node.children[i].type)) {
            errors.push({
              error: `Two consecutive untypable`,
              level: "error",
            });
          }
        }
      }
      break;
    case "table":
      {
        if (node.rows.length < 1) {
          errors.push({
            error: `Unexpected empty table`,
            level: "warning",
          });
        }
        const columnCount = node.rows[0].cells.length;
        for (const row of node.rows) {
          if (row.cells.length !== columnCount) {
            errors.push({
              error: `Unexpected non-rectangular table`,
              level: "warning",
            });
          }
          for (const cell of row.cells) {
            if (cell.type !== "cell") {
              errors.push({
                error: `Unexpected ${cell.type} as table cell`,
                level: "warning",
              });
            }
            const { errors: subErrors } = validateRichTextDocument(cell.json, true);
            errors.push(...subErrors);
          }
        }
      }
      break;
    case "heading":
      if (node.tag !== "h2" && node.tag !== "h3") {
        errors.push({
          error: `Unexpected heading tag ${node.tag}`,
          level: "error",
        });
      }
      if (node.children.some((node) => "format" in node && node.format)) {
        errors.push({
          error: `Unexpected formatting in heading`,
          level: "error",
        });
      }
      break;
    case "listitem":
      if (node.children.length > 1 && node.children.some((child) => child.type === "list")) {
        errors.push({
          error: `Unexpected nested list in list item with text`,
          level: "error",
        });
      }
      break;
    case "html-snippet":
      if (sanitise(node.src) !== node.src) {
        errors.push({
          error: `HTML snippet contains insecure code`,
          level: "error",
        });
      }
      break;
  }
}

const config = { ADD_TAGS: ["iframe"] };
/** Sanitizes the source code using dompurify, modified to allow iframes since that's what 90% of people will want. Also makes one other, more cursed modification. */
export function sanitise(src: string) {
  // So it turns out that isomorphic-dompurify's sanitize command reverses the order of HTML attributes. This is not really a problem, per se, but it not being idempotent does mean that we can't use "sanitize(x) == x" to detect whether the code is valid. Luckily, reversing the order of the HTML attributes again fixes it, and we have a """convenient""" way to do that. So sanitize(sanitize(src)) it is.
  return sanitize(sanitize(src, config), config);
}

export function validateRichTextDocumentOrThrow(doc: string) {
  const { errors, document, exception } = validateRichTextDocument(doc);
  if (exception) throw new UserInputError(exception.message);
  if (errors.length) {
    const exception = errors.find((error) => error.exception)?.exception;
    if (exception) throw exception;
    throw new UserInputError(errors.map((error) => error.error).join(""));
  }
  return document!;
}

export function validateRichTextDocumentString(doc: string) {
  validateRichTextDocumentOrThrow(doc);
  return doc;
}
