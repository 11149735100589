// @ts-ignore - on the server we force this to become @sentry/node which confuses TypeScript
// TODO: This should not be imported from the API, we need to sort out a real solution for shared components
import * as Sentry from "@sentry/browser";
import React from "react";

import Alert from "../../../../../shared/components/design-system/Alert";

const errorIds = new WeakMap<Error, string>();

/** This logs an error to Sentry, with a unique (hopefully) ID which is then returned for display. The ID is not a UUID because that would be really annoying to type out, it's a shorter hex value which is hopefully unique enough to find the right error and not many others. This function is idempotent, so safe to call during the render stage of a React component. */
export function logErrorWithId(error: Error) {
  if (errorIds.has(error)) return errorIds.get(error)!;
  const errorId = Math.random().toString(16).substring(2, 12);
  console.error(error);
  console.log({ errorId });
  Sentry.setTag("errorId", errorId);
  Sentry.captureException(error);
  try {
    errorIds.set(error, errorId);
  } catch (e) {
    // It's possible to throw a string or whatever, and if you do that it'll likely end up here. A string isn't a valid key for a weakmap (because it can't go out of scope) so it'll error. We really dont want our error logger throwing erros, so if that happens, just warn about it and move on.
    console.warn(e);
  }
  return errorId;
}

export default function GenericError({ message, error }: { message?: string; error?: Error | null }) {
  if (!error) {
    return (
      <Alert variant="danger" className="ds-mb-7" message={message ?? "An error occurred whilst loading this data."} />
    );
  }

  return (
    <Alert
      variant="danger"
      className="ds-mb-7"
      title={message ?? "An error occurred whilst loading this data."}
      message={`Please quote this error message if contacting support about this error: ${logErrorWithId(error)}`}
    />
  );
}
