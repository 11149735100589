import classNames from "classnames";
import React, { FunctionComponent, PropsWithChildren, useEffect, useState } from "react";

import "./styles.scss";

export type NewTagProps = PropsWithChildren<{
  visible: boolean;
  position?: "top" | "right" | "bottom" | "left";
  className?: string;
}>;

const NewTag: FunctionComponent<NewTagProps> = ({ visible, position = "top", className, children }) => {
  const [madeVisible, setMadeVisible] = useState<boolean>(false);

  useEffect(() => {
    if (visible) setTimeout(() => setMadeVisible(true), 2000);
  }, [visible]);

  const tagClassName = classNames({
    "c-new-tag": true,
    "c-new-tag--visible": madeVisible && visible,
    [`c-new-tag--${position}`]: position,
  });

  return (
    <div className={`c-new-tag-wrapper ${className ?? ""}`}>
      <div className={tagClassName}>New!</div>
      {children}
    </div>
  );
};

export default NewTag;
