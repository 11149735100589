import mixpanel, { Dict } from "mixpanel-browser";
import { useCallback } from "react";

import useDeepEquality from "../../../shared/hooks/useDeepEquality";

// Wraps a callback in a mixpanel tracking event.
export default function useTrackedCallback<ArgsType extends Array<unknown>, ReturnType>(
  callback: (...args: ArgsType) => ReturnType,
  message: string,
  data?: Dict,
) {
  const safeData = useDeepEquality(data);
  return useCallback(
    (...args: ArgsType) => {
      mixpanel.track(message, safeData);
      return callback(...args);
    },
    [callback, safeData, message],
  );
}
