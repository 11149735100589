import { endOfDay } from "date-fns";
import formatISO from "date-fns/formatISO";
import parseISO from "date-fns/parseISO";
import mixpanel from "mixpanel-browser";
import React, { useCallback, useMemo } from "react";

import { FilterSettings } from "../../../hooks/filtering/useFilterFromUrlParams";
import { COLUMNS } from "../../../hooks/useReportFields";
import DateRangeWidget from "../DatePicker/DateRangeWidget";
import { DateRange as DatePickerDateRange } from "../DatePicker/types";

export default function DateRangeFilter({
  filterKey,
  filterSettings: { filter, setFilter },
}: {
  filterKey: "createdAt" | "updatedAt";
  filterSettings: FilterSettings;
}) {
  const value = filter[filterKey];

  const handleDatePickerChange = useCallback(
    (dateRange: DatePickerDateRange | null) => {
      mixpanel.track("Changed the reports filter", { name: COLUMNS.find(({ id }) => id === filterKey)!.name });
      if (!dateRange?.startDate && !dateRange?.endDate) {
        setFilter({ ...filter, [filterKey]: undefined });
      } else {
        setFilter({
          ...filter,
          [filterKey]: {
            start: dateRange.startDate ? formatISO(dateRange.startDate) : undefined,
            end: dateRange.endDate ? formatISO(dateRange.endDate) : undefined,
          },
        });
      }
    },
    [filter, filterKey, setFilter],
  );

  const dateRange: DatePickerDateRange | null = useMemo(
    () =>
      value
        ? {
            startDate: value?.start ? parseISO(value?.start) : undefined,
            endDate: value?.end ? parseISO(value?.end) : undefined,
          }
        : null,
    [value],
  );

  const minDate = useMemo(() => new Date(2017, 0, 1), []);
  const maxDate = useMemo(() => endOfDay(new Date()), []);

  return (
    <DateRangeWidget
      value={dateRange}
      onChange={handleDatePickerChange}
      allowOpenEnded
      minDate={minDate}
      maxDate={maxDate}
    />
  );
}
