import React, { ReactNode } from "react";

import Alert from "../../../../../shared/components/design-system/Alert";
import Card from "../../design-system/Card";
import Logo from "../../design-system/Logo";
import confettiLeft from "./confettiLeft.svg";
import confettiRight from "./confettiRight.svg";
import "./styles.scss";

export function SignInMain({ children }: { children: ReactNode }) {
  return (
    <main className="l-login">
      <Logo className="l-login__logo" variant="light" />
      {children}
    </main>
  );
}

export function SignInCard({ children, toast }: { children: ReactNode; toast: string | null }) {
  return (
    <Card className="l-login__card">
      <img className="l-login__confetti-left" src={confettiLeft} aria-hidden="true" alt="" />
      <img className="l-login__confetti-right" src={confettiRight} aria-hidden="true" alt="" />
      {toast ? <Alert className="ds-mb-5" variant="info" message={toast} /> : null}
      {children}
    </Card>
  );
}
