import gql from "graphql-tag";

import { questionFields } from "../../graphql/fragments";

export const QUESTIONS_QUERY = gql`
  query getOrgsQuestions {
    questions {
      ...questionFields
    }
  }
  ${questionFields}
`;

export const TEAMS_QUERY = gql`
  query getOrgTeams {
    teams: assignableTeams {
      id
      name
      members {
        id
        name
      }
    }
  }
`;

export const OUTCOMES_QUERY = gql`
  query getOrgOutcomes {
    outcomes {
      id
      name
      guidance
      suppressForAnalytics
    }
  }
`;

export const SITES_QUERY = gql`
  query getOrgSites {
    sites {
      id
      name
      urls {
        id
        status
        host
        isCanonical
      }
      language
      hideReportPage
      forms {
        id
        name
        title
        category
      }
    }
  }
`;

export const CROSS_ORG_QUERY = gql`
  query getOrgData($organisationId: ID!) {
    organisation(id: $organisationId) {
      id
      name
      questions {
        ...questionFields
      }
      teams {
        id
        name
        members {
          id
          name
        }
      }
      outcomes {
        id
        name
        guidance
        suppressForAnalytics
      }
      sites {
        id
        name
        urls {
          id
          status
          host
          isCanonical
        }
        language
        hideReportPage
        forms {
          id
          name
          title
          category
        }
      }
      featureGates
    }
  }
  ${questionFields}
`;
