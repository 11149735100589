import * as Sentry from "@sentry/browser";
import React, { Component, ErrorInfo, PropsWithChildren } from "react";

import { logErrorWithId } from "../design-system/GenericError";
import ErrorPage from "./ErrorPage";

type ErrorReportingProps = PropsWithChildren<unknown>;

export default class ErrorReporting extends Component<ErrorReportingProps, { errorId: string | null }> {
  constructor(props: ErrorReportingProps) {
    super(props);
    this.state = { errorId: null };
  }

  // This isn't available yet with hooks, so a class component it is.
  componentDidCatch(error: Error, { componentStack }: ErrorInfo) {
    const errorId = logErrorWithId(error);
    this.setState({ errorId });
    Sentry.captureException(error, { contexts: { react: { componentStack } } });
  }

  render() {
    if (this.state.errorId) {
      return <ErrorPage errorId={this.state.errorId} />;
    }

    return this.props.children;
  }
}
