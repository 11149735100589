import React, { useCallback, useState } from "react";

import Dialog from "../../../../../shared/components/design-system/Dialog";
import StringInput from "../../../../../shared/components/design-system/TextInput/StringInput";
import {
  SectionFooter,
  SectionHeader,
} from "../../../../../shared/components/design-system/component-groups/section-header-footer";
import { URL_PARTS } from "../../../../../shared/db/documents";
import { useAsyncCallbackWithLoading } from "../../../hooks/useAsyncFunctionWithLoading";
import { useOpenableWithTracking } from "../../../hooks/useBoolean";
import Button from "../../design-system/Button";
import useSiteData from "../useSiteData";
import { usePersistence } from "./persistence";

export default function PreviewButton() {
  const site = useSiteData();

  const { savedDocument: doc, save, loading, name, setName, isDirty } = usePersistence();

  const { isOpen, open, close } = useOpenableWithTracking("Preview document modal", { type: "Insights report" });

  const [tempName, setTempName] = useState("");

  const [saveAndPreview, saving] = useAsyncCallbackWithLoading(async () => {
    const doc = await save({ makePreviewable: true });
    if (!doc) return;
    // Unpublished docs do not have slugs, so we reference them by ID:
    const path = doc.published?.path ?? `/${URL_PARTS[doc.documentType]}/${doc.id}`;
    window.open(`https://${site.urls[0].host}${path}/preview/${doc.currentDraft!.id}`, "_blank");
  }, [save, site.urls]);

  const onConfirm = useCallback(async () => {
    close();
    setName(name);
    await saveAndPreview();
  }, [close, name, saveAndPreview, setName]);

  const onClick = useCallback(async () => {
    if (isDirty) {
      setTempName(name);
      open();
    } else {
      await saveAndPreview();
    }
  }, [isDirty, name, open, saveAndPreview, setTempName]);

  if (!doc) return null;
  if (doc.published?.publishedVersion?.id === doc.currentDraft?.id && !isDirty) return null;

  return (
    <>
      <Button variant="action" onClick={onClick} loading={saving} disabled={loading} icon="arrowUpRightFromSquare">
        Preview
      </Button>
      <Dialog isOpen={isOpen} size="small">
        <SectionHeader title={document ? "Unsaved changes" : "Title required"} />
        <form onSubmit={onConfirm}>
          {name ? (
            <p>Please save your changes before previewing.</p>
          ) : (
            <>
              <p className="ds-mb-5">To save and preview this document, please add a title.</p>
              <StringInput value={tempName} onChange={setTempName} label="Title" required />
            </>
          )}
          <SectionFooter>
            <Button variant="ghost" onClick={close}>
              Cancel
            </Button>
            <Button type="submit">Save and Preview</Button>
          </SectionFooter>
        </form>
      </Dialog>
    </>
  );
}
