import React, { createContext, PropsWithChildren, useContext, useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { Breadcrumb } from "../../design-system/PageHeader/CombinedHeader";

const DEFAULT_VALUE: FilterContext = { search: "", tab: "all" };

const context = createContext<FilterContext>(DEFAULT_VALUE);

interface FilterContext {
  search: string;
  tab: string;
}

/** This is a component that wraps the whole /report/:id route,
and remembers what filter you had on the reports page (if any)
so that you can render the right breadcrumbs */
export default function ReportFilterProvider({ children }: PropsWithChildren<unknown>) {
  const { state } = useLocation();

  // If we navigate around within the report page, we don't want to worry about forwarding the state every time, so just remember it until we leave the "report view" area. This should work when switching to risk assessments or linked reports.
  const value = useMemo<FilterContext>(() => {
    if (!state || !(typeof state === "object")) return DEFAULT_VALUE;
    const { search, tab } = state as Record<string, unknown>;
    if (!search && !tab) return DEFAULT_VALUE;
    return {
      search: search?.toString() ?? "",
      tab: tab?.toString() ?? "tab",
    };
    // We only want this to run once, when the component is first mounted, so an empty array is correct. Try telling that to eslint, though.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <context.Provider value={value}>{children ?? <Outlet />}</context.Provider>;
}

export function useReportListBreadcrumb(): Breadcrumb {
  const { tab, search } = useContext(context);
  return { label: "Report list", url: `/reports/${tab}${search}` };
}
