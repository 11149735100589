import {
  $createNodeSelection,
  $getSelection,
  $isRangeSelection,
  $isRootNode,
  $setSelection,
  DecoratorNode,
} from "lexical";

/** Inserts a block node into a Lexical editor in such a way as to not break anything */
export default function $insertBlockNode(newNode: DecoratorNode<unknown>) {
  // We need a range selection and a focused node to insert a block — we should always have one, so just fail gracefully if we don't.
  const selection = $getSelection();
  if (!$isRangeSelection(selection)) return;
  const focus = selection.focus;
  const focusNode = focus.getNode();
  if (focusNode === null) return;

  // Insert the new node — there are a few cases depending on where the cursor currently is, but this should cover them all:
  if ($isRootNode(focusNode)) {
    const target = focusNode.getChildAtIndex(focus.offset);

    if (target !== null) {
      target.insertBefore(newNode);
    } else {
      focusNode.append(newNode);
    }
  } else {
    const topLevelNode = focusNode.getTopLevelElementOrThrow();
    topLevelNode.insertAfter(newNode);
  }

  // Select the new node
  const nodeSelection = $createNodeSelection();
  nodeSelection.add(newNode.getKey());
  $setSelection(nodeSelection);
}
