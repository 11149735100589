import { useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useEffect, useMemo } from "react";
import { v4 as uuid } from "uuid";

import {
  ArticleEditSessionsQuery,
  ArticleEditSessionsQueryVariables,
  CampaignEditSessionsQuery,
  CampaignEditSessionsQueryVariables,
  CategoryEditSessionsQuery,
  CategoryEditSessionsQueryVariables,
  DocumentEditSessionsQuery,
  DocumentEditSessionsQueryVariables,
  EndEditSessionMutation,
  EndEditSessionMutationVariables,
  FormEditSessionsQuery,
  FormEditSessionsQueryVariables,
  MarkEditSessionMutation,
  MarkEditSessionMutationVariables,
  PageEditSessionsQuery,
  PageEditSessionsQueryVariables,
  QuestionEditSessionsQuery,
  QuestionEditSessionsQueryVariables,
  SiteEditSessionsQuery,
  SiteEditSessionsQueryVariables,
} from "../../../__generated__/graphql";
import { useToast } from "../../../shared/components/design-system/Toaster/context";
import useSelf from "./useSelf";

const editSessionQueries = {
  article: gql`
    query articleEditSessions($id: ID!) {
      editObject: article(id: $id) {
        id
        editSessions {
          id
          user {
            id
            name
          }
        }
      }
    }
  `,
  campaign: gql`
    query campaignEditSessions($id: ID!) {
      editObject: campaign(id: $id) {
        id
        editSessions {
          id
          user {
            id
            name
          }
        }
      }
    }
  `,
  category: gql`
    query categoryEditSessions($id: ID!) {
      editObject: category(id: $id) {
        id
        editSessions {
          id
          user {
            id
            name
          }
        }
      }
    }
  `,
  document: gql`
    query documentEditSessions($id: ID!) {
      editObject: document(id: $id) {
        id
        editSessions {
          id
          user {
            id
            name
          }
        }
      }
    }
  `,
  form: gql`
    query formEditSessions($id: ID!) {
      editObject: form(id: $id) {
        id
        editSessions {
          id
          user {
            id
            name
          }
        }
      }
    }
  `,
  page: gql`
    query pageEditSessions($id: ID!) {
      editObject: page(id: $id) {
        id
        editSessions {
          id
          user {
            id
            name
          }
        }
      }
    }
  `,
  question: gql`
    query questionEditSessions($id: ID!) {
      editObject: question(id: $id) {
        id
        editSessions {
          id
          user {
            id
            name
          }
        }
      }
    }
  `,
  site: gql`
    query siteEditSessions($id: ID!) {
      editObject: site(id: $id) {
        id
        editSessions {
          id
          user {
            id
            name
          }
        }
      }
    }
  `,
};

type editSessionQuery =
  | ArticleEditSessionsQuery
  | CampaignEditSessionsQuery
  | CategoryEditSessionsQuery
  | DocumentEditSessionsQuery
  | FormEditSessionsQuery
  | PageEditSessionsQuery
  | QuestionEditSessionsQuery
  | SiteEditSessionsQuery;
type editSessionQueryVariables =
  | ArticleEditSessionsQueryVariables
  | CampaignEditSessionsQueryVariables
  | CategoryEditSessionsQueryVariables
  | DocumentEditSessionsQueryVariables
  | FormEditSessionsQueryVariables
  | PageEditSessionsQueryVariables
  | QuestionEditSessionsQueryVariables
  | SiteEditSessionsQueryVariables;

// If objectId is not passed, the hook will not run. Use this when, say, rendering a placeholder which doesn't have a real ID.
export default function useEditSession(objectType: keyof typeof editSessionQueries, objectId?: string) {
  const self = useSelf();
  const toast = useToast();
  const sessionId = useMemo(() => uuid(), []);

  const [markEditSession] = useMutation<MarkEditSessionMutation, MarkEditSessionMutationVariables>(
    gql`
      mutation markEditSession($sessionId: ID!, $objectId: ID!) {
        markEditing(sessionId: $sessionId, objectId: $objectId)
      }
    `,
    { variables: { sessionId, objectId: objectId! } },
  );

  const [endEditSession] = useMutation<EndEditSessionMutation, EndEditSessionMutationVariables>(
    gql`
      mutation endEditSession($sessionId: ID!) {
        endEditSession(id: $sessionId)
      }
    `,
    { variables: { sessionId } },
  );

  useEffect(() => {
    if (!objectId) return;
    void markEditSession();
    return () => void endEditSession();
  }, [endEditSession, markEditSession, objectId]);

  useEffect(() => {
    if (!objectId) return;
    const intervalId = setInterval(() => markEditSession(), 50000);
    return () => clearInterval(intervalId);
  }, [markEditSession, sessionId, objectId]);

  const { data } = useQuery<editSessionQuery, editSessionQueryVariables>(editSessionQueries[objectType], {
    variables: { id: objectId! },
    skip: !objectId,
    pollInterval: 25000,
  });

  const otherSessions = useMemo(
    () => (!data ? [] : data.editObject.editSessions).filter(({ id }) => id !== sessionId),
    [data, sessionId],
  );

  const otherUsers = useMemo(
    () =>
      [...new Set(otherSessions.filter(({ user }) => user.id !== self.id).map(({ user: { name } }) => name))].sort(),
    [otherSessions, self.id],
  );
  useEffect(() => {
    if (otherUsers.length > 0) {
      const toastId = toast.warning(
        otherUsers.length === 1
          ? `${otherUsers[0]} is also editing this ${objectType}`
          : `The following users are also editing this ${objectType}: ${otherUsers.join(", ")}`,
        { persistent: true },
      );
      return () => {
        toast.remove(toastId);
      };
    }
  }, [otherUsers, objectType, toast]);

  const amIEditing = otherSessions.some(({ user }) => user.id === self.id);
  useEffect(() => {
    if (amIEditing) {
      const toastId = toast.warning(
        `You have this ${objectType} open in another tab. Take care not to overwrite your work`,
        { persistent: true },
      );
      return () => toast.remove(toastId);
    }
  }, [amIEditing, objectType, toast]);
}
