import { useCallback } from "react";

import { PublicationMode } from "../../../../../__generated__/graphql";
import React from "../../../../../shared/block-editor-data/react";
import { useConfirm } from "../../../hooks/useAlert";
import useAsyncFunctionWithLoading from "../../../hooks/useAsyncFunctionWithLoading";
import Button from "../../design-system/Button";
import { usePersistence } from "./persistence";

export default function PublishButton() {
  const confirm = useConfirm();

  const { savedDocument: doc, isDirty, publish, loading, checkDocumentHasName } = usePersistence();
  const [wrappedPublish, publishing] = useAsyncFunctionWithLoading(publish);

  const onClick = useCallback(async () => {
    if (!checkDocumentHasName()) return;

    let okCaption = "Publish to site";
    const alerts: string[] = [];

    if (doc?.published?.publicationMode === PublicationMode.PrivateLink) {
      alerts.push("Publishing this document to your reporting site will remove the existing private link.");
    }

    if (isDirty) {
      alerts.unshift("You must save your document in order to publish it.");
      okCaption = "Save and publish";
    }

    if (
      alerts.length &&
      !(await confirm({
        title: "Publish to reporting site",
        okCaption,
        cancelCaption: "Cancel",
        okVariant: "primary",
        children: alerts.join(" "),
      }))
    ) {
      return;
    }

    await wrappedPublish(PublicationMode.Public);
  }, [checkDocumentHasName, confirm, doc?.published?.publicationMode, isDirty, wrappedPublish]);

  if (doc?.published?.publicationMode === PublicationMode.Public) return null;

  return (
    <Button variant="secondary" onClick={onClick} disabled={loading} loading={publishing}>
      Publish
    </Button>
  );
}
