// This file is deliberately doing weird things with useMemo and useEffect so it can't really follow the exhaustive-deps rule. Instead, calls to this function must themselves follow it.

/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from "react";

interface DataResult<T> {
  data: T;
  loading: false;
  error: null;
}
interface LoadingResult {
  data: null;
  loading: true;
  error: null;
}
interface ErrorResult {
  data: null;
  loading: false;
  error: Error;
}
type Result<T> = DataResult<T> | LoadingResult | ErrorResult;

type InternalResult<T> =
  | { query: unknown[]; data: T; error: null }
  | { query: unknown[]; data: null; error: Error | null };

/** Similar to useMemo, but accepts an asyncronous callback. Returns an object with "data", "loading" and "error" props similar to useApolloQuery. Only one of "data", "loading" and "error" are ever set, there's no stale data shenanigans here. */
export default function useAsyncMemo<T>(
  callback: (...args: Array<unknown>) => Promise<T>,
  deps: Array<unknown>,
): Result<T> {
  // This just makes "deps" into a single thing that stays reference equal until something in the array changes,
  // rather than the default behaviour, which is to make a new array on every render with all the same things in.
  // This means as well as passing it in as the depencency array, we can do normal things with it,
  // like use it in other dependency arrays, or compare it to see when things came in.
  const query = useMemo(() => deps, deps);

  const [result, setResult] = useState<InternalResult<T>>({ query: [], data: null, error: null });

  // If a result comes back for the current query, this will match and store it.
  // If it doesn't match, that means we got a delayed result for some older query,
  // so we throw it away and keep the previous value.
  const processResult = useCallback((result: InternalResult<T>) => {
    setResult((previousResult) => {
      if (result.query !== previousResult.query) return previousResult;
      return result;
    });
  }, []);

  // Whenever the query changes, we set the current result to null, to encode that we're waiting for a result,
  // and then when the callback returns, we attempt to store it (which will fail if the query is out of date).
  useEffect(
    (...args) => {
      setResult({ query, data: null, error: null });
      callback(...args)
        .then((data) => processResult({ query, data, error: null }))
        .catch((error: Error) => processResult({ query, data: null, error }));
    },
    [query],
  );

  // Lastly, for one "frame" after we update the dependencies, the query value will have changed
  // but the value in state won't have updated yet (because that's asynchronous in React),
  // so just ignore it and return "loading", since that's what we'll get next frame anyway.
  if (result.query !== query) return { data: null, loading: true, error: null };

  // Otherwise, just return the current state to the calling component.
  return { data: result.data, error: result.error, loading: !result.data && !result.error } as Result<T>;
}
