import React from "react";

import RangeCalendar from "../internals/Calendar/RangeCalendar";
import DateRangeInput from "../internals/DateRangeInput";
import { DateRange } from "../types";

export default function DateRangeWidget({
  value,
  onChange,
  required,
  minDate,
  maxDate,
  allowOpenEnded,
}: {
  value: DateRange | null;
  onChange: (value: DateRange | null) => void;
  required?: boolean;
  minDate: Date;
  maxDate: Date;
  allowOpenEnded?: boolean;
}) {
  return (
    <>
      <DateRangeInput
        value={value}
        onChange={onChange}
        required={required}
        minDate={minDate}
        maxDate={maxDate}
        allowOpenEnded={allowOpenEnded}
      />
      <RangeCalendar value={value ?? undefined} onChange={onChange} minDate={minDate} maxDate={maxDate} />
    </>
  );
}
