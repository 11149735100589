import React, { FunctionComponent, PropsWithChildren } from "react";

import "./styles.scss";

type EmptyPanelProps = PropsWithChildren<{
  heading?: string;
  image?: string;
  fitContent?: boolean;
}>;

const EmptyPanel: FunctionComponent<EmptyPanelProps> = ({ heading, image, fitContent, children }) => (
  <div className="c-empty-panel">
    {heading ? <h2 className="c-empty-panel__heading">{heading}</h2> : null}
    {image ? <img src={image} alt="" className="c-empty-panel__image" /> : null}
    <div
      className={`c-empty-panel__message ${heading ? "c-empty-panel__message--has-heading" : ""} ${
        fitContent ? "c-empty-panel__message--full-width" : ""
      }`}
    >
      {children}
    </div>
  </div>
);

export default EmptyPanel;
