import { ReportStatus, UpdateReclassifiedAnswerStatus } from "../../__generated__/graphql";

// This file contains text that isn't currently localised, but it's in the 'translation' folder because in theory it ought to be

export const STATUS_NAMES = {
  [ReportStatus.AwaitingAssignment]: "Unassigned",
  [ReportStatus.Assigned]: "Assigned",
  [ReportStatus.Accepted]: "Accepted",
  [ReportStatus.Closed]: "Closed",
  [ReportStatus.Archived]: "Archived",
};

// TODO: This will need localising
export function reclassifiedAnswerStatusText(
  newStatus: UpdateReclassifiedAnswerStatus,
  optionText: string,
  question: string,
) {
  switch (newStatus) {
    case UpdateReclassifiedAnswerStatus.AsReported:
      return `Restored "${optionText}" to ${question}.`;
    case UpdateReclassifiedAnswerStatus.Removed:
      return `Removed reported "${optionText}" from ${question}.`;
    case UpdateReclassifiedAnswerStatus.Deleted:
      return `Removed added "${optionText}" from ${question}.`;
    case UpdateReclassifiedAnswerStatus.Added:
      return `Added "${optionText}" to ${question}.`;
  }
}
