import styles from "./styles.module.scss";

// https://lexical.dev/docs/getting-started/theming

const theme = {
  text: {
    bold: styles.Bold,
    italic: styles.Italic,
    underline: styles.Underline,
  },
  paragraph: styles.Paragraph,
  heading: {
    h1: styles.Heading2,
    h2: styles.Heading2,
    h3: styles.Heading3,
    h4: styles.Heading3,
    h5: styles.Heading3,
    h6: styles.Heading3,
  },
  list: {
    ul: styles.UnorderedList,
    ol: styles.OrderedList,
    nested: {
      listitem: styles.ListWithSublist,
    },
  },
  table: styles.Table,
  tableCellHeader: styles.TableCellHeader,
  tableCell: styles.TableCell,
  tableSelected: styles.TableSelected,
  tableCellActionButtonContainer: styles.TableCellActionButtonContainer,
  caption: styles.Caption,
};

export default theme;
