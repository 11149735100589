import React, { FunctionComponent } from "react";

import LoadingOverlay from "../design-system/LoadingOverlay";

const Loading: FunctionComponent<{ authState?: string }> = ({ authState }) =>
  (authState ?? "loading") === "loading" ? (
    <main className="auth-layout">
      <LoadingOverlay visible={true} />
    </main>
  ) : null;

export default Loading;
