import React, { PropsWithChildren, useEffect, useMemo } from "react";
import { Outlet, useParams } from "react-router-dom";

import { FormCategory, Permission } from "../../../__generated__/graphql";
import Select from "../components/design-system/Select";
import useOrg from "./useOrg";
import { useUrlParams } from "./useUrlParams";

/** This is kind of the old-fashioned way of doing this now, but in effect what it does is set the global org switcher as soon as you enter an org in the partners tab, and then un-set it again when you leave */
export function OrgDataRoute({ children }: PropsWithChildren<unknown>) {
  const { setCurrentOrg } = useOrg();

  // Take the organisation ID from the URL if present, if not then take the query parameter, and if that's not present then use "null" which acts to take the user's organisation.
  const { organisationId: param } = useParams<"organisationId">();
  const { getParam } = useUrlParams<"org">();
  const organisationId = param ?? getParam("org");

  useEffect(() => {
    if (!organisationId) return;
    setCurrentOrg(organisationId);
    return () => setCurrentOrg(null);
  }, [organisationId, setCurrentOrg]);

  return <>{children ?? <Outlet />}</>;
}

/** @deprecated No longer does anything really, but wraps the new way of doing this to match the old one, so no code changes are needed */
export default function useOrgData() {
  return useOrg().currentOrg;
}

export function useQuestions(category: FormCategory) {
  const { questions } = useOrg().currentOrg;
  return useMemo(() => questions.filter((question) => question.category === category), [questions, category]);
}

/** This is just for analytics so it only goes by your cross-org permissions */
export function OrgSwitcher({ permission }: { permission: Permission }) {
  const { currentOrg, setCurrentOrg, loggedInUser, loggedInOrg } = useOrg();

  const orgs = loggedInUser.crossOrganisationPermissions
    .filter((p) => p.permission === permission && p.org.id !== loggedInOrg.id)
    .map(({ org }) => org);
  orgs.unshift(loggedInOrg);

  // Clear the selection when unmounting the component
  useEffect(() => () => setCurrentOrg(null), [setCurrentOrg]);

  if (orgs.length < 2) return null;

  return (
    <Select label="Choose organisation" hideLabel value={currentOrg.organisation.id} onChange={setCurrentOrg}>
      <option value="">Choose organisation</option>
      {orgs.map(({ id, name }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </Select>
  );
}
