import { useMutation } from "@apollo/client";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import mixpanel from "mixpanel-browser";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";

import { UploadImageMutation, UploadImageMutationVariables } from "../../../../../../../__generated__/graphql";
import Dialog from "../../../../../../../shared/components/design-system/Dialog";
import Icon from "../../../../../../../shared/components/design-system/Icon";
import LoadingSpinner from "../../../../../../../shared/components/design-system/LoadingSpinner";
import StringInput from "../../../../../../../shared/components/design-system/TextInput/StringInput";
import {
  SectionFooter,
  SectionHeader,
} from "../../../../../../../shared/components/design-system/component-groups/section-header-footer";
import { uploadFile } from "../../../../../../../shared/upload/uploadFile";
import { UPLOAD_IMAGE } from "../../../../../graphql/mutations";
import Button from "../../../Button";
import { isFirstInvalidField } from "../../../ImageUploader";
import { applyTransformations, imageFromFile } from "../../../ImageUploader/imageUtils";
import { createBlankCaption } from "../utils";
import { ImageNode } from "./ImageNode";
import { INSERT_IMAGE_COMMAND } from "./ImagePlugin";
import styles from "./styles.module.scss";

export default function InsertImageDialog({
  onCancel,
  alt,
  src,
  updateImageNode,
  siteId,
}: {
  onCancel: () => void;
  alt?: string;
  src?: string;
  siteId: string;
  updateImageNode?: (fn: (imageNode: ImageNode) => void) => void;
}) {
  const [isUploading, setImageUploading] = useState<boolean | "ERROR">(false);
  const [editor] = useLexicalComposerContext();
  const [originalImage, setOriginalImage] = useState<HTMLImageElement | "ERROR" | undefined>();

  const ref = useRef<HTMLDivElement | null>(null);
  const [altText, setAltText] = useState<string>(alt ?? "");
  const [url, setURL] = useState<string>(src ?? "");

  const [uploadImage] = useMutation<UploadImageMutation, UploadImageMutationVariables>(UPLOAD_IMAGE, {
    variables: { siteId },
  });

  const onInvalid = useCallback(() => {
    if (ref.current && isFirstInvalidField(ref.current)) {
      ref.current.focus();
    }
  }, []);

  const selectFile = useCallback(
    async ([file]: File[]) => {
      setImageUploading(true);
      mixpanel.track("Clicked to upload an image");
      try {
        setImageUploading(true);
        const uploadUrlsResult = await uploadImage();
        const {
          uploadImage: { publicUrl, uploadUrl },
        } = uploadUrlsResult.data!;
        await uploadFile(file, uploadUrl);
        setImageUploading(false);
        setURL(publicUrl);
        setOriginalImage(await imageFromFile(file));
      } catch (e) {
        setImageUploading("ERROR");
      }
    },
    [uploadImage],
  );
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    onDrop: selectFile,
  });

  const saveImage = useCallback(() => {
    if (src && updateImageNode) {
      mixpanel.track("Image replaced", { altText });
      updateImageNode((imageNode) => {
        imageNode.updateImageNode(url, altText);
      });
    } else if (url) {
      mixpanel.track("Image inserted", { altText });
      editor.dispatchCommand(INSERT_IMAGE_COMMAND, {
        altText,
        src: url,
        type: "image",
        version: 1,
        indent: 0,
        caption: createBlankCaption(),
      });
    }
    onCancel();
  }, [altText, editor, onCancel, src, updateImageNode, url]);

  const previewImageUrl: string | undefined = useMemo(
    () =>
      originalImage !== "ERROR" && originalImage
        ? applyTransformations(originalImage, { rotate: 0, flipH: false, flipV: false }).toDataURL("image/jpeg", 1)
        : undefined,
    [originalImage],
  );

  return (
    <Dialog isOpen onClose={onCancel} size="medium">
      <SectionHeader title="Image editor" />

      {/* Appears when image is uploaded */}
      {(originalImage && originalImage !== "ERROR") || src ? (
        <div className="c-image-uploader-cropper">
          <div className={`${styles.container} ${styles.insertImageContainer}`}>
            <img src={previewImageUrl ?? src} alt={altText} />
            {isUploading ? (
              <div className="c-image-uploader__status c-image-uploader__status--uploading">
                <LoadingSpinner />
                <p>Uploading</p>
              </div>
            ) : (
              <div className={`${styles.dropzone}`} ref={ref} {...getRootProps()}>
                <input
                  type="file"
                  accept="image/*"
                  title={`Open image uploader`}
                  onInvalid={onInvalid}
                  {...getInputProps()}
                />

                <div className="c-image-uploader__action">
                  <div>
                    <Icon icon="image" size={32} />
                    <br />
                    <span className="c-image-uploader__action-highlight">Choose a file</span>
                    <br />
                    or drag it or drag it here
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : null}
      {!originalImage && !src ? (
        <div
          className={`${styles.dropzone} ${isUploading === "ERROR" ? styles.uploadFailed : ""}`}
          ref={ref}
          {...getRootProps()}
        >
          <input
            type="file"
            accept="image/*"
            title={`Open image uploader`}
            onInvalid={onInvalid}
            {...getInputProps()}
          />
          {isUploading === "ERROR" ? (
            <div className="c-image-uploader__status c-image-uploader__status--failed">
              <div className="c-image-uploader__action">
                <div>
                  <Icon icon="image" size={32} />
                  <br />
                  <span className="c-image-uploader__action-highlight">Choose a file</span>
                  <br />
                  or drag it here
                </div>
              </div>
            </div>
          ) : isUploading === true ? (
            <div className="c-image-uploader__status c-image-uploader__status--uploading">
              <LoadingSpinner />
              <p>Uploading</p>
            </div>
          ) : (
            // Appears initially
            <div className="c-image-uploader__action">
              <div>
                <Icon icon="image" size={32} />
                <br />
                <span className="c-image-uploader__action-highlight">Choose a file</span>
                <br />
                or drag it here
              </div>
            </div>
          )}
        </div>
      ) : null}
      {isUploading === "ERROR" ? <span className="image-error-message">Image failed to upload</span> : null}

      <StringInput className={styles.altField} label="Alt text" value={altText} onChange={setAltText} />
      <p className="u-hint-text">Leave blank if image is decorative</p>
      <SectionFooter>
        <Button variant="ghost" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={saveImage} disabled={!!isUploading}>
          {originalImage ? "Save" : "Insert"}
        </Button>
      </SectionFooter>
    </Dialog>
  );
}
