import React from "react";
import { useParams } from "react-router-dom";

import { useOpenableWithTracking } from "../../../../../hooks/useBoolean";
import IconButton from "../../../Button/IconButton";
import { useIsRootEditor } from "../../hooks";
import InsertImageDialog from "./InsertImageDialog";

export default function InsertImageButton() {
  const { open, isOpen, close } = useOpenableWithTracking("Insert image modal");
  const { siteId } = useParams<"siteId" | "organisationId">();

  const isDisabled = !useIsRootEditor();

  return (
    <>
      <IconButton size="small" variant="ghost" onClick={open} icon="image" label="Insert image" disabled={isDisabled} />
      {isOpen ? <InsertImageDialog siteId={siteId!} onCancel={close} /> : null}
    </>
  );
}
