import React from "react";

import "./styles.scss";

const DAY_LABELS = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];

const Weekdays = () => (
  <ul className="c-weekdays">
    {DAY_LABELS.map((day) => (
      <li key={day} className="weekdays__day">
        {day}
      </li>
    ))}
  </ul>
);

export default Weekdays;
