import * as Sentry from "@sentry/browser";
import { v5 as uuidv5 } from "uuid";

import { AnalyticsCode, AnalyticsGroup, AnalyticsSplit } from "../../../../__generated__/graphql";
import { CustomGroupSet, DimensionGroupConfiguration } from "../../../../shared/components/charts/types";
import { OrgDataCurrentOrg, OrgDataQuestion } from "../../hooks/useOrg/types";
import { getGroupPreview } from "./AnalyticsInDetailPage/AnalyticsChartSelectionPanel/grouping/default-groups";
import { SplitSettings } from "./AnalyticsInDetailPage/useUrlSplitDimension";

const labelGroupingNamespace = "3a69164c-fd52-4dd8-822a-52bf5600ec95";

export function ungroupedSplit(code: Pick<SplitSettings, "analyticsCode" | "questionId">): AnalyticsSplit {
  if (code.analyticsCode) {
    return { code: code.analyticsCode };
  }

  return {
    code: AnalyticsCode.CustomQuestion,
    questions: [code.questionId!],
  };
}

type SplitOptions = Pick<SplitSettings, "analyticsCode" | "questionId" | "groups">;

// The "analytics code" in this is one of the extended varieties that can include "OUTCOME" or "custom-some-guid"
export function splitOptionsFromCode(code: string, questions: OrgDataQuestion[]): SplitOptions {
  if (code.startsWith("custom-")) {
    return { analyticsCode: null, groups: { basis: "NONE" }, questionId: code.substring(7) };
  }
  const analyticsCode = code as AnalyticsCode;
  if (Object.values(AnalyticsCode).includes(analyticsCode)) {
    return { analyticsCode, groups: defaultGroups(analyticsCode, questions), questionId: null };
  }
  const error = new Error("Unrecognised analytics code");
  Sentry.captureException(error, { extra: { code } });
  throw error;
}

export default function splitOn(
  settings: SplitOptions,
  orgData: Pick<OrgDataCurrentOrg, "questions" | "sites" | "outcomes">,
): AnalyticsSplit {
  const split = ungroupedSplit(settings);

  const groupConfig = settings.groups;
  if (!groupConfig) return split;

  const groups: AnalyticsGroup[] = [];

  const uiGroups: CustomGroupSet =
    groupConfig.basis === "CUSTOM" ? groupConfig.groups : getGroupPreview(groupConfig, null, orgData, split);

  for (const group of uiGroups) {
    if ("members" in group) {
      if (group.members.length > 0) {
        groups.push({
          // For custom-defined groups, don't send the label to the backend because it is very likely to change a lot and there's no need to re-run the analysis when it does. Instead we'll assign a label from the group definitions just before we draw the chart.
          label: groupConfig.basis === "CUSTOM" ? "Group" : group.label,
          id: group.id,
          members: group.members.flatMap<string>((a) => a.ids),
        });
      }
      continue;
    }
    if (group.ids.length > 1) {
      groups.push({ label: group.label, id: uuidv5(group.label, labelGroupingNamespace), members: group.ids });
    }
  }

  if (groups.length === 0) return split;
  return { ...split, groups };
}

export function defaultGroups(code: AnalyticsCode | null, questions: OrgDataQuestion[]): DimensionGroupConfiguration {
  if (
    code === AnalyticsCode.Faculty &&
    questions.some((q) => q.analyticsCode === "FACULTY" && q.optionGroups && q.optionGroups.length > 1)
  ) {
    return { basis: "FORM" };
  }
  return { basis: "NONE" };
}
