import React, { PropsWithChildren } from "react";

import { ExternalLinkButton } from "../../../../../../shared/components/design-system/Button/LinkButton";
import Dialog from "../../../../../../shared/components/design-system/Dialog";
import {
  SectionFooter,
  SectionHeader,
} from "../../../../../../shared/components/design-system/component-groups/section-header-footer";
import styles from "./styles.module.scss";

export default function UpsellModal({
  graphic,
  title,
  children,
  isOpen,
  onClose,
}: PropsWithChildren<{
  graphic: string;
  title: string;
  isOpen: boolean;
  onClose: () => void;
}>) {
  return (
    <Dialog closeOnClickOutside isOpen={isOpen} size="small" onClose={onClose}>
      <SectionHeader title={title} />
      <div className={styles.UpsellModal__image} style={{ backgroundImage: `url(${graphic})` }} />
      {children}
      <SectionFooter>
        <ExternalLinkButton to="mailto:hello@culture-shift.co.uk">Get in touch</ExternalLinkButton>
      </SectionFooter>
    </Dialog>
  );
}
