import React, { ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { unknownOrganisationId } from "../../../../api/resolvers/helpers/placeholder-ids";
import suspendedImage from "../../assets/images/error.svg";
import useSelf from "../../hooks/useSelf";
import ExportAllDataButton from "../admin/ExportAllDataButton";
import Card from "../design-system/Card";
import Container from "../design-system/Container";
import EmptyPanel from "../design-system/EmptyPanel";
import { FunctionComponent } from "../util";

const LockoutChecks: FunctionComponent = ({ children }) => {
  const self = useSelf();
  const location = useLocation();

  const hasNoAccess =
    !self.hasReportsAccess &&
    !self.hasAnalyticsAccess &&
    !self.hasSiteAccess &&
    !self.hasSurveyAccess &&
    !self.hasUsersAccess &&
    !self.hasFormsAccess;

  useEffect(() => {
    if (self.org.isSuspended) {
      document.title = "Culture Shift | Site Suspended";
    } else if (hasNoAccess) {
      document.title = "Culture Shift";
    }
  }, [self.org.isSuspended, hasNoAccess]);

  if (self.org.isSuspended) {
    const hasExportAccess =
      self.team.teamAccess === "ALL" &&
      self.team.siteAccess === "ALL" &&
      self.team.reportAccess === "ALL" &&
      self.team.formAccess === "ALL" &&
      self.team.surveyAccess === "ALL" &&
      self.team.answerAccess === "ALL";
    return siteError(
      "Your site has been suspended.",
      <>
        Your annual license fee is now overdue, and your site has been suspended. If you have any questions, please
        contact Culture Shift on 0161 443 4162 or{" "}
        <a href="mailto:accounts@culture-shift.co.uk">accounts@culture-shift.co.uk</a>
      </>,
      hasExportAccess ? "You can still download a zip file containing all the data in your system." : null,
      hasExportAccess ? <ExportAllDataButton /> : null,
    );
  }

  if (hasNoAccess && location.pathname !== "/account") {
    if (self.org.id === unknownOrganisationId) {
      return siteError(
        <>
          You have logged in using the username <b>{self.id.split("_").slice(1).join("_")}</b>, but this does not match
          any username known to Culture Shift.
        </>,
        "Please check with your administrator they have added you using this username.",
      );
    }

    return siteError(
      "You are successfully signed in, but do not have access to any parts of the system.",
      "Please check with your administrator that you have been added to the system.",
    );
  }

  return <>{children}</>;
};

export default LockoutChecks;

function siteError(headline: ReactNode, ...details: ReactNode[]) {
  return (
    <Container size="small">
      <Card className="ds-my-12 ds-py-8">
        <EmptyPanel image={suspendedImage}>{headline}</EmptyPanel>
        {details.map(
          (row, i) =>
            row && (
              <p className="ds-mt-4 ds-px-10 u-text-center" key={i}>
                {row}
              </p>
            ),
        )}
      </Card>
    </Container>
  );
}
