// This is a link styled to look like a button.
// Use it where the user should see a button, but all that happens when you click it is that the browser navigates to a new page.
// This is useful for download links, paginators, page preview buttons, etc.
import React from "react";

import Button, { ButtonSizeProps, GlobalButtonProps } from ".";

export type LinkButtonProps = Omit<GlobalButtonProps, "label"> & ButtonSizeProps & { to: string; children: string };

export function InternalLinkButton({ children, ...props }: LinkButtonProps) {
  return <Button {...props} linkType={"internal" as const} label={children} />;
}

export function ExternalLinkButton({ children, ...props }: LinkButtonProps) {
  return <Button {...props} linkType={"external" as const} label={children} />;
}

export function DownloadLinkButton({ children, ...props }: LinkButtonProps) {
  return <Button {...props} linkType={"download" as const} label={children} />;
}
