import mixpanel from "mixpanel-browser";
import React, { useCallback } from "react";

import Select from "../../design-system/Select";

export const MONTHS = [
  { label: "January", value: "01" },
  { label: "February", value: "02" },
  { label: "March", value: "03" },
  { label: "April", value: "04" },
  { label: "May", value: "05" },
  { label: "June", value: "06" },
  { label: "July", value: "07" },
  { label: "August", value: "08" },
  { label: "September", value: "09" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" },
];

export default function MonthSelect({
  label,
  value,
  onChange,
  className,
  trackingText,
}: {
  // we could just pass in the settings object here but for consistency with yearselect let's do it oldschool
  label: string;
  value: string;
  onChange: (value: string) => void;
  className?: string;
  trackingText: string;
}) {
  const changeMonth = useCallback(
    (value: string) => {
      mixpanel.track(trackingText, { month: value });
      onChange(value);
    },
    [onChange, trackingText],
  );

  return (
    <Select label={label} size="medium" onChange={changeMonth} value={value} className={className}>
      {MONTHS.map(({ value, label }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </Select>
  );
}
