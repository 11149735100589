import { useEffect } from "react";

import useSearch from "../../../hooks/useSearch";
import { filterSearchOptions } from "../FilterPanel";
import { DeletedOptionGroup } from "./useDeletedGroups";

export default function useExpandDeletedOnSearchMatch(
  showDeleted: boolean | undefined,
  deletedGroups: DeletedOptionGroup[],
  optionSearch: string | undefined,
  setDeletedExpanded: (expanded: boolean) => void,
) {
  // The set of deleted options that match any search string that's been passed in. It's only used in the useEffect immediately below.
  const deletedMatchSearch = useSearch({
    items: showDeleted
      ? deletedGroups.map(({ id, title, options }) => ({
          id: id ?? "null",
          text: [title, ...options.map(({ value }) => value)].join(" "),
        })) ?? []
      : [],
    fields: ["text"],
    searchText: optionSearch ?? "",
    emptyWithNoSearchTerm: true,
    searchOptions: filterSearchOptions,
  });

  // If any deleted options match the search string, expand the 'deleted options' panel
  const anyDeletedMatchSearch = deletedMatchSearch.length > 0;
  useEffect(() => {
    if (anyDeletedMatchSearch) {
      setDeletedExpanded(true);
    }
  }, [anyDeletedMatchSearch, setDeletedExpanded]);
}
