import { useCallback } from "react";

export type WithSelection<T> = {
  selection?: T;
  onChange: (selection: T) => void;
};

// "undefined" means "all" so it's only valid if showAllOption is true
export type SelectionOptions =
  | (WithSelection<string[] | undefined> & { showAllOption: true })
  | (WithSelection<string[]> & { showAllOption?: false });

export default function useSelection(options: SelectionOptions) {
  const { selection, onChange } = options;

  // Toggling never sets the selection to undefined so this code works in both cases:
  const toggleId = useCallback(
    (id: string) => {
      if (selection?.includes(id)) {
        onChange(selection.filter((i) => id !== i));
      } else {
        onChange([...(selection ?? []), id]);
      }
    },
    [selection, onChange],
  );

  // Set this variable to "an onChange callback that accepts undefined, or nothing at all"
  const selectAllCallback = options.showAllOption ? options.onChange : null;
  // For some reason that seems to be TypeScript's favourite way of handling this safely.
  const selectAll = useCallback(() => selectAllCallback?.(undefined), [selectAllCallback]);

  return { toggleId, selectAll };
}
