import { EditorThemeClasses, SerializedLexicalNode } from "lexical";
import mixpanel, { Dict } from "mixpanel-browser";
import React, { FunctionComponent, useCallback } from "react";

import { Caption } from "../../../../../../../shared/block-editor-data/types";
import Button from "../../../../../../../shared/components/design-system/Button";
import { useOpenableWithTracking } from "../../../../../hooks/useBoolean";
import BlockContainer from "../BlockContainer";

export type SerializedCustomNode<T> = SerializedLexicalNode & { csConfig: T; caption?: Caption };

export type CustomBlockProps<T> = {
  options: T;
  onChange: (options: T) => void;
  caption: Caption;
  nodeKey: string;
  theme: EditorThemeClasses;
};

export default function editableBlockComponent<T>(
  ChartComponent: FunctionComponent<{ options: T }>,
  DialogComponent: FunctionComponent<{
    onOk: (options: T) => void;
    onCancel: () => void;
    defaultValue?: T;
  }>,
  trackingProperties?: Dict,
) {
  return function ChartBlockComponent({ options, onChange, caption, theme, nodeKey }: CustomBlockProps<T>) {
    const { isOpen, open, close } = useOpenableWithTracking("Edit block dialog", trackingProperties);

    const onOk = useCallback(
      (options: T) => {
        onChange(options);
        close();
        mixpanel.track("Block edits saved", trackingProperties);
      },
      [close, onChange],
    );

    return (
      <>
        <BlockContainer
          actionButtons={<Button variant="action" onClick={open} icon="edit" label="Edit" />}
          captionProps={{ caption, theme, nodeKey }}
        >
          <ChartComponent options={options} />
        </BlockContainer>
        {isOpen ? <DialogComponent onOk={onOk} onCancel={close} defaultValue={options} /> : null}
      </>
    );
  };
}
