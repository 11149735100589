import { useCallback, useMemo } from "react";

import { FormCategory } from "../../../../../__generated__/graphql";
import { ChartType, InDetailChartOptions } from "../../../../../shared/components/charts/types";
import { AnalyticsCodeDescription, useAvailableAnalyticsCodes } from "../../../graphql/values";
import { useJsonWithLocalStorage } from "../../../hooks/useStateWithLocalStorage";
import { useUrlParams } from "../../../hooks/useUrlParams";
import { SplitSettings } from "./useUrlSplitDimension";

export interface ChartTypeSettings {
  chartType: ChartType;
  setChartType: (type: ChartType) => void;
}

// This shouldn't change after the initial render, it's just a way of injecting config settings into the chart editor
export interface InDetailChartConfig {
  allowHeatMap: boolean;
}

export interface InDetailChartSettings {
  config: InDetailChartConfig;
  chartOptions: InDetailChartOptions;
  title: string;
  legendTitle: string | null;
  setChartOptions: (options: InDetailChartOptions) => void;
  // These aren't used presently but will be in the embedded version
  setTitle?: (title: string) => void;
  setLegendTitle?: (title: string) => void;
}

export function useUrlChartType() {
  const { getParam, setParam } = useUrlParams<"chart">();
  const chartType: ChartType = getParam("chart") === "TABLE" ? "TABLE" : "BAR";
  const setChartType = useCallback((type: ChartType) => setParam("chart", type), [setParam]);
  return useMemo<ChartTypeSettings>(() => ({ chartType, setChartType }), [chartType, setChartType]);
}

// Chart options live in local storage since they're more likely to be relevant per-user than per-chart. Ofc this does not apply to charts within Insights Reports, which should look the same every time they are rendered.
export function useLocalStorageChartOptions(
  firstSplit: SplitSettings,
  secondSplit: SplitSettings,
  category: FormCategory,
) {
  const [chartOptions, setChartOptions] = useJsonWithLocalStorage<InDetailChartOptions>(
    "analytics-display-options",
    {
      showNumbers: false,
      showOuterBars: false,
      showHeatMap: true,
      showPercentages: false,
      showNotAnswered: false,
      showZeroBars: true,
    },
    { mixpanelMessage: "Updated analytics display options" },
  );

  const { allCodes } = useAvailableAnalyticsCodes(category);
  const title = getTitle(allCodes, firstSplit);
  // This fallback value should never appear so it's probably not worth translating:
  const legendTitle = secondSplit.analyticsCode || secondSplit.questionId ? getTitle(allCodes, secondSplit) : null;

  return useMemo<InDetailChartSettings>(
    () => ({ chartOptions, title, legendTitle, setChartOptions, config: { allowHeatMap: true } }),
    [chartOptions, title, legendTitle, setChartOptions],
  );
}

export function getTitle(codes: AnalyticsCodeDescription[], split: SplitSettings) {
  const codeId = split.analyticsCode ?? `custom-${split.questionId}`;
  for (const code of codes) {
    if (code.code === codeId) {
      return code.long;
    }
  }
  throw new Error("Invalid code selected");
}
