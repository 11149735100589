import { endOfDay, isBefore, startOfDay } from "date-fns";

import { PeriodId } from "../../../timePeriods";

export type Month = {
  month: number;
  year: number;
};

export interface Day {
  date: Date;
  dateString: string;
  label: number;
  isDisabled?: boolean;
  isCurrentMonth?: boolean;
  isToday?: boolean;
  isStartDate?: boolean;
  isEndDate?: boolean;
  isInRange?: boolean;
}

export type MonthDays = Day[];

export interface DateRange {
  startDate?: Date;
  endDate?: Date;
  presetId?: PeriodId;
}

export interface DateRangePreset extends DateRange {
  label: string;
  presetId: PeriodId;
}

export function dateRange<T extends DateRange>(
  { startDate, endDate, ...props }: T,
  options: {
    allowBackwards?: boolean;
  } = {},
): T {
  if (!options.allowBackwards && startDate && endDate && isBefore(endDate, startDate)) {
    [startDate, endDate] = [endDate, startDate];
  }
  return {
    ...props,
    startDate: startDate ? startOfDay(startDate) : undefined,
    endDate: endDate ? endOfDay(endDate) : undefined,
  } as T;
}
