// $isCaptionedNode gives us a way to check if a node has a caption, without importing $isTableNode or inDetailChartPlugin.$is or whatever into this file (which creates a nasty circular dependency where the caption component depends on those types, and those types depend on the caption component). Instead we register captionable types in their own file, which reverses this half of the dependency, so the captioned components depend on captions but not vice versa.
import { LexicalNode } from "lexical";

interface CaptionedNode {
  updateCaptionJSON(json: string): void;
}

type NodeCheck = (node: LexicalNode | null | undefined) => node is LexicalNode & CaptionedNode;

const nodeChecks: NodeCheck[] = [];

export function registerNodeCheck(check: NodeCheck) {
  if (!nodeChecks.includes(check)) nodeChecks.push(check);
}

export function $isCaptionedNode(node: LexicalNode | null | undefined): node is LexicalNode & CaptionedNode {
  if (!node) return false;
  for (const check of nodeChecks) {
    if (check(node)) return true;
  }
  return false;
}
