import { $isHeadingNode } from "@lexical/rich-text";
import { $isRangeSelection, FORMAT_TEXT_COMMAND, TextFormatType } from "lexical";
import React from "react";

import { IconSlug } from "../../../../../../shared/components/design-system/Icon";
import IconButton from "../../Button/IconButton";
import { useCommandWithPayload, useSelectionProperty } from "../hooks";
import { isParentAHeadingNode } from "./utils";

export default function FormattingButton({
  format,
  label,
  icon,
}: {
  format: TextFormatType;
  label: string;
  icon: IconSlug;
}) {
  const handleClick = useCommandWithPayload(FORMAT_TEXT_COMMAND, format);

  const isDisabled = useSelectionProperty(
    (selection) => {
      if (!selection) return true;
      const allNodes = selection.getNodes();
      //currently we don't want to allow any text styling on headings but this might change in the future
      //check if the parent is a heading node(for click and drag selection) or if the selected nodes contain a heading node(for double and triple click selection)
      if (selection.getNodes().every(isParentAHeadingNode) || allNodes.some($isHeadingNode)) {
        return true;
      }
      return false;
    },
    [],
    true,
  );

  const isActive = useSelectionProperty(
    (selection) => {
      if (!selection) return false;
      if (!$isRangeSelection(selection)) return false;
      return selection.hasFormat(format);
    },
    [format],
    false,
  );

  return (
    <IconButton
      size="small"
      variant="ghost"
      onClick={handleClick}
      isActive={isActive}
      label={label}
      icon={icon}
      disabled={isDisabled}
    />
  );
}
