import React, { useCallback, useState } from "react";

import { InDetailChartBlockOptions } from "../../../../../../../shared/block-editor-data/chart-block-types";
import { OverTimeChartBlockOptions } from "../../../../../../../shared/block-editor-data/chart-block-types";
import IconButton from "../../../Button/IconButton";
import useSelect, { SelectProps } from "../../../RadioGroup/useSelect";
import { useCommand, useIsRootEditor } from "../../hooks";
import inDetailPlugin from "./in-detail";
import EditInDetailChartDialog from "./in-detail/EditInDetailChartDialog";
import overTimePlugin from "./over-time";
import EditOverTimeChartDialog from "./over-time/EditOverTimeChartDialog";

const OPTIONS: SelectProps<"in-detail" | "over-time"> = {
  title: "Insert analytics data",
  label: "What sort of data would you like to insert?",
  okCaption: "Next",
  cancelCaption: "Cancel",
  options: [
    {
      id: "in-detail",
      title: "In detail",
      body: "Split by report question and filter to see a detailed breakdown of your reporting data",
    },
    {
      id: "over-time",
      title: "Over time",
      body: "Split by time and filter to see trends in your reporting data over time",
    },
  ],
  trackingId: "insert analytics data",
  defaultValue: "in-detail",
};

export default function InsertChartButton() {
  const [openDialog, setOpenDialog] = useState<"in-detail" | "over-time" | null>(null);

  const select = useSelect();

  const insertInDetailCommand = useCommand(inDetailPlugin.commands.insert);
  const insertOverTimeCommand = useCommand(overTimePlugin.commands.insert);

  const onClick = useCallback(async () => setOpenDialog(await select(OPTIONS)), [select]);

  const close = useCallback(() => setOpenDialog(null), []);

  const insertInDetailChart = useCallback(
    (options: InDetailChartBlockOptions) => {
      insertInDetailCommand(options);
      close();
    },
    [close, insertInDetailCommand],
  );

  const insertOverTimeChart = useCallback(
    (options: OverTimeChartBlockOptions) => {
      insertOverTimeCommand(options);
      close();
    },
    [close, insertOverTimeCommand],
  );

  const isDisabled = !useIsRootEditor();

  return (
    <>
      <IconButton
        size="small"
        variant="ghost"
        disabled={isDisabled}
        onClick={onClick}
        icon="chart"
        label="Insert analytics data"
      />
      {openDialog === "in-detail" ? <EditInDetailChartDialog onOk={insertInDetailChart} onCancel={close} /> : null}
      {openDialog === "over-time" ? <EditOverTimeChartDialog onOk={insertOverTimeChart} onCancel={close} /> : null}
    </>
  );
}
