import React, { FunctionComponent } from "react";

export interface LogoProps {
  variant?: "light" | "dark";
  className?: string;
  small?: boolean;
}

const Logo: FunctionComponent<LogoProps> = ({ variant = "dark", className, small }) => {
  const textColor = variant === "dark" ? "#383351" : "#fff";

  if (small) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={29.812}
        height={29.011}
        className={className}
        aria-labelledby="csSmallLogoTitle"
        role="img"
      >
        <title id="csSmallLogoTitle">Culture Shift</title>
        <g>
          <g>
            <path
              d="M27.526 25.145c0-3.215-7.377-.688-7.377-5.965 0-2.1 1.783-3.9 4.72-3.9 3.1 0 4.738 1.9 4.794 4.366h-2.211a2.325 2.325 0 00-2.583-2.341c-1.543 0-2.49.65-2.49 1.82 0 2.9 7.432.651 7.432 5.928 0 2.4-2.081 3.958-5.24 3.958-3.512 0-4.868-2.23-4.942-4.385h2.23c.093 1.413 1.041 2.36 2.824 2.36 1.802-.002 2.843-.649 2.843-1.841z"
              fill={textColor}
            />
          </g>
          <g>
            <path
              d="M6.597 0a6.075 6.075 0 016.336 5.092h-2.4a3.787 3.787 0 00-3.936-2.936c-2.639 0-4.218 1.858-4.218 4.7s1.58 4.72 4.218 4.72a3.787 3.787 0 003.939-2.936h2.4a6.075 6.075 0 01-6.339 5.092c-3.847 0-6.6-2.806-6.6-6.875C-.003 2.806 2.75 0 6.597 0z"
              fill={textColor}
            />
          </g>
          <g>
            <path fill="#7f76cf" d="M.801 27.729L28.525.005l1.282 1.281L2.083 29.011z" />
          </g>
        </g>
      </svg>
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 172 54"
      style={{ maxWidth: "172px" }}
      aria-labelledby="csLogoTitle"
      role="img"
    >
      <title id="csLogoTitle">Culture Shift</title>
      <g>
        <g fill={textColor}>
          <path d="M12.22.99c5.56 0 9.86 3.32 10.78 8.66h-4.08c-.76-3.16-3.32-5-6.7-5-4.49 0-7.17 3.17-7.17 8s2.68 8.03 7.17 8.03c3.38 0 5.94-1.83 6.7-5H23c-.92 5.35-5.22 8.66-10.78 8.66C5.68 24.34 1 19.57 1 12.65 1 5.77 5.68.99 12.22.99z" />
          <path d="M26.74 14.68V1.47h3.82v13.36c0 3.57 1.8 6 5.5 6s5.5-2.43 5.5-6V1.47h3.82v13.2c0 6.58-4.2 9.7-9.32 9.7-5.15 0-9.32-3.12-9.32-9.7z" />
          <path d="M64.79 23.9H50.57V1.47h3.82v18.9h10.4z" />
          <path d="M79.09 5.1h-6.5v18.8h-3.82V5.1h-6.51V1.47H79.1z" />
          <path d="M81.84 14.68V1.47h3.83v13.36c0 3.57 1.8 6 5.5 6s5.5-2.43 5.5-6V1.47h3.82v13.2c0 6.58-4.2 9.7-9.32 9.7-5.16 0-9.33-3.12-9.33-9.7z" />
          <path d="M119.2 23.9l-4.55-8.85h-5.15v8.85h-3.82V1.47h9.86c4.96 0 7.39 2.87 7.39 6.79a6.17 6.17 0 01-4.4 6.32l5.16 9.32zM115.57 5h-6.07v6.5h6.07c2.37 0 3.5-1.32 3.5-3.25S117.93 5 115.57 5z" />
          <path d="M142.8 23.9h-15.78V1.47h15.77V5h-11.95v5.76h11.4v3.54h-11.4v6.07h11.94z" />
        </g>
        <g fill={textColor}>
          <path d="M97.25 46.41c0-5.46-12.54-1.16-12.54-10.14 0-3.57 3.03-6.63 8.02-6.63 5.28 0 8.06 3.22 8.16 7.42h-3.76c-.03-2.21-1.52-3.98-4.4-3.98-2.62 0-4.23 1.1-4.23 3.1 0 4.93 12.64 1.1 12.64 10.08 0 4.08-3.54 6.73-8.91 6.73-5.97 0-8.28-3.8-8.4-7.46h3.79c.15 2.4 1.77 4.02 4.8 4.02 3.06 0 4.83-1.11 4.83-3.13z" />
          <path d="M123.7 52.55h-3.82v-9.6h-10.64v9.6h-3.83V30.12h3.82v9.29h10.65v-9.29h3.83z" />
          <path d="M132.9 52.55h-3.82V30.12h3.82z" />
          <path d="M138.35 52.55V30.12h14.66v3.54h-10.83v5.75h10.3v3.54h-10.3v9.6z" />
          <path d="M171 33.75h-6.5v18.8h-3.82v-18.8h-6.51v-3.63H171z" />
        </g>
        <path fill="#7268ca" d="M3.19 39.68h70.23v3.09H3.19z" />
      </g>
    </svg>
  );
};

export default Logo;
