import { Dict } from "mixpanel-browser";
import { useCallback, useState } from "react";

import useTrackedCallback from "./useTrackedCallback";

interface ReturnType {
  value: boolean;
  setTrue: () => void;
  setFalse: () => void;
  toggle: () => void;
  setValue: (value: boolean) => void;
}

export default function useBoolean(defaultValue = false): ReturnType {
  const [value, setValue] = useState(defaultValue);

  const setTrue = useCallback(() => setValue(true), []);
  const setFalse = useCallback(() => setValue(false), []);
  const toggle = useCallback(() => setValue((val) => !val), []);

  return { value, setTrue, setFalse, toggle, setValue };
}

// A shorthand way of getting mixpanel-tracked open/close callbacks
export function useOpenableWithTracking(name: string, data?: Dict, defaultValue = false) {
  const { value, setTrue, setFalse } = useBoolean(defaultValue);

  const open = useTrackedCallback(setTrue, `Opened the ${name}`, data);
  const close = useTrackedCallback(setFalse, `Closed the ${name}`, data);

  return { isOpen: value, open, close, toggle: value ? close : open };
}
