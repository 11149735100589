import React, { PropsWithChildren, useRef } from "react";

import { dialogContext } from "../../../../../shared/components/design-system/Dialog";
import Modal from "../../../../../shared/components/design-system/Modal";
import useGeneratedId from "../../../../../shared/hooks/useGeneratedId";
import styles from "./styles.module.scss";

export default function SidePanel({
  isOpen,
  onClose,
  children,
  closeOnClickOutside,
}: PropsWithChildren<{
  isOpen: boolean;
  onClose: () => void;
  closeOnClickOutside?: boolean;
}>) {
  const titleRef = useRef<HTMLHeadingElement>(null);
  const dialogId = useGeneratedId();

  return (
    <dialogContext.Provider value={{ dialogId, onClickClose: onClose, titleRef }}>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        dimBackground
        closeOnClickOutside={closeOnClickOutside}
        labelRef={titleRef}
        labelledBy={`modal-${dialogId}-title`}
        isLeaf={false}
      >
        <div className={`${styles.SidePanel} ${isOpen ? styles.open : styles.closed}`}>{children}</div>
      </Modal>
    </dialogContext.Provider>
  );
}

export function ScrollableContent({ children }: PropsWithChildren<unknown>) {
  return <div className={styles.ScrollableContent}>{children}</div>;
}
