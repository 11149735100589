import { GraphQLError, GraphQLErrorOptions } from "graphql";

export const FORBIDDEN_ERROR_CODE = "FORBIDDEN";
export const UNAUTHENTICATED_ERROR_CODE = "UNAUTHENTICATED";
export const USER_INPUT_ERROR_CODE = "BAD_REQUEST";

class RSError extends GraphQLError {
  constructor(message: string, code: string, options?: GraphQLErrorOptions) {
    super(message, { ...(options ?? {}), extensions: { ...(options?.extensions ?? {}), code } });
  }
}

export class ForbiddenError extends RSError {
  constructor(message?: string, options?: GraphQLErrorOptions) {
    super(message ?? "Forbidden", FORBIDDEN_ERROR_CODE, options);
  }
}

export class AuthenticationError extends RSError {
  constructor(message?: string, options?: GraphQLErrorOptions) {
    super(message ?? "Unauthenticated", UNAUTHENTICATED_ERROR_CODE, options);
  }
}

export class UserInputError extends RSError {
  constructor(message?: string, options?: GraphQLErrorOptions) {
    super(message ?? "User input error", USER_INPUT_ERROR_CODE, options);
  }
}
