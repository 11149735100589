import { endOfDay, formatISO, parseISO } from "date-fns";
import mixpanel from "mixpanel-browser";
import React, { FunctionComponent, useCallback, useMemo } from "react";

import { TimePeriodSettings } from "../../../hooks/filtering/useTimePeriodFromUrlParams";
import { DateRangeDropdown } from "../../design-system/DatePicker";
import { defaultPresets } from "../../design-system/DatePicker/defaultPresets";
import { DateRange } from "../../design-system/DatePicker/types";
import "./styles.scss";

const DateFilter: FunctionComponent<{ datePickerSettings: TimePeriodSettings; className?: string }> = ({
  datePickerSettings: { timePeriod, setTimePeriod, usePresets },
  className,
}) => {
  const setValue = useCallback(
    (value: DateRange | null) => {
      const start = value?.startDate && formatISO(value?.startDate);
      const end = value?.endDate && formatISO(value?.endDate);

      mixpanel.track("Changed date filter", { period: value?.presetId, start, end });

      setTimePeriod({
        period: value?.presetId ?? "SPECIFIC",
        specificStart: start,
        specificEnd: end,
      });
    },
    [setTimePeriod],
  );

  const dateRange: DateRange | null = useMemo(() => {
    if (timePeriod.period === "SPECIFIC") {
      const { specificStart, specificEnd } = timePeriod;
      return {
        startDate: specificStart ? parseISO(specificStart) : undefined,
        endDate: specificEnd ? parseISO(specificEnd) : undefined,
      };
    }
    const preset = defaultPresets.find(({ presetId }) => presetId === timePeriod.period);
    if (!preset) return null;
    const { startDate, endDate } = preset;
    return {
      startDate,
      endDate,
      preset: timePeriod.period,
    };
  }, [timePeriod]);

  const minDate = useMemo(() => new Date(2017, 0, 1), []);
  const maxDate = useMemo(() => endOfDay(new Date()), []);

  return (
    <DateRangeDropdown
      inputSize="small"
      className={className}
      onChange={setValue}
      presets={usePresets ? defaultPresets : [{ presetId: "ALL_TIME", label: "All time" }]}
      value={dateRange}
      allowOpenEnded
      minDate={minDate}
      maxDate={maxDate}
    />
  );
};

export default DateFilter;
