import gql from "graphql-tag";

import type { GetSelfQuery } from "../../../../__generated__/graphql";
import { teamPermissions } from "../../graphql/queries";

export const SELF_QUERY = gql`
  query getSelf {
    self {
      id
      name
      email
      org {
        id
        name
        sector
        ssoProvider
        billingWarning
        featureGates
        isTestSite
        isSuspended
        sites {
          id
          name
          language
        }
      }
      team {
        id
        name
        ...teamPermissions
      }
      hasReportsAccess
      hasAnalyticsAccess
      hasSiteAccess
      hasUsersAccess
      hasFormsAccess
      hasSurveyAccess
      canRedactReport
      canCreateTeam
      crossOrganisationPermissions {
        org {
          id
          name
        }
        permission
      }
      isSsoUser
      hasMfaEnabled
      acceptTracking
      receiveEmailNotifications
      receiveEmailAssignments
      receiveNewMessageEmails
      unreadFeatureAnnouncements
      answeredNewsletterCta
      viewSettings {
        name
        value
      }
    }
  }
  ${teamPermissions}
`;

export type Self = GetSelfQuery["self"] & { isSuperAdmin: boolean };
