import mixpanel from "mixpanel-browser";
import React, { useEffect, useRef } from "react";

import { AnalyticsCode, FormCategory, QuestionType } from "../../../../../__generated__/graphql";
import useFilterFromUrlParams from "../../../hooks/filtering/useFilterFromUrlParams";
import useReportFields, { Column } from "../../../hooks/useReportFields";
import Card from "../../design-system/Card";
import Container from "../../design-system/Container";
import FilterChips from "../../design-system/FilterChips";
import FilterPanel from "../../design-system/FilterPanel";
import { ReportsAnalysisNavigator } from "../AnalysisNavigator";
import AnalyticsHeader from "../AnalyticsHeader";
import { useUrlChartType } from "../AnalyticsInDetailPage/chart-options";
import ChartActions from "./ChartActions";
import ChartPanel from "./ChartPanel";
import TimeframeSelection from "./TimeframeSelection";
import styles from "./index.module.scss";
import useOverTimeQuery from "./useOverTimeQuery";
import useUrlYearSettings from "./useUrlYearSettings";

export const DEFAULT_ANALYTICS_CODES: Array<AnalyticsCode | undefined> = [
  AnalyticsCode.Form,
  AnalyticsCode.IncidentType,
  AnalyticsCode.SuspectedCause,
  AnalyticsCode.MessageOptIn,
  AnalyticsCode.Faculty,
  AnalyticsCode.Role,
  AnalyticsCode.Gender,
  AnalyticsCode.SexualIdentity,
  AnalyticsCode.Outcome,
];
export const ALLOWED_QUESTION_TYPES: QuestionType[] = [QuestionType.Checklist, QuestionType.Radio];

export default function AnalyticsOverTimePage() {
  const yearSettings = useUrlYearSettings();

  const filterSettings = useFilterFromUrlParams({
    defaults: { excludeSpam: true },
    forbiddenFilters: {
      createdAt: true,
      updatedAt: false,
      status: false,
      assignedTo: false,
      outcome: false,
      form: false,
      questions: false,
      excludeSpam: false,
    },
  });

  const allColumns = useReportFields(ALLOWED_QUESTION_TYPES);
  const defaultPanels: Column[] = allColumns.filter((col) => DEFAULT_ANALYTICS_CODES.includes(col.analyticsCode));

  useEffect(() => {
    document.title = "Culture Shift | Your data over time";
    mixpanel.track("Visited Analytics Over Time");
  }, []);

  const { loading, data, error } = useOverTimeQuery(yearSettings, filterSettings);

  const chartTypeSettings = useUrlChartType();

  const ref = useRef<HTMLDivElement>(null);

  return (
    <Container>
      <AnalyticsHeader srTitle="Over time" />
      <ReportsAnalysisNavigator active="over-time" className="ds-mb-7" />
      <Card contentClassName={styles.OverTimeHeader}>
        <TimeframeSelection settings={yearSettings} />
        <FilterPanel
          settings={filterSettings}
          defaultPanels={defaultPanels}
          className={styles.OverTimeHeader__filterButton}
          category={FormCategory.Reporting}
        />
        <FilterChips className={styles.OverTimeHeader__filterChips} settings={filterSettings} />
      </Card>
      <ChartActions
        loading={loading}
        data={data}
        yearSettings={yearSettings}
        chartTypeSettings={chartTypeSettings}
        container={ref}
      />
      <Card ref={ref}>
        <ChartPanel
          loading={loading}
          error={error}
          data={data}
          yearSettings={yearSettings}
          filterSettings={filterSettings}
          chartTypeSettings={chartTypeSettings}
        />
      </Card>
    </Container>
  );
}
