import * as Sentry from "@sentry/browser";
import mixpanel from "mixpanel-browser";
import { useEffect } from "react";

import useSelf from "./hooks/useSelf";

// Note: by "analytics" here we mean Mixpanel and Sentry, not the report analytics tools built into the app.
export default function useAnalytics() {
  const self = useSelf();

  useEffect(() => {
    Sentry.setUser({
      id: self.id,
      email: self.email,
      name: self.name,
      team: self.team.name,
      organisation: self.org.name,
    });
  }, [self]);

  useEffect(() => {
    if (self.acceptTracking === "ALLOW" && !mixpanel.has_opted_in_tracking()) {
      mixpanel.opt_in_tracking();
      mixpanel.set_group("organisation", [self.org.id]);
      mixpanel.identify(self.id);
    } else if (self.acceptTracking !== "ALLOW" && !mixpanel.has_opted_out_tracking()) {
      mixpanel.opt_out_tracking();
    }
  }, [self]);
}
