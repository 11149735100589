import React, { forwardRef, FunctionComponent, MouseEvent, PropsWithChildren, useCallback } from "react";

import Icon from "../../../../../shared/components/design-system/Icon";
import LoadingSpinner from "../../../../../shared/components/design-system/LoadingSpinner";
import "./styles.scss";

export type ChipVariant = "danger" | "success" | "info" | "warning" | "default";

export type ChipProps = PropsWithChildren<{
  onClick?: () => void;
  onRemove?: () => void;
  internalLinkTo?: string;
  removeButtonLabel?: string;
  suffix?: number | string;
  variant?: ChipVariant;
  loading?: boolean;
}>;

const Chip = forwardRef<HTMLElement, PropsWithChildren<ChipProps>>(
  ({ children, onClick, onRemove, internalLinkTo, removeButtonLabel, suffix, variant, loading }, ref) => {
    const ElementTag = onClick ? "button" : "div";

    const wrappedOnClick = useCallback(() => onClick?.(), [onClick]);
    const wrappedOnRemove = useCallback(() => onRemove?.(), [onRemove]);

    return (
      // @ts-ignore - We don't know what kind of element "ref" needs but it must at least be an HTMLElement
      <ElementTag onClick={wrappedOnClick} className={`c-chip ${variant ? "is-" + variant : ""}`} ref={ref}>
        {loading && <LoadingSpinner size="extra-small" />}
        <ChipContent suffix={suffix} internalLinkTo={internalLinkTo}>
          {children}
        </ChipContent>
        {onRemove && !loading ? <ChipRemoveButton onClick={wrappedOnRemove} label={removeButtonLabel} /> : null}
      </ElementTag>
    );
  },
);

export type ChipContentProps = PropsWithChildren<{
  suffix?: number | string;
  internalLinkTo?: string;
}>;

export const ChipContent: FunctionComponent<ChipContentProps> = ({ children, suffix, internalLinkTo }) => (
  <>
    {internalLinkTo ? (
      <a href={internalLinkTo} className="c-chip__content">
        {children}
      </a>
    ) : (
      <div className="c-chip__content">{children}</div>
    )}
    {suffix ? <div className="c-chip__suffix">{suffix}</div> : null}
  </>
);

export interface ChipRemoveButtonProps {
  label?: string;
  onClick: (ev: MouseEvent<HTMLButtonElement>) => void;
}

export const ChipRemoveButton: FunctionComponent<ChipRemoveButtonProps> = ({ label = "Remove", onClick }) => (
  <button className="c-chip__remove-button" type="button" onClick={onClick} aria-label={label}>
    <Icon icon="xmark" />
    <span className="is-sr-only">{label}</span>
  </button>
);

export function ChipRow({ children, className }: PropsWithChildren<{ className?: string }>) {
  return <div className={`c-chip-container ${className ?? ""}`}>{children}</div>;
}

export default Chip;
