import classNames from "classnames";
import React, { FormEvent, RefObject, useCallback, useEffect, useState } from "react";

import Button from "../../Button";
import { ModalPopup, PopupAxisPosition } from "../../Popup";
import DateRangeWidget from "../DateRangeWidget";
import "../styles.scss";
import { DateRange, DateRangePreset } from "../types";
import DatePresets from "./DatePresets";

const POSITIONS = [
  { x: PopupAxisPosition.MatchStart, y: PopupAxisPosition.After },
  { x: PopupAxisPosition.MatchEnd, y: PopupAxisPosition.After },
  { x: PopupAxisPosition.Centred, y: PopupAxisPosition.After },
  { x: PopupAxisPosition.MatchStart, y: PopupAxisPosition.Before },
  { x: PopupAxisPosition.MatchEnd, y: PopupAxisPosition.Before },
  { x: PopupAxisPosition.Centred, y: PopupAxisPosition.Before },
];

export default function DateRangeDropdownModalForm({
  value,
  isOpen,
  onOk,
  onCancel,
  required,
  allowOpenEnded,
  minDate,
  maxDate,
  presets,
  inputRef,
}: {
  value: DateRange | null;
  isOpen: boolean;
  onOk: (value: DateRange | null) => void;
  onCancel: () => void;
  required?: boolean;
  allowOpenEnded?: boolean;
  minDate: Date;
  maxDate: Date;
  presets?: DateRangePreset[];
  inputRef: RefObject<HTMLElement>;
}) {
  const dropdownClassName = classNames({
    "c-date-picker__dropdown": true,
    "is-open": true,
    "has-presets": !!presets?.length,
    "is-range": true,
    "has-start-date": !!value?.startDate,
    "has-end-date": !!value?.endDate,
  });

  const [formValue, setFormValue] = useState(value);
  const choosePreset = useCallback((preset: DateRangePreset) => setFormValue({ ...preset }), []);

  const handleOk = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      onOk(formValue);
    },
    [onOk, formValue],
  );

  useEffect(() => {
    if (isOpen) setFormValue(value);
  }, [isOpen, value]);

  return (
    <ModalPopup title="Date range picker" positions={POSITIONS} source={inputRef} shown={isOpen} onClose={onCancel}>
      <form className={dropdownClassName} role="dialog" aria-modal="true" aria-label="Date picker" onSubmit={handleOk}>
        {presets ? (
          <div className="c-date-picker__sidebar">
            <DatePresets presets={presets} onChange={choosePreset} selected={formValue?.presetId} />
          </div>
        ) : null}

        <div className="c-date-picker__main">
          <div className="c-date-picker__main-wrapper">
            <DateRangeWidget
              value={formValue}
              onChange={setFormValue}
              minDate={minDate}
              maxDate={maxDate}
              required={required}
              allowOpenEnded={allowOpenEnded}
            />
          </div>
        </div>

        <div className="c-date-picker__footer">
          <Button type="button" variant="ghost" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" variant="primary">
            Apply
          </Button>
        </div>
      </form>
    </ModalPopup>
  );
}
