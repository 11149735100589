import React, { ChangeEvent, useCallback } from "react";

import Icon from "../../../../../shared/components/design-system/Icon";
import TextInput from "../../../../../shared/components/design-system/TextInput";
import "./styles.scss";

type Variant = "top-bar" | "wide";

export interface SearchInputProps {
  autoFocus?: boolean;
  id?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  value: string;
  variant?: Variant;
}

export default function SearchInput({
  autoFocus,
  id,
  onChange,
  placeholder = "Search",
  value,
  variant = "wide",
}: SearchInputProps) {
  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value),
    [onChange],
  );

  const handleClickClear = useCallback(() => onChange(""), [onChange]);

  return (
    <TextInput
      autoFocus={autoFocus}
      className={`c-search-input c-search-input--${variant}`}
      id={id}
      onChange={handleInputChange}
      placeholder={placeholder}
      value={value}
      valuePrefix={<Icon icon="search" />}
      valueSuffix={
        value && (
          <button className="c-search-input__clear-button" type="button" onClick={handleClickClear}>
            <Icon icon="xmark" />
            <span className="is-sr-only">Clear search term</span>
          </button>
        )
      }
    />
  );
}
