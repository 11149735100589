import { useCallback, useState } from "react";

/**
 * Accepts an async function, and returns a boolean that's true while that funciton is executing.
 * Does not support concurrent execution (though it would be easy to add in).
 * The function must not change, if it isn't something from useCallback or similar, use useAsyncCallbackWithLoading instead.
 */
export default function useAsyncFunctionWithLoading<TReturn, TArgs extends Array<unknown>>(
  callback: (...args: TArgs) => Promise<TReturn>,
): [(...args: TArgs) => Promise<TReturn>, boolean] {
  const [loading, setLoading] = useState(false);
  const wrapped = useCallback(
    async (...args: TArgs) => {
      setLoading(true);
      const value = await callback(...args);
      setLoading(false);
      return value;
    },
    [callback],
  );
  return [wrapped, loading];
}

/** As useAsyncFunctionWithLoading, but includes useCallback so there's no need to use both */
export function useAsyncCallbackWithLoading<TReturn, TArgs extends Array<unknown>>(
  callback: (...args: TArgs) => Promise<TReturn>,
  deps: unknown[],
): [(...args: TArgs) => Promise<TReturn>, boolean] {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useAsyncFunctionWithLoading(useCallback(callback, deps));
}
