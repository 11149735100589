import { SearchOptions } from "minisearch";
import React, { FunctionComponent, ReactElement, useEffect } from "react";

import useSearch, { SearchableItem } from "../../../hooks/useSearch";

/*
It's possible this function will become annoyingly slow in the future. If so, we might have to debounce the updates so that the display only updates when the user doesn't press a key for (say) 300ms.

Functionality we might want to add in the future:
 • Ignore diacritics — allow é to match e, etc. Need to find a library for that.
 • Order by relevance — prioritise full-word matches, early matches in the searchable text, or consecutive or in-order word matches?
 • Support for phrases in quotes or negative matching with "-"?
 • Look for "close" matches if nothing matches properly.
*/

export function SearchableList<ItemType extends SearchableItem>({
  items,
  fields,
  searchText,
  alwaysShow,
  component,
  emptyComponent,
  emptyWithNoSearchTerm,
  onFilterUpdate,
  searchOptions,
}: {
  items: ItemType[];
  fields: Array<keyof ItemType>;
  searchText: string;
  alwaysShow?: string[];
  component: FunctionComponent<ItemType>;
  emptyComponent?: ReactElement;
  emptyWithNoSearchTerm?: boolean;
  keyboardNav?: boolean;
  onFilterUpdate?: (items: string[]) => void;
  searchOptions?: SearchOptions & { initialsMatch?: boolean };
}) {
  const filteredItems = useSearch<ItemType>({
    items,
    fields,
    searchText,
    alwaysShow,
    searchOptions,
    emptyWithNoSearchTerm,
  });
  useEffect(() => onFilterUpdate?.(filteredItems.map((item) => item.id)), [filteredItems, onFilterUpdate]);

  if (emptyWithNoSearchTerm && /^\s*$/.test(searchText)) {
    return null;
  }

  if (filteredItems.length === 0) {
    return emptyComponent ?? null;
  }

  return (
    <>
      {filteredItems.map((item) => (
        // component works better here as an inline function — if it's instantiated as <Component {...item} />
        // then you need to memoise the component function, which makes your code a bit timey-wimey, or else React
        // re-renders the whole thing every time because as far as it's concerned you've used a whole new class of
        // component it's never seen before.
        <React.Fragment key={item.id}>{component(item)}</React.Fragment>
      ))}
    </>
  );
}
