import { useMemo } from "react";

import { AnalyticsCode, FormCategory, QuestionType } from "../../../__generated__/graphql";
import { OrgDataQuestion } from "./useOrg/types";
import useOrgData from "./useOrgData";
import useSelf from "./useSelf";

export type StandardColumnType =
  | "key"
  | "form"
  | "createdAt"
  | "assignedTo"
  | "status"
  | "updatedAt"
  | "outcome"
  | "openTime"
  | "triageTime"
  | "needsAttentionSince";

export type ColumnType = StandardColumnType | "question";

export interface Column {
  id: string;
  type: ColumnType;
  question?: OrgDataQuestion;
  name: string;
  analyticsCode?: AnalyticsCode;
}

export const COLUMNS: Column[] = (
  [
    { type: "status", name: "Status", analyticsCode: AnalyticsCode.Status },
    { type: "key", name: "ID" },
    { type: "createdAt", name: "Reported On" },
    { type: "form", name: "Form", analyticsCode: AnalyticsCode.Form },
    { type: "assignedTo", name: "Assigned Advisors" },
    // This is where the highlighted questions are inserted
    { type: "outcome", name: "Outcome", analyticsCode: AnalyticsCode.Outcome },
    { type: "updatedAt", name: "Last Updated" },
    { type: "openTime", name: "Time Open" },
    { type: "triageTime", name: "Time To Triage" },
    // This is where the non-hightlighted questions are inserted
    // Lastly, the "unread" column is added separately because it's conditional on a feature gate and because it's last
  ] as Array<{ type: StandardColumnType; name: string }>
).map((col) => ({ ...col, id: col.type }));

// Questions of these types are bumped up into the "standard" metadata column section, above other questions.
// It's reversed so that allColumns.splice lower down doesn't have to keep track of where it's up to
export const HIGHLIGHTED_QUESTIONS: AnalyticsCode[] = [AnalyticsCode.Role].reverse();

/** Returns the questions the current user is allowed to work with in some context. Filters out hidden questions, filters by question type, and sorts by short title. */
export function useAllowedQuestions(allowedQuestionTypes?: QuestionType[]) {
  const { questions } = useOrgData();

  // Strip out any questions we don't want to allow, and sort the others by title
  return useMemo(
    () =>
      questions
        .filter(({ type }) => !allowedQuestionTypes || allowedQuestionTypes.includes(type))
        .filter(({ answersHidden }) => !answersHidden)
        .sort((a, b) => a.shortTitle.localeCompare(b.shortTitle)),
    [questions, allowedQuestionTypes],
  );
}

// Returns all report fields the current user is allowed to work with in some context. Includes questions, as well as things like report ID, status, time to triage, etc.
export default function useReportFields(allowedQuestionTypes?: QuestionType[]) {
  const originalQuestions = useAllowedQuestions(allowedQuestionTypes);
  const self = useSelf();

  return useMemo(() => {
    // Start with just the metadata columns
    const allColumns = [...COLUMNS];

    // Copy the questions array as we want to mutate it
    const questions = [...originalQuestions];

    // Inject the highlighted questions at position 5. Because we reversed the HIGHLIGHTED_QUESTIONS array earlier, we can keep injecting them at position 5 without having to keep track of a cursor.
    for (const code of HIGHLIGHTED_QUESTIONS) {
      allColumns.splice(
        5,
        0,
        ...questions
          .filter(
            (question) =>
              question.analyticsCode !== null && (question.analyticsCode as unknown as AnalyticsCode) === code,
          )
          .map(questionToColumn),
      );
    }

    // Add the remaining reporting-form questions
    allColumns.push(
      ...questions
        .filter(
          (question) =>
            !HIGHLIGHTED_QUESTIONS.includes(question.analyticsCode! as unknown as AnalyticsCode) &&
            question.category === FormCategory.Reporting,
        )
        .map(questionToColumn),
    );

    // Add a column for the "unread" badge
    if (self.org.featureGates.includes("twoWayMessaging")) {
      allColumns.push({ type: "needsAttentionSince", id: "needsAttentionSince", name: "Messages" });
    }

    return allColumns;
  }, [originalQuestions, self]);
}

function questionToColumn(question: OrgDataQuestion): Column {
  return {
    id: `question-${question.id}`,
    type: "question",
    question,
    name: question.shortTitle,
    analyticsCode: (question.analyticsCode ?? undefined) as AnalyticsCode | undefined,
  };
}
