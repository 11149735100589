import React from "react";

import {
  InDetailChartBlock,
  InDetailChartBlockOptions,
} from "../../../../../../../../shared/block-editor-data/chart-block-types";
import { DEFAULT_TITLE } from "../../../../../../../../shared/components/charts/ChartLegend";
import InDetailBarChart from "../../../../../../../../shared/components/charts/InDetailBarChart";
import InDetailTable from "../../../../../../../../shared/components/charts/InDetailTable";
import tableStyles from "../../../../../analytics/tables.module.scss";
import { registerNodeCheck } from "../../BlockContainer/Caption/is";
import { createCustomBlock } from "../../utils";
import editableBlockComponent from "../block";
import EditInDetailChartDialog from "./EditInDetailChartDialog";

const InDetailChartBlockComponent = editableBlockComponent(InDetailChartBlockChart, EditInDetailChartDialog, {
  chartType: "in-detail",
});

function InDetailChartBlockChart({ options }: { options: InDetailChartBlockOptions }) {
  if (options.chartType === "TABLE") {
    return (
      <div className={tableStyles.Container}>
        <InDetailTable data={options.data} options={options.options} className={tableStyles.Table} />
      </div>
    );
  }
  return (
    <InDetailBarChart
      data={options.data}
      options={options.options}
      legendTitle={DEFAULT_TITLE}
      showMultipleAnswerWarning={false}
    />
  );
}

const inDetailChartPlugin = createCustomBlock<InDetailChartBlock, InDetailChartBlockOptions>(
  "cs-analytics-chart--in-detail",
  InDetailChartBlockComponent,
);

export default inDetailChartPlugin;

registerNodeCheck(inDetailChartPlugin.$is);
