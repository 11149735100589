import { LexicalEditor } from "lexical";

import { Caption } from "../../../../../../../shared/block-editor-data/types";

export function $updateCaption(
  caption: Caption,
  id: string,
  captionEditor: null | LexicalEditor,
  updateImageNode: (imageNode: string) => void,
  fn: () => void,
): void {
  if (caption === null || captionEditor === null || id === caption.id) return;
  const editorState = captionEditor.parseEditorState(caption.json);
  captionEditor._headless = true;
  captionEditor.setEditorState(editorState);
  captionEditor.update(() => {
    captionEditor._pendingEditorState!._flushSync = true;
    fn();
  });
  captionEditor._headless = false;
  const newJSON = JSON.stringify(captionEditor.getEditorState());
  updateImageNode(newJSON);
}
