import React, { PropsWithChildren, ReactNode, useCallback } from "react";

import { Variant } from "../../../shared/components/design-system/Button";
import {
  SectionFooter,
  SectionHeader,
} from "../../../shared/components/design-system/component-groups/section-header-footer";
import useModal, { PropsWithAlertResolver } from "../../../shared/hooks/useModal";
import Button from "../components/design-system/Button";

function AlertAndChildren({
  children,
  title,
  okVariant = "primary",
  okCaption = "OK",
  cancelCaption,
  resolve,
  cancel,
}: PropsWithAlertResolver<
  boolean,
  PropsWithChildren<{
    title: string;
    okVariant?: Variant;
    okCaption?: string;
    cancelCaption?: string;
  }>
>) {
  const ok = useCallback(() => resolve(true), [resolve]);

  return (
    <>
      <SectionHeader title={title} />
      {children}
      <SectionFooter>
        {cancelCaption ? (
          <Button variant="ghost" onClick={cancel}>
            {cancelCaption}
          </Button>
        ) : null}
        <Button variant={okVariant || "primary"} onClick={ok}>
          {okCaption}
        </Button>
      </SectionFooter>
    </>
  );
}

export function useAlert() {
  const setAlertBox = useModal(AlertAndChildren, { size: "small", alert: true });
  return useCallback(
    async ({
      mixpanelName,
      ...props
    }: {
      title: string;
      okCaption: string;
      children: ReactNode;
      okVariant: Variant;
      mixpanelName?: string;
    }) => {
      await setAlertBox(props, mixpanelName);
    },
    [setAlertBox],
  );
}

export function useConfirm() {
  const setAlertBox = useModal(AlertAndChildren, { size: "small", alert: true });
  return useCallback(
    async ({
      mixpanelName,
      ...props
    }: {
      title: string;
      okCaption: string;
      okVariant: Variant;
      cancelCaption: string;
      children: ReactNode;
      mixpanelName?: string;
    }) => !!(await setAlertBox(props, mixpanelName)),
    [setAlertBox],
  );
}
