import React, { useCallback, useState } from "react";

import TextInput from "./base";
import "./styles.scss";

export interface PasswordInputProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  required?: boolean;
  className?: string;
  autoFocus?: boolean;
  hideValidation?: boolean;
  serverValidity?: string | null;
  applyPasswordRules?: boolean;
  mustMatch?: string;
}

export default function PasswordInput({
  autoFocus,
  label,
  onChange,
  value,
  className,
  required,
  hideValidation,
  serverValidity,
  applyPasswordRules,
  mustMatch,
}: PasswordInputProps) {
  const [showPassword, setShowPassword] = useState(false);

  const parse = useCallback(
    (value: string): { invalid: false; value: string } | { invalid: string } => {
      if (mustMatch) {
        return value === mustMatch ? { invalid: false, value } : { invalid: "Your passwords do not match" };
      }
      if (applyPasswordRules) {
        const invalid = awsPasswordValidation(value);
        return invalid ? { invalid } : { invalid: false, value };
      }
      return { invalid: false, value };
    },
    [applyPasswordRules, mustMatch],
  );

  return (
    <TextInput<string>
      label={label}
      value={value}
      onChange={onChange}
      type={{
        inputType: showPassword ? "text" : "password",
        toString: stringToString,
        parse,
      }}
      button={{
        label: showPassword ? "Hide password" : "Show password",
        icon: showPassword ? "eyeStrikethrough" : "eye",
        onClick: () => setShowPassword((show) => !show),
        isActive: showPassword,
      }}
      serverValidity={serverValidity}
      autoFocus={autoFocus}
      className={className}
      required={required}
      hideValidation={hideValidation}
    />
  );
}

function awsPasswordValidation(password: string) {
  if (!password) return ""; // The 'required' tag will catch this case
  if (password.length < 8) return "Your password must be at least eight characters.";
  if (!/^[\S]+.*[\S]+$/.test(password)) return "Your password may not start or end with spaces";
  return "";
}

function stringToString(value: string) {
  return value;
}
