import React, { PropsWithChildren, useEffect, useRef } from "react";

import { InternalLink } from "../../../../../shared/components/design-system/Button/Link";
import { ActionGroup } from "../../../../../shared/components/design-system/component-groups/ActionGroup";
import { Translatable, Translation } from "../../../../../shared/components/translation";
import styles from "./CombinedHeader.module.scss";

export interface Breadcrumb {
  label: string;
  url: string;
}

export default function CombinedHeader({
  title,
  breadcrumbs,
  visuallyHideTitle,
  loading,
  className,
  children,
}: PropsWithChildren<{
  title: Translatable;
  breadcrumbs?: Array<Breadcrumb | null>;
  visuallyHideTitle?: boolean;
  loading?: boolean;
  className?: string;
}>) {
  const h1 = useRef<HTMLHeadingElement>(null);

  // TODO: This really shouldn't focus on initial app load, but until we've done more work on restructuring the pages, that's probably not practical to fix
  useEffect(() => h1.current?.focus(), []);

  return (
    <header
      className={`
        ${styles.CombinedHeader}
        can-load ${loading ? "is-loading" : ""}
        ${className ?? ""}
        ${visuallyHideTitle ? styles.hiddenTitle : ""}
      `}
    >
      {breadcrumbs ? (
        <nav className={styles.Breadcrumbs}>
          <ul>
            {breadcrumbs
              .filter((breadcrumb): breadcrumb is Breadcrumb => !!breadcrumb)
              .map(({ url, label }, i) => (
                <li key={i}>
                  <InternalLink to={url} isQuiet>
                    {label}
                  </InternalLink>
                </li>
              ))}
          </ul>
        </nav>
      ) : null}

      <h1 className={`ds-h1 ds-m-0 ${styles.Title} ${visuallyHideTitle ? "is-sr-only" : ""}`} tabIndex={-1} ref={h1}>
        <Translation props={title} />
      </h1>

      {children ? <ActionGroup className={styles.Actions}>{children}</ActionGroup> : null}
    </header>
  );
}
