import format from "date-fns/format";
import sub from "date-fns/sub";
import { useCallback } from "react";

import { useUrlParams } from "../../../hooks/useUrlParams";
import { MONTHS } from "./MonthSelect";
import { MAX_YEAR, MIN_YEAR } from "./YearSelect";

export interface OverTimeYearSettings {
  month: string;
  year: string;
  comparisonYear: string | null;
  duration: string;
  setMonth: (month: string) => void;
  setYear: (year: string) => void;
  setComparisonYear: (year: string | null) => void;
  setDuration: (year: string) => void;
}

export default function useUrlYearSettings(): OverTimeYearSettings {
  const { getParam, setParam, setParams } = useUrlParams<"year" | "comparisonYear" | "yearStartMonth" | "duration">();

  const month = normaliseMonth(getParam("yearStartMonth"));
  const year = normaliseYear(getParam("year"), false)!;
  const comparisonYear = normaliseYear(getParam("comparisonYear"), true);
  const duration = getParam("duration") ?? "12";

  // Don't do any validation in this direction - simply trust that our frontend is giving us reasonable values.
  const setMonth = useCallback((month: string) => setParam("yearStartMonth", month), [setParam]);
  const setYear = useCallback(
    (year: string) =>
      setParams({
        year: year,
        // Reset the comparison year if it's the same as the "main" year
        comparisonYear: comparisonYear && parseInt(year, 10) === parseInt(comparisonYear, 10) ? null : comparisonYear,
      }),
    [comparisonYear, setParams],
  );
  const setComparisonYear = useCallback((year: string | null) => setParam("comparisonYear", year), [setParam]);
  const setDuration = useCallback((duration: string) => setParam("duration", duration), [setParam]);

  return {
    month,
    year,
    comparisonYear,
    duration,
    setMonth,
    setYear,
    setComparisonYear,
    setDuration,
  };
}

function normaliseMonth(param: string | null) {
  if (MONTHS.some(({ value }) => value === param)) return param!;
  return format(new Date(), "MM");
}

function normaliseYear(param: string | null, allowNull: boolean) {
  if (param) {
    // we treat empty strings as null because it's convenient
    const n = parseInt(param, 10);
    if (n >= MIN_YEAR && n <= MAX_YEAR) return n.toString();
  }
  if (allowNull) return null;
  return format(sub(new Date(), { years: 1 }), "yyyy");
}
