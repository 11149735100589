import React, { FormEvent, useCallback, useState } from "react";

import PasswordInput from "../../../../../shared/components/design-system/TextInput/PasswordInput";
import ButtonSet from "../../../../../shared/components/design-system/component-groups/ButtonSet";
import Button from "../../design-system/Button";

interface ForcePasswordChangeFormProps {
  onSubmit: (newPassword: string) => void;
}

export default function ForcePasswordChangeForm({ onSubmit: onSubmitProp }: ForcePasswordChangeFormProps) {
  const [newPassword, setNewPassword] = useState("");

  const onSubmit = useCallback(
    (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      onSubmitProp(newPassword);
    },
    [onSubmitProp, newPassword],
  );

  return (
    <>
      <h2 className="ds-h3">Password Change</h2>
      <p>Your password must be changed, please enter a new password.</p>

      <form className="ds-mt-7" onSubmit={onSubmit}>
        <PasswordInput
          value={newPassword}
          label="New password"
          onChange={setNewPassword}
          autoFocus
          required
          applyPasswordRules
        />

        <ButtonSet direction="vertical" className="ds-mt-7">
          <Button className="ds-mt-7" type="submit" size="large">
            Change password
          </Button>
        </ButtonSet>
      </form>
    </>
  );
}
