import mixpanel from "mixpanel-browser";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { AnalyticsCode, QuestionAnalyticsCode } from "../../../../../../../__generated__/graphql";
import {
  CustomGroupSet,
  DimensionGroupConfiguration,
  MergedOptionSet,
  OptionGroup,
} from "../../../../../../../shared/components/charts/types";
import useRefOf from "../../../../../../../shared/hooks/useRefOf";
import useOrgData from "../../../../../hooks/useOrgData";
import RadioButtons from "../../../../design-system/RadioGroup";
import { AnalyticsQueryResult, UseAnalyticsQueryResult } from "../../../useAnalyticsQuery";

export default function GroupingFormat({
  currentCode,
  currentOptions,
  setCurrentOptions,
  data,
}: {
  currentCode: AnalyticsCode;
  currentOptions: DimensionGroupConfiguration;
  setCurrentOptions: (options: DimensionGroupConfiguration) => void;
  data: UseAnalyticsQueryResult["data"];
}) {
  const { questions } = useOrgData();

  const [customGroups, setCustomGroups] = useState<CustomGroupSet | null>(null);
  useEffect(() => setCustomGroups(null), [currentCode]);

  const showFormOption = questions.some(
    (q) =>
      q.analyticsCode === (currentCode as unknown as QuestionAnalyticsCode) &&
      q.optionGroups &&
      q.optionGroups.length > 1,
  );

  const [showCustom, setShowCustom] = useState(currentOptions.basis === "CUSTOM");
  // Show the 'custom' tab whenever it becomes active (any way)
  useEffect(() => {
    if (currentOptions.basis === "CUSTOM") setShowCustom(true);
  }, [currentOptions.basis]);
  // If you switch tab, 'custom' only appears if it's selected
  const currentOptionsRef = useRefOf(currentOptions);
  useEffect(() => setShowCustom(currentOptionsRef.current.basis === "CUSTOM"), [currentCode, currentOptionsRef]);

  const bases = useMemo(() => {
    const bases: Array<{ id: "FORM" | "NONE" | "CUSTOM"; label: string }> = [{ id: "NONE", label: "None" }];
    if (showFormOption || currentOptions.basis === "FORM") {
      bases.unshift({ id: "FORM", label: "Form" });
    }
    if (showCustom) bases.push({ id: "CUSTOM", label: "Custom" });
    return bases;
  }, [currentOptions.basis, showCustom, showFormOption]);

  const setBasis = useCallback(
    (basis: "FORM" | "NONE" | "CUSTOM") => {
      mixpanel.track("Switched grouping basis", { basis: "NONE", code: currentCode });
      if (basis !== "CUSTOM") {
        setCurrentOptions({ basis });
        return;
      }
      if (customGroups) {
        setCurrentOptions({ basis, groups: customGroups });
        return;
      }
      if (!data) {
        setCurrentOptions({ basis, groups: [] });
        return;
      }
      const [chart] = data;
      const chartIds = new Set(chart.data.map(getId));
      const byLabel: Record<string, string[]> = {};
      for (const q of questions) {
        // @ts-ignore - these types do in fact overlap, like, a lot
        if (!q.optionGroups || q.analyticsCode !== currentCode) continue;
        for (const { options } of q.optionGroups) {
          for (const { id, value } of options) {
            if (!chartIds.has(id!)) continue;
            if (byLabel[value]) byLabel[value].push(id!);
            else byLabel[value] = [id!];
          }
        }
        if (q.deletedOptions) {
          for (const { id, value } of q.deletedOptions) {
            if (!chartIds.has(id)) continue;
            if (byLabel[value]) byLabel[value].push(id);
            else byLabel[value] = [id];
          }
        }
      }
      const groups: Array<OptionGroup | MergedOptionSet> = [];
      for (const label in byLabel) {
        groups.push({ label, ids: byLabel[label] });
      }
      mixpanel.track("Switched grouping basis", { basis: "CUSTOM", code: currentCode });
      setCurrentOptions({ basis: "CUSTOM", groups });
    },
    [currentCode, customGroups, data, questions, setCurrentOptions],
  );

  if (!showFormOption && !showCustom) return null;

  return (
    <RadioButtons
      label="Select a grouping format"
      variant="segmented"
      className="ds-my-5"
      value={currentOptions.basis}
      onChange={setBasis}
      options={bases}
    />
  );
}

function getId(row: AnalyticsQueryResult): string {
  return row.optionId ?? row.form?.id ?? row.outcome?.id ?? row.status ?? "unknown id";
}
