import { format, isSameDay, isBefore, isAfter } from "date-fns";
import React, { FunctionComponent, useMemo } from "react";

import { MonthDays, Day } from "../../../types";
import { getIsInRange, getMonthDays } from "../../../utils";
import CalendarDay from "./CalendarDay";
import "./styles.scss";

interface CalendarMonthProps {
  month: number;
  year: number;
  onClickDay?: (date: Date) => void;
  startDate: Date | null;
  endDate: Date | null;
  minDate?: Date;
  maxDate?: Date;
  handleDayMouseEnter: (day: Day) => void;
  handleDayMouseLeave: (day: Day) => void;
}

const CalendarMonth: FunctionComponent<CalendarMonthProps> = ({
  month,
  year,
  onClickDay,
  startDate,
  endDate,
  minDate,
  maxDate,
  handleDayMouseEnter,
  handleDayMouseLeave,
}) => {
  const monthStart = useMemo(() => new Date(year, month - 1), [month, year]);
  const monthEnd = new Date(year, month);

  const days: MonthDays = useMemo(() => getMonthDays(monthStart, minDate, maxDate), [maxDate, minDate, monthStart]);

  const title = format(monthStart, "MMMM yyyy");

  // Before we do the calculation for each day, work out if anything is anywhere near us. If it's not, we can skip the whole thing.
  const noHighlighting = !(startDate && endDate && isBefore(startDate, monthEnd) && isAfter(endDate, monthStart));
  // Also check whether the entire month is in the range, which saves us a lot of calculations
  const highlightEntireMonth = !!(
    startDate &&
    endDate &&
    isBefore(startDate, monthStart) &&
    isAfter(endDate, monthEnd)
  );

  const isDayInRange = (date: Date) => {
    if (noHighlighting) return false;
    if (highlightEntireMonth) return true;
    return getIsInRange(date, startDate, endDate);
  };

  const isDayStartDate = (date: Date) => {
    if (noHighlighting || highlightEntireMonth) return false;
    return isSameDay(date, startDate);
  };

  const isDayEndDate = (date: Date) => {
    if (noHighlighting || highlightEntireMonth) return false;
    return isSameDay(date, endDate);
  };

  return (
    <div id={`${year}-${month}`} className="c-calendar-month">
      <h3 className="c-calendar-month__title">{title}</h3>

      <div className="c-calendar-month__days">
        {days.map((day) => (
          <CalendarDay
            key={`${year}-${month}--${day.dateString}`}
            day={day}
            isInRange={isDayInRange(day.date)}
            isStartDate={isDayStartDate(day.date)}
            isEndDate={isDayEndDate(day.date)}
            onClick={onClickDay}
            onMouseEnter={handleDayMouseEnter}
            onMouseLeave={handleDayMouseLeave}
          />
        ))}
      </div>
    </div>
  );
};

export default CalendarMonth;
