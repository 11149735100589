import React, { PropsWithChildren, useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";

import Alert from "../../../../shared/components/design-system/Alert";
import useOrg from "../../hooks/useOrg";

/** Similar to OrgDataRoute, this sets the global switcher when you enter a site area and unsets it when you leave. This is less obviously useful here than it is for orgs, as there is no global site switcher, but it's useful to do it this way anyway because modals open outside the main dom tree and also need access to this stuff. On the off-chance we do want to open two site contexts on-screen at once, we can use a second provider to locally override the global setting. */
export function SiteDataRoute({ children }: PropsWithChildren<unknown>) {
  const { currentSite, setCurrentSite } = useOrg();
  const { siteId } = useParams<"siteId">();

  useEffect(() => {
    if (!siteId) return;
    setCurrentSite(siteId);
    return () => setCurrentSite(null);
  }, [setCurrentSite, siteId]);

  if (siteId && !currentSite) {
    return <Alert variant="danger" message="The site you are attempting to access does not exist." />;
  }

  return <>{children ?? <Outlet />}</>;
}

/** @deprecated No longer does anything really, but wraps the new way of doing this to match the old one, so no code changes are needed */
export default function useSiteData() {
  return useOrg().currentSite!;
}
