import { useMutation } from "@apollo/client";
import * as Sentry from "@sentry/browser";
// TODO: any styles needed really ought to be applied by the toast extras system but for now this is fine
import { isAfter } from "date-fns";
import gql from "graphql-tag";
import mixpanel from "mixpanel-browser";
import React, { useEffect } from "react";

import {
  MarkMarketingPromptAsReadMutation,
  MarkMarketingPromptAsReadMutationVariables,
} from "../../../../../__generated__/graphql";
import megaphone from "../../../../../dashboard/src/assets/images/megaphone.svg";
import Alert from "../../../../../shared/components/design-system/Alert";
import { useToast } from "../../../../../shared/components/design-system/Toaster/context";
import styles from "../../../../../shared/components/design-system/Toaster/styles.module.scss";
import useSelf from "../../../hooks/useSelf";

// These could be passed in as props but there's really no need, we only ever make one of these components.
const featureId = "planned-outage";
// Date format here is (year, month - 1, date):
const showUntil = new Date(2020, 7, 4);
// Configure the body of the alert by editing the component.

const MARK_MARKETING_PROMPT_AS_READ = gql`
  mutation markMarketingPromptAsRead($featureId: String!) {
    markFeatureAnnouncementAsRead(feature: $featureId) {
      id
      unreadFeatureAnnouncements
    }
  }
`;

export default function useExtraMarketingPrompt() {
  const self = useSelf();
  const toast = useToast();

  const [clearPrompt] = useMutation<MarkMarketingPromptAsReadMutation, MarkMarketingPromptAsReadMutationVariables>(
    MARK_MARKETING_PROMPT_AS_READ,
    { variables: { featureId } },
  );

  useEffect(() => {
    if (!self.unreadFeatureAnnouncements.includes(featureId) || isAfter(new Date(), showUntil)) return;
    const toastId = toast.showExtra(ExtraMarketingPrompt, {
      onClose: () => {
        clearPrompt().catch((error) => {
          console.error(error);
          Sentry.captureException(error);
        });
        toast.removeExtra(toastId);
        mixpanel.track("Dismissed the marketing prompt call to action", { featureId });
      },
    });
  }, [clearPrompt, self, toast]);
}

function ExtraMarketingPrompt({ onClose }: { onClose: () => void }) {
  return (
    <Alert
      className={styles.toastAlert}
      onClickClose={onClose}
      icon={<img src={megaphone} alt="" width={96} height={88} />}
      title="Planned Outage: 7:30am on 8th July"
    >
      Please note, there is a planned outage scheduled for 7:30am on 8th July during which time the front and back end
      of the system will be unavailable. This is anticipated to last no more than a few minutes.
    </Alert>
  );
}
