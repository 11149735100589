import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useLexicalNodeSelection } from "@lexical/react/useLexicalNodeSelection";
import { mergeRegister } from "@lexical/utils";
import {
  $getNodeByKey,
  $getSelection,
  $isNodeSelection,
  $isRangeSelection,
  CLICK_COMMAND,
  COMMAND_PRIORITY_LOW,
  EditorThemeClasses,
  FORMAT_TEXT_COMMAND,
  KEY_ARROW_DOWN_COMMAND,
  KEY_ARROW_UP_COMMAND,
  KEY_ENTER_COMMAND,
  LexicalEditor,
  NodeKey,
  TextFormatType,
  createEditor,
} from "lexical";
import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { Caption } from "../../../../../../../../shared/block-editor-data/types";
import { NestedContext } from "../../NestedContext";
import {
  $createSelectAll,
  NestedEditor,
  emptyNestedEditorJSON,
  focusNestedEditor,
  generateHTMLFromJSON,
  getCurrentDocument,
  getNestedElementId,
  isTargetOnPossibleUIControl,
} from "../../utils";
import { $updateCaption } from "../updateCaption";
import { $isCaptionedNode } from "./is";
import styles from "./styles.module.scss";

const CAPTION_PLACEHOLDER =
  '{"root":{"children":[{"children":[{"detail":0,"format":"","mode":"normal","style":"","text":"Add caption text here","type":"text","version":1}],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1}],"direction":"ltr","format":"","indent":0,"type":"root","version":1}} ';

export default function CaptionContainer({
  nodeKey,
  caption,
  theme,
}: PropsWithChildren<{
  nodeKey: NodeKey;
  caption: Caption;
  theme: EditorThemeClasses;
}>) {
  const editorStateJSON = caption?.json;

  const [editor] = useLexicalComposerContext();
  const [primarySelectedCaptionId, setPrimarySelectedCaptionId] = useState<null | string>(null);
  const [isSelected, setSelected, clearSelection] = useLexicalNodeSelection(nodeKey);
  const [isEditing, setIsEditing] = useState(false);
  const captionRef = useRef<string | null>(null);
  const { nestedEditorConfig } = useContext(NestedContext);
  const mouseDownRef = useRef(false);

  const captionEditor = useMemo<null | LexicalEditor>(() => {
    if (nestedEditorConfig === null) {
      return null;
    }
    const _captionEditor = createEditor({
      namespace: nestedEditorConfig.namespace,
      nodes: nestedEditorConfig.nodes,
      onError: (error) => nestedEditorConfig.onError(error, _captionEditor),
      theme: nestedEditorConfig.theme,
    });
    return _captionEditor;
  }, [nestedEditorConfig]);

  useEffect(() => {
    const captionElem = elementRef.current;

    if (isSelected && document.activeElement === document.body && captionElem !== null) {
      captionElem.focus();
    }
  }, [isSelected]);

  const elementRef = useRef<null | HTMLDivElement>(null);

  const updateNode = useCallback(
    (json: string) => {
      editor.update(() => {
        const node = $getNodeByKey(nodeKey);
        if (!$isCaptionedNode(node)) {
          throw new Error("Cannot update an invalid Node");
        }

        node.updateCaptionJSON(json);
      });
    },
    [editor, nodeKey],
  );

  const saveEditorToJSON = useCallback(() => {
    if (captionEditor !== null) {
      const json = JSON.stringify(captionEditor.getEditorState());
      updateNode(json);
    }
  }, [captionEditor, updateNode]);

  const modifySelectedCaption = useCallback((id: string) => {
    captionRef.current = id;
    setPrimarySelectedCaptionId(id);
    focusNestedEditor(elementRef.current!, id);
  }, []);

  useEffect(() => {
    const element = elementRef.current;
    if (element === null) {
      return;
    }
    const doc = getCurrentDocument(editor);

    const handlePointerDown = (event: PointerEvent) => {
      const possibleId = getNestedElementId(event.target as HTMLElement);

      if (possibleId !== null && editor.isEditable() && element.contains(event.target as HTMLElement)) {
        setSelected(false);

        mouseDownRef.current = true;
        if (primarySelectedCaptionId !== possibleId) {
          if (isEditing) {
            saveEditorToJSON();
          }
          setPrimarySelectedCaptionId(possibleId);
          setIsEditing(false);
          captionRef.current = possibleId;
        } else {
          captionRef.current = null;
        }
      } else if (primarySelectedCaptionId !== null && !isTargetOnPossibleUIControl(event.target as HTMLElement)) {
        setSelected(false);
        mouseDownRef.current = false;

        if (isEditing) {
          saveEditorToJSON();
        }
        setPrimarySelectedCaptionId(null);

        setIsEditing(false);
        captionRef.current = null;
      }
    };
    const handlePointerMove = (event: PointerEvent) => {
      if (isEditing || !mouseDownRef.current || primarySelectedCaptionId === null) {
        return;
      }
      const possibleId = getNestedElementId(event.target as HTMLElement);
      if (possibleId !== null && possibleId !== captionRef.current) {
        element.style.userSelect = "none";

        captionRef.current = possibleId;
      }
    };

    doc.addEventListener("pointerdown", handlePointerDown);
    doc.addEventListener("pointermove", handlePointerMove);

    return () => {
      doc.removeEventListener("pointerdown", handlePointerDown);
      doc.removeEventListener("pointermove", handlePointerMove);
    };
  }, [editor, isEditing, saveEditorToJSON, updateNode, setSelected, primarySelectedCaptionId, nodeKey]);

  useEffect(() => {
    if (!isEditing && primarySelectedCaptionId !== null) {
      const doc = getCurrentDocument(editor);

      const loadContentIntoCaption = (cap: Caption | null) => {
        if (cap !== null && captionEditor !== null) {
          const editorStateJSON = cap.json;
          const editorState = captionEditor.parseEditorState(editorStateJSON);

          captionEditor.setEditorState(editorState);
        }
      };

      const handleClick = (event: MouseEvent) => {
        const possibleId = getNestedElementId(event.target as HTMLElement);
        if (possibleId === primarySelectedCaptionId && editor.isEditable()) {
          loadContentIntoCaption(caption);
          setIsEditing(true);
        }
      };

      const handleKeyDown = (event: KeyboardEvent) => {
        // Ignore arrow up and down keys so that people are forced to click out of the nested editor when they've finished editing the caption
        const keyCode = event.keyCode;
        if (keyCode === 38 || keyCode === 40 || !editor.isEditable()) {
          return;
        }
        if (keyCode === 13) {
          event.preventDefault();
        }
        if (event.metaKey || event.ctrlKey || event.altKey) {
          return;
        }

        loadContentIntoCaption(caption);
        setIsEditing(true);
      };

      doc.addEventListener("click", handleClick);
      doc.addEventListener("keydown", handleKeyDown);

      return () => {
        doc.removeEventListener("click", handleClick);
        doc.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [editor, isEditing, captionEditor, caption, primarySelectedCaptionId]);

  useEffect(() => {
    const element = elementRef.current;
    if (element === null) {
      return;
    }
    return mergeRegister(
      editor.registerCommand(CLICK_COMMAND, (payload) => $isNodeSelection($getSelection()), COMMAND_PRIORITY_LOW),

      editor.registerCommand<TextFormatType>(
        FORMAT_TEXT_COMMAND,
        (payload) => {
          if (primarySelectedCaptionId === null || isEditing) {
            return false;
          }
          $updateCaption(caption, primarySelectedCaptionId, captionEditor, updateNode, () =>
            $createSelectAll().formatText(payload),
          );
          return true;
        },
        COMMAND_PRIORITY_LOW,
      ),

      editor.registerCommand<KeyboardEvent>(
        KEY_ENTER_COMMAND,
        (event, targetEditor) => {
          const selection = $getSelection();
          if (
            primarySelectedCaptionId === null &&
            !isEditing &&
            $isNodeSelection(selection) &&
            selection.has(nodeKey) &&
            selection.getNodes().length === 1 &&
            targetEditor === editor
          ) {
            const firstCaptionId = caption.id;
            setPrimarySelectedCaptionId(firstCaptionId);
            focusNestedEditor(element, firstCaptionId);
            event.preventDefault();
            event.stopPropagation();
            clearSelection();
            return true;
          }
          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand<KeyboardEvent>(
        KEY_ARROW_UP_COMMAND,
        (event, targetEditor) => {
          const selection = $getSelection();
          if (!isEditing && selection === null) {
            const extend = event.shiftKey;
            const captionId = extend ? captionRef.current || primarySelectedCaptionId : primarySelectedCaptionId;
            if (captionId !== null) {
              modifySelectedCaption(captionId);
              return true;
            }
          }
          if (!$isRangeSelection(selection) || targetEditor !== captionEditor) {
            return false;
          }
          if (
            selection.isCollapsed() &&
            selection.anchor.getNode().getTopLevelElementOrThrow().getPreviousSibling() === null
          ) {
            event.preventDefault();
            return true;
          }
          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand<KeyboardEvent>(
        KEY_ARROW_DOWN_COMMAND,
        (event, targetEditor) => {
          const selection = $getSelection();
          if (!isEditing && selection === null) {
            const extend = event.shiftKey;
            const captionId = extend ? captionRef.current || primarySelectedCaptionId : primarySelectedCaptionId;
            if (captionId !== null) {
              modifySelectedCaption(captionId);
              return true;
            }
          }
          if (!$isRangeSelection(selection) || targetEditor !== captionEditor) {
            return false;
          }
          if (
            selection.isCollapsed() &&
            selection.anchor.getNode().getTopLevelElementOrThrow().getNextSibling() === null
          ) {
            event.preventDefault();
            return true;
          }
          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
    );
  }, [
    caption,
    captionEditor,
    clearSelection,
    editor,
    isEditing,
    modifySelectedCaption,
    nodeKey,
    primarySelectedCaptionId,
    saveEditorToJSON,
    setSelected,
    updateNode,
  ]);

  if (captionEditor === null) {
    return null;
  }

  return (
    <div data-id={caption.id} className={theme.caption} tabIndex={-1} ref={elementRef}>
      {isEditing && primarySelectedCaptionId === caption.id ? (
        <NestedEditor nestedEditor={captionEditor} />
      ) : caption.json === emptyNestedEditorJSON ? (
        <>
          <div
            className={`${styles.Caption} u-hint-text`}
            dangerouslySetInnerHTML={{
              __html: generateHTMLFromJSON(CAPTION_PLACEHOLDER, captionEditor),
            }}
          />
        </>
      ) : (
        <>
          <div
            className={styles.Caption}
            dangerouslySetInnerHTML={{
              __html: generateHTMLFromJSON(editorStateJSON, captionEditor),
            }}
          />
        </>
      )}
    </div>
  );
}
