import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import { COMMAND_PRIORITY_EDITOR, createCommand, LexicalCommand } from "lexical";
import { useEffect } from "react";

import { HtmlSnippet } from "../../../../../../../shared/block-editor-data/types";
import $insertBlockNode from "../block-nodes/insert-block-node";
import { $createHtmlSnippetNode, HtmlSnippetNode } from "./HtmlSnippetNode";

export type InsertHtmlSnippetPayload = Readonly<HtmlSnippet>;

export const INSERT_HTML_SNIPPET_COMMAND: LexicalCommand<InsertHtmlSnippetPayload> = createCommand();

export default function HtmlSnippetPlugin(): JSX.Element | null {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    if (!editor.hasNodes([HtmlSnippetNode])) {
      throw new Error("HtmlSnippetPlugin: HtmlSnippetNode not registered on editor");
    }
    return mergeRegister(
      editor.registerCommand<InsertHtmlSnippetPayload>(
        INSERT_HTML_SNIPPET_COMMAND,
        (payload) => {
          $insertBlockNode($createHtmlSnippetNode(payload));
          return true;
        },
        COMMAND_PRIORITY_EDITOR,
      ),
    );
  }, [editor]);

  return null;
}
