import { cloneDeep } from "lodash";
import { useCallback, useRef } from "react";

import { useConfirm } from "./useAlert";
import useBlocker from "./useBlocker";

const prompt = {
  title: "Discard unsaved changes?",
  children: "If you leave this page all unsaved changes will be lost.",
  okCaption: "Discard",
  okVariant: "primary",
  cancelCaption: "Keep editing",
  mixpanelName: "Leave page with unsaved changes",
} as const;

export default function useWarnAboutUnsavedChanges<T>(current: T) {
  const confirm = useConfirm();

  // Store this in a ref rather than in state so that updates apply instantly. We aren't re-rendering anything so there's no need to wait until the next invocation, and often we're calling markClean precisely because the very next line is a redirect that we want to unblock so the side effect would be extremely annoying to handle every time.
  const cleanValue = useRef(cloneDeep(current));
  const markClean = useCallback<() => void>(() => (cleanValue.current = cloneDeep(current)), [current]);

  const isDirty = !isEqual(cleanValue.current, current);

  if (isDirty) console.log(cleanValue.current, current);

  useBlocker(async ({ retry }) => {
    // this is duplicated to work around a case of navigating immediately after mark clean
    // see discussion on https://gitlab.com/culture-shift/report-support/-/merge_requests/993
    if (isEqual(cleanValue.current, current) || (await confirm(prompt))) retry();
  }, isDirty);

  return { markClean, isDirty };
}

export function isEqual<T>(a: T, b: T, path = ""): boolean {
  // If they're reference equal there's no need to go deeper.
  if (a === b) return true;
  if (Array.isArray(a)) {
    if (!Array.isArray(b) || b.length !== a.length) {
      console.log("Unsaved changes:", a, "changed to", b, "at", path);
      return false;
    }
    for (let i = 0; i < a.length; ++i) if (!isEqual(a[i], b[i], `${path}[${i}]`)) return false;
    return true;
  }
  if (Array.isArray(b)) {
    console.log("Unsaved changes:", a, "changed to", b, "at", path);
    return false;
  }
  if (a && typeof a === "object") {
    if (!b || typeof b !== "object") {
      console.log("Unsaved changes:", a, "changed to", b, "at", path);
      return false;
    }
    for (const key in { ...a, ...b }) if (!isEqual(a[key], b[key], `${path}.${key}`)) return false;
    return true;
  }
  // Normal triple-equals here would say NaN != NaN which isn't what we want here
  if (!Object.is(a, b)) {
    console.log("Unsaved changes:", a, "changed to", b, "at", path);
    return false;
  }
  return true;
}
