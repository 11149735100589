import mixpanel from "mixpanel-browser";
import { useCallback, useMemo, useState } from "react";

interface Options {
  mixpanelMessage?: string;
}

export default function useStateWithLocalStorage(key: string, defaultValue: string, { mixpanelMessage }: Options) {
  const [value, setValue] = useState(localStorage.getItem(key) ?? defaultValue);

  const updateValue = useCallback(
    (value: string) => {
      if (mixpanelMessage) mixpanel.track(mixpanelMessage, { value });
      localStorage.setItem(key, value);
      setValue(value);
    },
    [key, mixpanelMessage],
  );

  return [value, updateValue] as const;
}

export function useJsonWithLocalStorage<T>(key: string, defaultValue: T, options: Options) {
  const [stringValue, setValue] = useStateWithLocalStorage(key, JSON.stringify(defaultValue), options);

  const updateValue = useCallback(
    (value: T) => {
      setValue(JSON.stringify(value));
    },
    [setValue],
  );

  // this handles the case where your schema changes, new defaults get correctly set, but we don't
  // need to recompute it if the defaults change for any reason
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const value = useMemo<T>(() => ({ ...defaultValue, ...JSON.parse(stringValue) }), [stringValue]);

  return [value, updateValue] as const;
}
