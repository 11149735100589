import { FocusRing } from "@react-aria/focus";
import classNames from "classnames";
import React, { FunctionComponent, useCallback } from "react";

import Select from "../../../Select";
import { DateRangePreset } from "../../types";
import "./styles.scss";

interface DatePresetsProps {
  selected?: string;
  onChange: (preset: DateRangePreset) => void;
  presets: DateRangePreset[];
}

const DatePresets: FunctionComponent<DatePresetsProps> = ({ selected, onChange, presets }) => {
  const buttonClassNames = (value: string) =>
    classNames({
      "c-date-presets__button": true,
      "is-selected": value === selected,
    });

  const onChangeDropdown = useCallback(
    (presetValue: string) => {
      onChange(presets.find(({ presetId }) => presetValue === presetId) ?? { label: "All time", presetId: "ALL_TIME" });
    },
    [onChange, presets],
  );

  return (
    <div className="c-date-presets">
      <Select
        className="c-date-presets__dropdown"
        label="Select date range"
        hideLabel
        value={selected}
        onChange={onChangeDropdown}
        size="medium"
      >
        <option value="">Custom</option>
        {presets.map((preset) => (
          <option key={preset.presetId} value={preset.presetId}>
            {preset.label}
          </option>
        ))}
      </Select>
      {presets.map((preset) => (
        <FocusRing key={preset.presetId} focusRingClass="has-keyboard-focus">
          <button type="button" className={buttonClassNames(preset.presetId)} onClick={() => onChange(preset)}>
            {preset.label}
          </button>
        </FocusRing>
      ))}
    </div>
  );
};

export default DatePresets;
