import React, { FunctionComponent } from "react";

import { FormCategory } from "../../../../../__generated__/graphql";
import useOrgData from "../../../hooks/useOrgData";
import Checkbox from "../Checkbox";
import { filterSearchOptions } from "../FilterPanel";
import { SearchableList } from "../SearchableList";

const FormPicker: FunctionComponent<{
  selection: string[];
  optionSearch?: string;
  onChange: (selection: string[]) => void;
  category?: FormCategory;
}> = ({ optionSearch, selection, category, onChange }) => {
  const { sites } = useOrgData();
  const toggleId = (id: string) => () => {
    if (selection.includes(id)) {
      onChange(selection.filter((selectionId) => id !== selectionId));
    } else {
      onChange([...selection, id]);
    }
  };

  const sitesWithForms = sites
    .map((site) => ({ ...site, forms: site.forms.filter((form) => !category || form.category === category) }))
    .filter((site) => site.forms.length > 0);

  return (
    <div className="option-chooser">
      <SearchableList
        items={sitesWithForms.map((site) => ({
          id: site.id,
          site: site,
          forms: site.forms,
          matchText: [...site.forms.map(({ name }) => name), ...site.forms.map(({ title }) => title)].join(" "),
        }))}
        fields={["matchText"]}
        searchText={optionSearch ?? ""}
        component={({ id, site, forms }) => (
          <div className="option-chooser__group" key={id}>
            {sitesWithForms.length > 1 ? <h4 className="option-chooser__group-heading">{site.name}</h4> : null}
            <div className="option-chooser__checkboxes">
              <SearchableList
                items={forms.map(({ id, name, title }) => ({ id, name, title, matchText: `Form ${name} ${title}` }))}
                fields={["matchText"]}
                searchText={optionSearch ?? ""}
                component={({ id, name }) => (
                  <Checkbox key={id} onChange={toggleId(id)} checked={selection.includes(id)} label={name} />
                )}
              />
            </div>
          </div>
        )}
        searchOptions={filterSearchOptions}
      />
    </div>
  );
};

export default FormPicker;
