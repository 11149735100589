import classNames from "classnames";
import React, { FunctionComponent, PropsWithChildren, RefObject, useEffect, useState } from "react";

import Popup, { PopupAxisPosition, PopupPosition } from "../Popup";
import "./styles.scss";

type TooltipPosition = "top" | "right" | "bottom" | "left";

export type TooltipProps = PropsWithChildren<{
  anchorRef: RefObject<HTMLElement>;
  position?: "top" | "right" | "bottom" | "left";
  width?: string;
  className?: string;
}>;

const POSITIONS: Record<TooltipPosition, PopupPosition[]> = {
  right: [{ x: PopupAxisPosition.After, y: PopupAxisPosition.Centred }],
  bottom: [{ x: PopupAxisPosition.Centred, y: PopupAxisPosition.After }],
  top: [{ x: PopupAxisPosition.Centred, y: PopupAxisPosition.Before }],
  left: [{ x: PopupAxisPosition.Before, y: PopupAxisPosition.Centred }],
};

const Tooltip: FunctionComponent<TooltipProps> = ({ children, position, width, className, anchorRef }) => {
  const [shown, setShown] = useState(false);

  // We can't directly bind events to the target element so rather than pass a bunch of callbacks all around, listen on the window object for bubbling versions of the events.
  useEffect(() => {
    const handler = (e: MouseEvent | FocusEvent) => {
      // Particularly for mouse-over events, we might be hovering a child of the target and we want to catch that too
      for (let el: HTMLElement | null = e.target as HTMLElement; el; el = el.parentElement) {
        if (el === anchorRef.current) {
          setShown(true);
          return;
        }
      }
      setShown(false);
    };
    window.addEventListener("mouseover", handler);
    window.addEventListener("focusin", handler);
    return () => {
      window.removeEventListener("mouseover", handler);
      window.removeEventListener("focusin", handler);
    };
  }, [anchorRef]);

  // Use non-bubbling events here as we only want to trigger these if the mouse/focus leaves the whole app
  useEffect(() => {
    const hideHandler = () => setShown(false);
    window.addEventListener("mouseleave", hideHandler);
    window.addEventListener("blur", hideHandler);
    return () => {
      window.removeEventListener("mouseleave", hideHandler);
      window.removeEventListener("blur", hideHandler);
    };
  }, []);

  const classNameObj = classNames({
    "ds-tooltip": true,
    "is-visible": shown,
  });

  const positions = position ? POSITIONS[position] : Object.values(POSITIONS).flat();

  return (
    <Popup shown={shown} source={anchorRef} positions={positions}>
      <div className={`${classNameObj} ${className}`} aria-hidden={!shown} style={{ width }}>
        {children}
      </div>
    </Popup>
  );
};

export default Tooltip;
