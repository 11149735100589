import { useCallback, useEffect, useMemo, useState } from "react";

import { AnalyticsCode } from "../../../../../../../../__generated__/graphql";
import {
  DimensionGroupConfiguration,
  InDetailSplitDimension,
} from "../../../../../../../../shared/components/charts/types";
import { OrgDataQuestion } from "../../../../../../hooks/useOrg/types";
import { SplitSettings } from "../../../../../analytics/AnalyticsInDetailPage/useUrlSplitDimension";
import { defaultGroups } from "../../../../../analytics/split";

export default function useStateSplitDimension(
  defaultValue: InDetailSplitDimension | null,
  optional: boolean,
  allQuestions: OrgDataQuestion[],
) {
  const [analyticsCode, setAnalyticsCode] = useState<AnalyticsCode | null>(defaultValue?.analyticsCode ?? null);
  const [questionId, setQuestionId] = useState<string | null>(defaultValue?.questionId ?? null);
  const [groups, setGroups] = useState<DimensionGroupConfiguration>(defaultValue?.groups ?? { basis: "NONE" });

  // Reset the grouping if the question selection changes
  useEffect(() => setGroups(() => defaultGroups(analyticsCode, allQuestions)), [analyticsCode, allQuestions]);

  const clear = useCallback(() => {
    if (!optional) throw new Error("Attempted to clear an optional dimension");
    setAnalyticsCode(null);
    setQuestionId(null);
  }, [optional]);

  return useMemo<SplitSettings>(
    () => ({
      optional,
      analyticsCode,
      setAnalyticsCode: (analyticsCode: AnalyticsCode) => {
        setAnalyticsCode(analyticsCode);
        setQuestionId(null);
      },
      questionId,
      setQuestionId: (questionId: string) => {
        setQuestionId(questionId);
        setAnalyticsCode(null);
      },
      groups,
      setGroups,
      clear,
    }),
    [analyticsCode, clear, groups, optional, questionId],
  );
}
