import { ChangeEvent, Dispatch, SetStateAction, useCallback, useState } from "react";

/** @deprecated — this should be handled by input components */
export default function useStateWithInputChange<T>(
  defaultValue: T,
): [
  string | T,
  (ev: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void,
  Dispatch<SetStateAction<string | T>>,
] {
  const [value, setValue] = useState<string | T>(defaultValue);

  const changeValue = useCallback(
    (ev: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
      setValue(ev.currentTarget.value);
    },
    [setValue],
  );

  return [value, changeValue, setValue];
}

export function useStateWithCheckboxChange<T>(
  defaultValue: T,
): [boolean | T, (ev: ChangeEvent<HTMLInputElement>) => void] {
  const [value, setValue] = useState<boolean | T>(defaultValue);

  const changeValue = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      setValue(ev.currentTarget.checked);
    },
    [setValue],
  );

  return [value, changeValue];
}
