import { useCallback, useState } from "react";

import { InDetailData } from "../../../../../../shared/components/charts/types";

export function useEditLabel(
  categoriesData: InDetailData,
): [InDetailData, (index: number, value: string) => void, (index: number, value: string) => void] {
  const [labelsData, setLabels] = useState<InDetailData>(categoriesData);

  const onLabelInputChange = useCallback(
    (index: number, value: string) => {
      const categories = [...labelsData.categories];
      const row = { ...categories[index] };
      row.label = value;
      categories[index] = row;
      setLabels({ ...labelsData, categories });
    },
    [labelsData],
  );
  const onSplitInputChange = useCallback(
    (index: number, value: string) => {
      const splits = [...labelsData.splits!];
      const row = { ...splits[index] };
      row.label = value;
      splits[index] = row;
      setLabels({ ...labelsData, splits });
    },
    [labelsData],
  );

  return [labelsData, onLabelInputChange, onSplitInputChange];
}
