import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { DateRange, QuestionSearch, ReportSearch, ReportStatus } from "../../../../__generated__/graphql";
import useDeepEquality from "../../../../shared/hooks/useDeepEquality";
import useOrgData from "../useOrgData";
import { UrlParamUpdates, useUrlParams } from "../useUrlParams";

interface Parameters {
  // The filter to apply when nothing else is specified. Anything in this won't be included in the URL, so it's useful for things like date range or status tabs that are handled in other ways
  defaults: ReportSearch;
  // URL paramters that will be deleted when navigating to a new page. Useful for resetting the reports list page number when the filter changes
  paramsToClearOnWrite?: string[];
  // Filters you can't use — useful for hiding the date range filter in the analytics view where it's handled elsewhere
  forbiddenFilters?: ReportSearchBools;
  // Normally if you call setFilter (see below) the URL will change to have the new filter in the search parameter. Sometimes you also want to do other things such as switch to the "all statuses" tab when the filter changes. This lets you do that. The parameters are the incoming filter and a function to turn that filter into a query string starting in "?". This allows you to modify the filter as well as change the path.
  customUrlGenerator?: (filter: ReportSearch, linkWithParams: (filter: ReportSearch) => string) => string;
}

export interface FilterSettings {
  // The filter we're currently searching by
  filter: ReportSearch;
  // A function to update the filter
  setFilter: (filter: ReportSearch) => void;
  // A function to reset the filter to its default value
  clearFilters: () => void;
  // Objects passed in on the options:
  forbiddenFilters: ReportSearchBools;
  defaults: ReportSearch;
}

export interface ReportSearchBools {
  createdAt: boolean;
  updatedAt: boolean;
  status: boolean;
  assignedTo: boolean;
  outcome: boolean;
  form: boolean;
  questions: false | Record<string, boolean>;
  excludeSpam: boolean;
}

const NO_REPORT_SEARCH: ReportSearchBools = {
  createdAt: false,
  updatedAt: false,
  status: false,
  assignedTo: false,
  form: false,
  outcome: false,
  questions: false,
  excludeSpam: false,
};

// TODO: create a useFilterFromState version of this, or whatever ends up making sense. But it should return the same context object so it's a drop-in replacement as far as downstream components can tell.

export default function useFilterFromUrlParams({ customUrlGenerator, ...options }: Parameters) {
  // useUrlParams doesn't need a type argument here as the params can take any string value (since we don't know all the question IDs yet)
  const { params, setParams, linkWithParams } = useUrlParams();
  const { questions } = useOrgData();
  const defaults = useDeepEquality(options.defaults);
  const paramsToClearOnWrite = useDeepEquality(options.paramsToClearOnWrite);
  const forbiddenFilters = useDeepEquality(options.forbiddenFilters) ?? NO_REPORT_SEARCH;
  const navigate = useNavigate();

  const filter = useMemo(() => {
    const excludeSpam = params.get("exclude-spam");

    const questionParams: QuestionSearch[] = [];
    for (const key of new Set(params.keys())) {
      if (key.startsWith("question-")) {
        const questionId = key.substring(9);
        const question = questions.find((question) => question.id === questionId);
        if (question && !question.answersHidden) {
          // If an option text gets passed on the URL then parse it, just in case.
          if (question.optionGroups) {
            const questionParam: QuestionSearch = { questionId: question.id, value: [] };
            for (const param of params.getAll(key)) {
              groupLoop: for (const group of question.optionGroups) {
                if (group.id === param || group.groupName === param) {
                  questionParam.value.push(group.id!);
                  break;
                }
                for (const option of group.options) {
                  if (option.id === param || option.value === param) {
                    questionParam.value.push(option.id ?? option.value);
                    break groupLoop;
                  }
                }
              }
              for (const group of question.deletedOptionGroups ?? []) {
                if (group.id === param || group.groupName === param) {
                  questionParam.value.push(group.id!);
                  break;
                }
              }
              for (const option of question.deletedOptions ?? []) {
                if (option.id === param || option.value === param) {
                  questionParam.value.push(option.id ?? option.value);
                  break;
                }
              }
            }
            if (questionParam.value.length > 0) questionParams.push(questionParam);
          }
        }
      }
    }

    return {
      updatedAt: dateRangeFromUrlParam(params.get("updated-at"), defaults.updatedAt),
      createdAt: dateRangeFromUrlParam(params.get("created-at"), defaults.createdAt),
      status: arrayFromUrlParam(
        params.getAll("status").filter((param) => (Object.values(ReportStatus) as string[]).includes(param)),
        defaults.status,
      ),
      assignedTo: arrayFromUrlParam(
        [...params.getAll("assigned-to"), ...params.getAll("assigned-to-team")],
        defaults.assignedTo,
      ),
      form: arrayFromUrlParam(params.getAll("form"), defaults.form),
      outcome: arrayWithNullsFromUrlParam(params.getAll("outcome"), defaults.outcome),
      questions: questionParams.length === 0 ? defaults.questions : questionParams,
      excludeSpam: excludeSpam ? excludeSpam === "true" : defaults.excludeSpam,
    } as ReportSearch;
  }, [params, questions, defaults]);

  const getUpdates = useCallback(
    (filter: ReportSearch) => paramUpdatesFromFilter(filter, defaults, params, paramsToClearOnWrite),
    [params, defaults, paramsToClearOnWrite],
  );

  const setFilter = useCallback(
    (filter: ReportSearch) => {
      if (customUrlGenerator) navigate(customUrlGenerator(filter, (filter) => linkWithParams(getUpdates(filter))));
      else setParams(getUpdates(filter));
    },
    [getUpdates, setParams, linkWithParams, customUrlGenerator, navigate],
  );

  const clearFilters = useCallback(() => setFilter(defaults), [setFilter, defaults]);

  return useMemo(
    () => ({ filter, defaults, setFilter, clearFilters, forbiddenFilters }),
    [clearFilters, defaults, filter, forbiddenFilters, setFilter],
  );
}

function dateRangeFromUrlParam(param: string | null, def?: DateRange | null): DateRange | null {
  if (param === null) return def ?? null;
  if (!param) return null;
  const [start, end] = param.split("/");
  return {
    start: start || null,
    end: end || null,
  };
}

function arrayFromUrlParam(param: string[], def?: string[] | null) {
  if (param.length === 0) return def ?? null;
  if (param.length === 1 && param[0] === "") return null;
  return param;
}

function arrayWithNullsFromUrlParam(param: string[], def?: (string | null)[] | null) {
  if (param.length === 0) return def ?? null;
  if (param.length === 1 && param[0] === "") return null;
  return param.map((item) => (item === "none" ? null : item));
}

function dateRangeToParam(dateRange?: DateRange | null, def?: DateRange | null) {
  if (dateRangeIsEqualEnough(dateRange, def)) return null;
  const start = dateRange?.start ?? "";
  const end = dateRange?.end ?? "";
  if (!start && !end) return "";
  return `${start}/${end}`;
}

function arrayToParam<T extends string | null>(actual?: T[] | null, def?: T[] | null): string[] | null {
  if (!actual) return def ? [""] : null;
  if (arrayIsEqualEnough(def, actual)) return null;
  return coalesceStringArray(actual);
}

function coalesceStringArray(value: Array<string | null>) {
  return value?.map((item) => item ?? "none") ?? null;
}

export function dateRangeIsEqualEnough(a?: DateRange | null, b?: DateRange | null) {
  const aStart = a?.start ?? null;
  const aEnd = a?.end ?? null;
  const bStart = b?.start ?? null;
  const bEnd = b?.end ?? null;
  return aStart === bStart && aEnd === bEnd;
}

export function arrayIsEqualEnough<T>(a?: T[] | null, b?: T[] | null) {
  if (!a) return !b;
  if (!b) return false;
  if (a.length !== b.length) return false;
  for (const item of a) if (!b.includes(item)) return false;
  return true;
}

export function paramUpdatesFromFilter(
  filter: ReportSearch,
  updateFrom: ReportSearch = {},
  params?: URLSearchParams,
  paramsToClearOnWrite: string[] = [],
) {
  const updates: UrlParamUpdates<string> = {
    "created-at": dateRangeToParam(filter.createdAt, updateFrom.createdAt),
    "updated-at": dateRangeToParam(filter.updatedAt, updateFrom.updatedAt),
    status: arrayToParam(filter.status, updateFrom.status),
    "assigned-to": arrayToParam(filter.assignedTo, updateFrom.assignedTo),
    form: arrayToParam(filter.form, updateFrom.form),
    outcome: arrayToParam(filter.outcome, updateFrom.outcome),
    "assigned-to-team": null,
  };

  const excludeSpam = !!filter.excludeSpam;
  if (excludeSpam === !!updateFrom.excludeSpam) {
    updates["exclude-spam"] = null;
  } else {
    updates["exclude-spam"] = excludeSpam.toString();
  }

  for (const param of paramsToClearOnWrite ?? []) {
    updates[param] = null;
  }

  if (params) {
    for (const key of params.keys()) {
      if (key.startsWith("question-")) updates[key] = null;
    }
  }

  for (const { questionId, value } of filter.questions ?? []) {
    updates[`question-${questionId}`] = value;
  }

  return updates;
}
