import mixpanel from "mixpanel-browser";
import React, { RefObject, useCallback, useEffect } from "react";

import "../../../../../../shared/components/charts/ChartLegend/styles.css";
import "../../../../../../shared/components/charts/InDetailBarChart/styles.css";
import { InDetailData } from "../../../../../../shared/components/charts/types";
import { ActionGroup } from "../../../../../../shared/components/design-system/component-groups/ActionGroup";
import { TimePeriod } from "../../../../timePeriods";
import "../../../../translations";
import RadioButtons from "../../../design-system/RadioGroup";
import { CHART_TYPES, ChartType } from "../../AnalyticsOverTimePage/ChartActions";
import ExportContainerButton from "../../ExportContainerButton";
import ExportTableDataButton, { inDetailCsv } from "../../ExportTableDataButton";
import { ChartTypeSettings, InDetailChartConfig, InDetailChartSettings } from "../chart-options";
import { SplitSettings } from "../useUrlSplitDimension";
import ChartOptions from "./ChartOptions";
import styles from "./styles.module.scss";

export default function ChartActions({
  firstSplit,
  secondSplit,
  timePeriod,
  chartTypeSettings: { chartType, setChartType },
  chartSettings,
  data,
  container,
  loading,
  config,
}: {
  firstSplit: SplitSettings;
  secondSplit: SplitSettings;
  timePeriod: TimePeriod;
  chartTypeSettings: ChartTypeSettings;
  chartSettings: InDetailChartSettings;
  data: InDetailData | null;
  container: RefObject<HTMLElement> | null;
  loading: boolean;
  config: InDetailChartConfig;
}) {
  const setChartTypeWithTracking = useCallback(
    (type: ChartType) => {
      mixpanel.track(`Changed chart display to ${type.toLowerCase()}`);
      setChartType(type);
    },
    [setChartType],
  );

  // TODO: this feels like a legacy thing, check if it is
  useEffect(() => {
    mixpanel.track("Selected in detail chart", { chart: firstSplit.analyticsCode });
  }, [firstSplit.analyticsCode]);

  const isSegmented = !!(secondSplit.analyticsCode || secondSplit.questionId);

  return (
    <ActionGroup className="ds-my-4">
      <ChartOptions settings={chartSettings} isSegmented={isSegmented} chartType={chartType} config={config} />
      {container ? (
        chartType === "TABLE" ? (
          <ExportTableDataButton data={data ? inDetailCsv(data) : undefined} disabled={loading} />
        ) : (
          <ExportContainerButton container={container} disabled={loading} />
        )
      ) : null}
      <RadioButtons<ChartType>
        label="Chart type"
        variant="segmented"
        visuallyHideLabel
        value={chartType}
        onChange={setChartTypeWithTracking}
        options={CHART_TYPES}
        className={styles.chartTypeSelector}
      />
    </ActionGroup>
  );
}
