import React from "react";

import Calendar from "../../assets/images/EmptyStateAndUpsell/calendar.svg";
import Messages from "../../assets/images/EmptyStateAndUpsell/messages.svg";
import Tasks from "../../assets/images/EmptyStateAndUpsell/tasks.svg";
import WritingPaper from "../../assets/images/EmptyStateAndUpsell/writing-paper.svg";
import Container from "../design-system/Container";
import EmptyStateBanner from "../design-system/EmptyStateBanner";
import CombinedHeader from "../design-system/PageHeader/CombinedHeader";
import styles from "./index.module.scss";

export default function HelpPage() {
  return (
    <Container>
      <CombinedHeader title="Help" />
      <div className={styles.bannerContainer}>
        <EmptyStateBanner
          graphic={Messages}
          direction="vertical"
          title="Contact support"
          body="Having technical problems? use our ticketing system to contact our support team"
          externalLink="mailto:tickets@culture-shift.co.uk"
          buttonLabel="Contact support"
        />
        <EmptyStateBanner
          graphic={WritingPaper}
          direction="vertical"
          title="User guide"
          body="Step-by-step instructions and helpful tips for using the Culture Shift platform"
          externalLink="https://docs.culture-shift.co.uk/user-guide/"
          buttonLabel="View user guide"
        />
        <EmptyStateBanner
          graphic={Tasks}
          direction="vertical"
          title="Knowledge base"
          body="Our knowledge base contains technical information about the Culture Shift platform"
          externalLink="https://www.culture-shift.co.uk/knowledge-base/"
          buttonLabel="View knowledge base"
        />
        <EmptyStateBanner
          graphic={Calendar}
          direction="vertical"
          title="Public roadmap"
          body="Find out about upcoming exciting new features and product developments"
          externalLink="https://portal.productboard.com/culture-shift/2-culture-shift-roadmap"
          buttonLabel="View public roadmap"
        />
      </div>
    </Container>
  );
}
