import React, { FunctionComponent, PropsWithChildren } from "react";

import "./styles.scss";

export type ContainerProps = PropsWithChildren<{
  size?: "small" | "medium" | "large" | "max";
  className?: string;
}>;

const Container: FunctionComponent<ContainerProps> = ({ size = "default", children, className }) => (
  <div className={`c-container ${size ? "c-container--" + size : ""} ${className ?? ""}`}>{children}</div>
);

export default Container;
