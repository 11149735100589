import React, { PropsWithChildren, useMemo } from "react";

import { useAlert } from "../../../../../hooks/useAlert";
import useOrgData from "../../../../../hooks/useOrgData";
import useSelf from "../../../../../hooks/useSelf";

export default function PermissionCheck({ children, close }: PropsWithChildren<{ close: () => void }>) {
  const self = useSelf();
  const alert = useAlert();
  const { isCrossOrg, organisation } = useOrgData();

  const permissionError = useMemo(() => {
    if (!self.org.featureGates.includes("reportAnalytics")) {
      return "Access to analytics is only available to our Gold package partners. To find out more, speak to your Relationship Manager";
    } else if (!isCrossOrg) {
      if (self.team.analyticsAccess !== "ALL") {
        return "To insert or edit a chart you need permission to view all analytics data. This can be done by your site administrator.";
      }
    } else if (
      !self.crossOrganisationPermissions.some(
        ({ org, permission }) => org.id === organisation.id && permission === "ANALYTICS",
      )
    ) {
      return "You must have consultant access to this organisation's analytics data to insert or edit charts";
    }
  }, [isCrossOrg, organisation.id, self]);

  if (permissionError) {
    void alert({
      title: "Insufficient permissions",
      okCaption: "OK",
      okVariant: "primary",
      children: permissionError,
    }).then(close);
    return null;
  }

  return <>{children}</>;
}
