import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $getSelection,
  GridSelection,
  LexicalCommand,
  LexicalEditor,
  NodeSelection,
  RangeSelection,
  SELECTION_CHANGE_COMMAND,
} from "lexical";
import { useCallback, useEffect, useState } from "react";

import useDeepEquality from "../../../../../shared/hooks/useDeepEquality";

const LOW_PRIORITY = 1;

export type Selection = RangeSelection | NodeSelection | GridSelection | null;

// Runs a callback when an editor command happens.
// Unregisters the listener when it's no longer needed.

export function useListenerRootEditorCommand<T>(
  command: LexicalCommand<T> | undefined | null,
  callback: ((payload: T) => void) | ((payload: T) => boolean),
) {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    if (command) {
      return editor.registerCommand(command, (payload: T) => !!callback(payload), LOW_PRIORITY);
    }
  }, [editor, command, callback]);
}

export function useListenerCommand<T>(
  callback: ((payload: T, newEditor: LexicalEditor) => void) | ((payload: T) => boolean),
  deps: unknown[],
  command: LexicalCommand<T> | undefined | null,
) {
  const [editor] = useLexicalComposerContext();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const staticCallback = useCallback(callback, deps);
  useEffect(() => {
    if (command) {
      return editor.registerCommand(
        command,
        (payload: T, newEditor) => !!staticCallback(payload, newEditor),
        LOW_PRIORITY,
      );
    }
  }, [editor, command, staticCallback]);
}

export function useSelectionProperty<T>(callback: (selection: Selection) => T, deps: unknown[], defaultValue: T) {
  const [value, setValue] = useState<T>(defaultValue);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useListenerCommand(() => setValue(callback($getSelection())), deps, SELECTION_CHANGE_COMMAND);

  return useDeepEquality(value);
}

export function useSelection() {
  const [selection, setSelection] = useState<RangeSelection | NodeSelection | GridSelection | null>(null);

  useListenerCommand(() => setSelection($getSelection()), [], SELECTION_CHANGE_COMMAND);

  return useDeepEquality(selection);
}

export function useActiveEditor() {
  const [editor] = useLexicalComposerContext();
  const [activeEditor, setActiveEditor] = useState<LexicalEditor>(editor);

  useListenerCommand((payload, newEditor) => setActiveEditor(newEditor), [], SELECTION_CHANGE_COMMAND);

  return useDeepEquality(activeEditor);
}

export function useCommand<T>(command: LexicalCommand<T>) {
  const editor = useActiveEditor();
  return useCallback((payload: T) => editor.dispatchCommand(command, payload), [editor, command]);
}

export function useCommandWithPayload<T>(command: LexicalCommand<T>, payload: T) {
  const editor = useActiveEditor();
  const memoisedPayload = useDeepEquality(payload);
  return useCallback(() => editor.dispatchCommand(command, memoisedPayload), [editor, command, memoisedPayload]);
}

export function useRootEditorCommandWithPayload<T>(command: LexicalCommand<T>, payload: T) {
  const [editor] = useLexicalComposerContext();
  const memoisedPayload = useDeepEquality(payload);
  return useCallback(() => editor.dispatchCommand(command, memoisedPayload), [editor, command, memoisedPayload]);
}

export function useIsRootEditor() {
  const [rootEditor] = useLexicalComposerContext();
  const activeEditor = useActiveEditor();
  return activeEditor === rootEditor;
}
