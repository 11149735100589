import mixpanel from "mixpanel-browser";
import React, { RefObject, useCallback } from "react";

import "../../../../../shared/components/charts/OverTimeBarChart/styles.css";
import { OverTimeData } from "../../../../../shared/components/charts/types";
import { ActionGroup } from "../../../../../shared/components/design-system/component-groups/ActionGroup";
import "../../../translations";
import RadioButtons from "../../design-system/RadioGroup";
import { ChartTypeSettings } from "../AnalyticsInDetailPage/chart-options";
import ExportContainerButton from "../ExportContainerButton";
import ExportTableDataButton, { overTimeCsv } from "../ExportTableDataButton";
import styles from "./index.module.scss";
import { OverTimeYearSettings } from "./useUrlYearSettings";

export type ChartType = "BAR" | "TABLE";
export const CHART_TYPES: Array<{ id: ChartType; label: string }> = [
  { id: "BAR", label: "Chart" },
  { id: "TABLE", label: "Table" },
];

export default function ChartActions({
  loading,
  data,
  chartTypeSettings: { chartType, setChartType },
  container,
  yearSettings,
}: {
  loading: boolean;
  data: OverTimeData | null;
  chartTypeSettings: ChartTypeSettings;
  container: RefObject<HTMLElement> | null;
  yearSettings: OverTimeYearSettings;
}) {
  const setChartTypeWithTracking = useCallback(
    (chartType: ChartType) => {
      mixpanel.track(`Selected '${chartType.toLowerCase()}' view in 'over time'`);
      setChartType(chartType);
    },
    [setChartType],
  );

  return (
    <ActionGroup className="ds-my-4">
      {container ? (
        chartType === "TABLE" ? (
          <ExportTableDataButton data={data ? overTimeCsv(data, yearSettings) : []} disabled={loading} />
        ) : (
          <ExportContainerButton container={container} disabled={loading} />
        )
      ) : null}
      <RadioButtons<ChartType>
        label="Chart type"
        variant="segmented"
        visuallyHideLabel
        value={chartType}
        onChange={setChartTypeWithTracking}
        options={CHART_TYPES}
        className={styles.chartTypeSelector}
      />
    </ActionGroup>
  );
}
