import { format } from "date-fns";
import React, { ChangeEvent, KeyboardEvent, useCallback, useRef, useState } from "react";

import Icon from "../../../../../shared/components/design-system/Icon";
import useBoolean from "../../../hooks/useBoolean";
import useTrigger from "../../../hooks/useTrigger";
import { ModalPopup, PopupAxisPosition } from "../Popup";
import DateCalendar from "./internals/Calendar/DateCalendar";
import DateRangeDropdownModalForm from "./internals/DateRangeDropdownModalForm";
import { DateRange, DateRangePreset } from "./types";
import { dateToString, stringToDate } from "./utils";

export function DateRangeDropdown({
  minDate,
  maxDate,
  value,
  onChange,
  allowOpenEnded,
  required,
  presets,
  inputSize = "medium",
  className = "",
}: {
  minDate: Date;
  maxDate: Date;
  value: DateRange | null;
  onChange: (value: DateRange | null) => void;
  allowOpenEnded?: boolean;
  required?: boolean;
  presets?: DateRangePreset[];
  inputSize?: "small" | "medium";
  className?: string;
}) {
  const { value: isDropdownOpen, setTrue: openDropdown, setFalse: closeDropdown } = useBoolean(false);
  const inputRef = useRef(null);

  const handleToggleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Enter" || event.key === " ") {
        openDropdown();
      }
    },
    [openDropdown],
  );

  const labelText = valueToString(value, presets);

  const onOk = useCallback(
    (value: DateRange | null) => {
      onChange(value);
      closeDropdown();
    },
    [closeDropdown, onChange],
  );

  return (
    <div className={`c-date-picker is-${inputSize} ${className}`}>
      <div
        className="c-date-picker__toggle-wrapper"
        tabIndex={0}
        onKeyDown={handleToggleKeyDown}
        onClick={openDropdown}
        ref={inputRef}
      >
        <Icon className="c-date-picker__icon" icon="calendar" />
        <span className="c-date-picker__toggle-label">{labelText}</span>

        <button
          className="c-date-picker__toggle-button"
          type="button"
          onClick={openDropdown}
          aria-label="Toggle date picker"
          tabIndex={-1}
        >
          <Icon icon="chevronDown" />
        </button>
      </div>
      <DateRangeDropdownModalForm
        isOpen={isDropdownOpen}
        value={value}
        onCancel={closeDropdown}
        onOk={onOk}
        minDate={minDate}
        maxDate={maxDate}
        allowOpenEnded={allowOpenEnded}
        required={required}
        presets={presets}
        inputRef={inputRef}
      />
    </div>
  );
}

export function DateDropdown({
  minDate,
  maxDate,
  value,
  onChange,
  required,
  inputSize = "medium",
  className = "",
}: {
  minDate: Date;
  maxDate: Date;
  value: Date | null;
  onChange: (value: Date | null) => void;
  required?: boolean;
  inputSize?: "small" | "medium";
  className?: string;
}) {
  const { value: isDropdownOpen, setTrue: openDropdown, setFalse: closeDropdown } = useBoolean(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { ref, trigger } = useTrigger(value);

  const handleToggleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Enter" || event.key === " ") {
        openDropdown();
      }
    },
    [openDropdown],
  );

  const [textValue, setTextValue] = useState(value ? dateToString(value) : "");

  const onChangeText = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setTextValue(e.target.value);
      if (!e.target.value) {
        onChange(null);
        return;
      }
      const dateValue = stringToDate(e.target.value);
      if (!dateValue) {
        inputRef.current?.setCustomValidity("Invalid date format");
        return;
      }
      inputRef.current?.setCustomValidity("");
      trigger(dateValue);
      onChange(dateValue);
    },
    [onChange, trigger],
  );

  const onClickCalendar = useCallback(
    (value: Date) => {
      setTextValue(dateToString(value));
      onChange(value);
    },
    [onChange, setTextValue],
  );

  return (
    <div className={`c-date-picker is-${inputSize} ${className}`}>
      <div
        className="c-date-picker__toggle-wrapper"
        onKeyDown={handleToggleKeyDown}
        onClick={openDropdown}
        ref={containerRef}
      >
        <Icon className="c-date-picker__icon" icon="calendar" />
        <input
          className="c-date-picker__toggle-input"
          type="text"
          placeholder="dd/mm/yyyy"
          value={textValue}
          onChange={onChangeText}
          ref={inputRef}
          required={required}
        />

        <button
          className="c-date-picker__toggle-button"
          type="button"
          onClick={openDropdown}
          aria-hidden="true"
          tabIndex={-1}
        >
          <Icon icon="chevronDown" />
        </button>
      </div>
      <ModalPopup
        title="Date picker"
        positions={POSITIONS}
        shown={isDropdownOpen}
        source={containerRef}
        onClose={closeDropdown}
      >
        <div className="c-date-picker__dropdown is-open" role="dialog" aria-modal="true" aria-label="Date picker">
          <DateCalendar
            value={value ?? undefined}
            onChange={onClickCalendar}
            minDate={minDate}
            maxDate={maxDate}
            scrollTo={ref}
          />
        </div>
      </ModalPopup>
    </div>
  );
}

const POSITIONS = [
  { x: PopupAxisPosition.MatchStart, y: PopupAxisPosition.After },
  { x: PopupAxisPosition.MatchEnd, y: PopupAxisPosition.After },
  { x: PopupAxisPosition.Centred, y: PopupAxisPosition.After },
  { x: PopupAxisPosition.MatchStart, y: PopupAxisPosition.Before },
  { x: PopupAxisPosition.MatchEnd, y: PopupAxisPosition.Before },
  { x: PopupAxisPosition.Centred, y: PopupAxisPosition.Before },
];

function valueToString(value: DateRange | null, presets?: DateRangePreset[]) {
  if (!value) return "Select a date range";
  const { startDate, endDate, presetId } = value;

  if (presetId) {
    const preset = presets?.find((p) => p.presetId === presetId);
    if (preset) return preset.label;
    console.warn("Preset not found", presetId);
  }

  if (startDate) {
    return endDate
      ? `${format(startDate, "dd MMM, Y")}${endDate ? ` - ${format(endDate, "dd MMM, Y")}` : ""}`
      : `From ${format(startDate, "dd MMM, Y")}`;
  }

  if (endDate) return `Until ${format(endDate, "dd MMM, Y")}`;

  return "All time";
}
