import mixpanel from "mixpanel-browser";
import React, { useCallback, useEffect, useRef } from "react";

import { useOpenableWithTracking } from "../../../../hooks/useBoolean";
import Button from "../../../design-system/Button";
import { ModalPopup, PopupAxisPosition, PopupPosition } from "../../../design-system/Popup";
import ToggleSwitch from "../../../design-system/ToggleSwitch";
import { ChartType } from "../../AnalyticsOverTimePage/ChartActions";
import { InDetailChartConfig, InDetailChartSettings } from "../chart-options";
import styles from "./styles.module.scss";

const POSITIONS: PopupPosition[] = [
  { x: PopupAxisPosition.Centred, y: PopupAxisPosition.After },
  { x: PopupAxisPosition.MatchEnd, y: PopupAxisPosition.After },
  { x: PopupAxisPosition.MatchStart, y: PopupAxisPosition.After },
  { x: PopupAxisPosition.Centred, y: PopupAxisPosition.Before },
  { x: PopupAxisPosition.MatchEnd, y: PopupAxisPosition.Before },
  { x: PopupAxisPosition.MatchStart, y: PopupAxisPosition.Before },
];

export default function ChartOptions({
  settings: { chartOptions: options, setChartOptions: onChange },
  chartType,
  isSegmented,
  config,
}: {
  settings: InDetailChartSettings;
  chartType: ChartType;
  isSegmented?: boolean;
  config: InDetailChartConfig;
}) {
  const { isOpen, open, close } = useOpenableWithTracking("chart settings");

  const toggleShowNumbers = useCallback(
    (showNumbers: boolean) => {
      mixpanel.track(`${showNumbers ? "Enabled" : "Disabled"} showing values on a chart`);
      onChange({ ...options, showNumbers });
    },
    [onChange, options],
  );

  const toggleShowOuterBars = useCallback(
    (showOuterBars: boolean) => {
      mixpanel.track(`${showOuterBars ? "Enabled" : "Disabled"} showing the total bar on a chart`);
      onChange({ ...options, showOuterBars });
    },
    [onChange, options],
  );
  const toggleShowZeroBars = useCallback(
    (showZeroBars: boolean) => {
      mixpanel.track(`${showZeroBars ? "Enabled" : "Disabled"} showing the zero value bars on a chart`);
      onChange({ ...options, showZeroBars });
    },
    [onChange, options],
  );
  const toggleShowHeatMap = useCallback(
    (showHeatMap: boolean) => {
      mixpanel.track(`${showHeatMap ? "Enabled" : "Disabled"} heat map`);
      onChange({ ...options, showHeatMap });
    },
    [onChange, options],
  );

  const togglePercentages = useCallback(
    (showPercentages: boolean) => {
      mixpanel.track(`${showPercentages ? "Enabled" : "Disabled"} showing percentages on a chart`);
      onChange({ ...options, showPercentages });
    },
    [onChange, options],
  );

  const toggleNotAnswered = useCallback(
    (showNotAnswered: boolean) => {
      mixpanel.track(`${showNotAnswered ? "Enabled" : "Disabled"} showing "not answered" on a chart`);
      onChange({ ...options, showNotAnswered });
    },
    [onChange, options],
  );

  useEffect(() => {
    if (chartType !== "BAR" && isSegmented && options.showPercentages) onChange({ ...options, showPercentages: false });
  }, [chartType, isSegmented, onChange, options]);

  const button = useRef<HTMLButtonElement>(null);

  return (
    <>
      <Button variant="action" onClick={open} ref={button} icon="gear">
        Settings
      </Button>
      <ModalPopup title="Chart options" positions={POSITIONS} source={button} shown={isOpen} onClose={close}>
        <div className={styles.ChartOptions_container}>
          {chartType === "TABLE" && isSegmented && config.allowHeatMap ? (
            <ToggleSwitch checked={options.showHeatMap} onChange={toggleShowHeatMap} label="Show heat map" />
          ) : null}
          {chartType === "BAR" ? (
            <ToggleSwitch checked={options.showNumbers} onChange={toggleShowNumbers} label="Show values" />
          ) : null}
          {chartType === "BAR" || !isSegmented ? (
            <ToggleSwitch checked={options.showPercentages} onChange={togglePercentages} label="Show percentages" />
          ) : null}
          {chartType === "BAR" && isSegmented ? (
            <ToggleSwitch checked={options.showOuterBars} onChange={toggleShowOuterBars} label="Show outer bars" />
          ) : null}
          {chartType === "BAR" && isSegmented ? (
            <ToggleSwitch checked={options.showZeroBars} onChange={toggleShowZeroBars} label="Show zero bars" />
          ) : null}
          <ToggleSwitch checked={options.showNotAnswered} onChange={toggleNotAnswered} label="Show 'not answered'" />
        </div>
      </ModalPopup>
    </>
  );
}
