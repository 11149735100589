import React, { FunctionComponent } from "react";

import { ReportStatus } from "../../../../../__generated__/graphql";
import "./styles.scss";

export const statusText = {
  Loading: { text: "Loading", className: "" },
  [ReportStatus.AwaitingAssignment]: { text: "Unassigned", className: "awaiting-assignment" },
  [ReportStatus.Assigned]: { text: "Assigned", className: "assigned" },
  [ReportStatus.Accepted]: { text: "Accepted", className: "accepted" },
  [ReportStatus.Closed]: { text: "Closed", className: "closed" },
  [ReportStatus.Archived]: { text: "Archived", className: "archived" },
};

const ReportStatusComponent: FunctionComponent<{
  status: ReportStatus | "Loading";
  className?: string;
}> = ({ status, className: classNameProp }) => {
  const { text, className } = statusText[status];
  return <span className={`status-tag status-tag--${className} ${classNameProp ?? ""}`}>{text}</span>;
};

export default ReportStatusComponent;
