import { AnalyticsCode, AnalyticsSplit } from "../../../../../../../__generated__/graphql";
import {
  CustomGroupSet,
  DimensionGroupConfiguration,
  MergedOptionSet,
  OptionGroup,
} from "../../../../../../../shared/components/charts/types";
import { STATUS_NAMES } from "../../../../../../../shared/translation/untranslated";
import { OrgDataCurrentOrg, OrgDataQuestion } from "../../../../../hooks/useOrg/types";
import { AnalyticsQueryResult, UseAnalyticsQueryResult } from "../../../useAnalyticsQuery";

function dataMatcher(data: UseAnalyticsQueryResult["data"]) {
  if (!data) return () => true;
  const [chart] = data;
  const chartIds = new Set(chart.data.map(getId));
  return (id: string) => chartIds.has(id);
}

// In principle we could re-use the code that generates groups for the backend, but that's in a different format as it needs different information. Crucially here, it doesn't distinguish between an OptionGroup (an explicit, reversible and deliberate grouping of distinct options) and a MergedOptionSet (a group of option IDs which share the same label and are therefore treated as one atomic item by the UI). As far as the backend is concerned, those are both "groups".
export function getGroupPreview(
  options: DimensionGroupConfiguration,
  data: UseAnalyticsQueryResult["data"],
  { questions, sites, outcomes }: Pick<OrgDataCurrentOrg, "questions" | "sites" | "outcomes">,
  split: AnalyticsSplit,
): CustomGroupSet {
  const matchesId = dataMatcher(data);
  switch (options.basis) {
    case "CUSTOM":
      return options.groups;

    case "NONE": {
      const groups: MergedOptionSet[] = [];
      switch (split.code) {
        case AnalyticsCode.Form:
          for (const { forms } of sites) {
            for (const { id, title } of forms) addToGroup(groups, { id, value: title }, matchesId);
          }
          break;
        case AnalyticsCode.Outcome:
          for (const { id, name } of outcomes) addToGroup(groups, { id, value: name }, matchesId);
          break;
        case AnalyticsCode.Status:
          return Object.entries(STATUS_NAMES).map(([id, label]) => ({ ids: [id], label } as MergedOptionSet));
        default:
          // Group everything by label:
          for (const q of questionsInSplit(questions, split)) {
            for (const { options } of q.optionGroups) {
              addAllToGroup(groups, options, matchesId);
            }
            if (q.deletedOptions) {
              addAllToGroup(groups, q.deletedOptions, matchesId);
            }
          }
      }
      return groups;
    }

    case "FORM": {
      const optionGroups: OptionGroup[] = [];
      const optionGroupsById: Record<string, OptionGroup> = {};
      const ungrouped: MergedOptionSet[] = [];

      for (const q of questionsInSplit(questions, split)) {
        for (const { groupName, id, options } of q.optionGroups) {
          // A group without a name has to be the only group a question has so treat its options as ungrouped:
          if (!groupName) {
            addAllToGroup(ungrouped, options, matchesId);
            continue;
          }
          // Merge with an existing option group if it has the same name — this is mostly for cases where there are (eg) two faculty questions.
          const existingGroup = optionGroups.find((g) => g.label === groupName);
          if (existingGroup) {
            addAllToGroup(existingGroup.members, options, matchesId);
            continue;
          }
          // Make a group and store it for later.
          const mergedGroup = { label: groupName, id, members: allAsGroup(options, matchesId) };
          optionGroups.push(mergedGroup);
          optionGroupsById[id] = mergedGroup;
        }

        if (q.deletedOptionGroups) {
          for (const { id, groupName } of q.deletedOptionGroups) {
            if (!optionGroupsById[id]) {
              // If it has the same name as an existing group, just log that group against this one's ID so we can reference it later in the event that any deleted options are in this group
              if (groupName) {
                const existingGroup = optionGroups.find((g) => g.label === groupName);
                if (existingGroup) {
                  optionGroupsById[id] = existingGroup;
                  continue;
                }
              }
              // Otherwise create an empty group for it
              const mergedGroup = { label: groupName ?? "Deleted option group", id, members: [] };
              optionGroups.push(mergedGroup);
              optionGroupsById[id] = mergedGroup;
            }
          }
        }

        if (q.deletedOptions) {
          for (const option of q.deletedOptions) {
            addToGroup(optionGroupsById[option.groupId!]?.members ?? ungrouped, option, matchesId);
          }
        }
      }

      // Lastly, remove any groups that have only one (or zero) item. Loop through the array backwards so we can splice out of it without messing up the numbering.
      for (let i = optionGroups.length - 1; i >= 0; --i) {
        const { members } = optionGroups[i];
        if (members.length > 1) continue;
        optionGroups.splice(i, 1);
        ungrouped.unshift(...members);
      }

      return [...optionGroups, ...ungrouped];
    }
  }
}

type Option = { id?: string | null; value: string };

function allAsGroup(options: Option[], matchesId: (id: string) => boolean) {
  const groups: MergedOptionSet[] = [];
  addAllToGroup(groups, options, matchesId);
  return groups;
}

function addAllToGroup(groups: MergedOptionSet[], options: Option[], matchesId: (id: string) => boolean) {
  for (const option of options) {
    addToGroup(groups, option, matchesId);
  }
}

function addToGroup(groups: MergedOptionSet[], option: Option, matchesId: (id: string) => boolean) {
  if (!matchesId(option.id!)) return;
  for (const member of groups) {
    if (member.label === option.value) {
      member.ids.push(option.id!);
      return;
    }
  }
  groups.push({ label: option.value, ids: [option.id!] });
}

type AnalysableQuestion = OrgDataQuestion & { optionGroups: NonNullable<OrgDataQuestion["optionGroups"]> };

function* questionsInSplit(
  questions: OrgDataQuestion[],
  { code, questions: questionIds }: AnalyticsSplit,
): Generator<AnalysableQuestion> {
  for (const q of questions) {
    if (!q.optionGroups) continue;
    // @ts-ignore - these types do in fact overlap, like, a lot
    if (q.analyticsCode === code || questionIds?.includes(q.id)) {
      yield q as AnalysableQuestion;
    }
  }
}

function getId(row: AnalyticsQueryResult): string {
  return row.optionId ?? row.form?.id ?? row.outcome?.id ?? row.status ?? "unknown id";
}
