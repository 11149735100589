import { RichTextDocument, TopLevelNode } from "../../../../../../shared/block-editor-data/types";
import {
  Heading,
  ListItem,
  Paragraph,
  TextBlock,
  UnorderedList,
} from "../../../../../../shared/block-editor-data/types";

type NodeWithChildren = Pick<Heading | Paragraph | ListItem | UnorderedList, "children">;
export type PossibleTextNode = NodeWithChildren | TextBlock | TopLevelNode;

const isRichTextDocument = (doc: unknown): doc is RichTextDocument => (doc as RichTextDocument).root !== undefined;

export function getTextContent(document: RichTextDocument | TopLevelNode): string {
  if (isRichTextDocument(document)) {
    const possibleTextNodes = document.root as unknown as PossibleTextNode;
    return parseNodes(possibleTextNodes);
  }

  return parseNodes(document);
}

const isNodeWithText = (node: unknown): node is TextBlock => (node as TextBlock).text !== undefined;
const isNodeWithChildren = (node: unknown): node is NodeWithChildren =>
  (node as NodeWithChildren).children !== undefined;
export function parseNodes(node: PossibleTextNode): string {
  if (isNodeWithText(node)) {
    return node.text ?? "";
  }
  if (isNodeWithChildren(node)) return node.children.map(parseNodes).join("");
  return "";
}
