import React, { FormEvent, useCallback } from "react";

import TextInput from "../../../../../shared/components/design-system/TextInput";
import ButtonSet from "../../../../../shared/components/design-system/component-groups/ButtonSet";
import useStateWithInputChange from "../../../hooks/useStateWithInputChange";
import Button from "../../design-system/Button";

interface ForgotPasswordFormProps {
  onSubmit: (username: string) => void;
}

export default function ForgotPasswordForm({ onSubmit: onSubmitProp }: ForgotPasswordFormProps) {
  const [username, setUsername] = useStateWithInputChange("");

  const onSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      onSubmitProp(username);
    },
    [onSubmitProp, username],
  );

  return (
    <>
      <h1 className="ds-h3">Forgotten Password</h1>
      <p>
        Please enter your email address below. We’ll send you a verification code you can use to verify your identity
        and reset your password.
      </p>
      <form className="ds-mt-8" onSubmit={onSubmit}>
        <TextInput
          type="email"
          value={username}
          label="Email"
          placeholder="Email"
          autoComplete="username"
          autoFocus
          required
          onChange={setUsername}
        />
        <ButtonSet direction="vertical" className="ds-mt-7">
          <Button className="ds-mt-7" type="submit" size="large">
            Send verification code
          </Button>
        </ButtonSet>
      </form>
    </>
  );
}
