import { useQuery } from "@apollo/client";
import React, { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from "react";

import {
  GetOrgDataQuery,
  GetOrgDataQueryVariables,
  GetOrgSitesQuery,
  GetOrgsQuestionsQuery,
  GetOrgTeamsQuery,
  GetOutcomesQuery,
  GetSelfQuery,
  SiteUrlStatus,
} from "../../../../__generated__/graphql";
import { DEFAULT_SUPERUSER_ORG_ID } from "../../../../shared/db/special-case-orgs";
import { logErrorWithId } from "../../components/design-system/GenericError";
import ErrorPage from "../../components/global-furniture/ErrorPage";
import Loading from "../../components/global-furniture/LoadingPage";
import { CROSS_ORG_QUERY, OUTCOMES_QUERY, QUESTIONS_QUERY, SITES_QUERY, TEAMS_QUERY } from "./org-queries";
import { SELF_QUERY } from "./self-query";
import type { ContextPayload, OrgDataCurrentOrg } from "./types";

// This is exported so we can stub out the self in tests and Storybook:
export const context = createContext<ContextPayload | null>(null);

export default function useOrg(): ContextPayload {
  const data = useContext(context);
  if (!data) throw new Error("Do no use useOrg outside of the provider");
  return data;
}

export function OrgProvider({ children }: PropsWithChildren<unknown>) {
  const [orgOverride, setOrgOverride] = useState<string | null>(null);
  const [siteOverride, setSiteOverride] = useState<string | null>(null);

  const selfQuery = useQuery<GetSelfQuery>(SELF_QUERY, { fetchPolicy: "cache-first", context: { doNotBatch: true } });

  const questionsQuery = useQuery<GetOrgsQuestionsQuery>(QUESTIONS_QUERY, { context: { doNotBatch: true } });
  const teamsQuery = useQuery<GetOrgTeamsQuery>(TEAMS_QUERY, { context: { doNotBatch: true } });
  const outcomesQuery = useQuery<GetOutcomesQuery>(OUTCOMES_QUERY, { context: { doNotBatch: true } });
  const sitesQuery = useQuery<GetOrgSitesQuery>(SITES_QUERY, { context: { doNotBatch: true } });

  const crossOrgQuery = useQuery<GetOrgDataQuery, GetOrgDataQueryVariables>(CROSS_ORG_QUERY, {
    skip: !orgOverride,
    variables: { organisationId: orgOverride! },
    fetchPolicy: "cache-first",
    context: { doNotBatch: true },
  });

  const payload = useMemo<ContextPayload | null>(() => {
    if (
      !selfQuery.data ||
      !questionsQuery.data ||
      !outcomesQuery.data ||
      !teamsQuery.data ||
      !sitesQuery.data ||
      !questionsQuery.data ||
      (orgOverride && !crossOrgQuery.data)
    ) {
      return null;
    }

    const currentOrg: OrgDataCurrentOrg = orgOverride
      ? {
          organisation: crossOrgQuery.data!.organisation,
          sites: crossOrgQuery.data!.organisation.sites.map(findSiteUrl),
          questions: crossOrgQuery.data!.organisation.questions,
          outcomes: crossOrgQuery.data!.organisation.outcomes,
          teams: crossOrgQuery.data!.organisation.teams,
          isCrossOrg: true,
          featureGates: crossOrgQuery.data!.organisation.featureGates,
          refetch: () => crossOrgQuery.refetch(),
          reloading: crossOrgQuery.loading,
        }
      : {
          ...questionsQuery.data,
          ...teamsQuery.data,
          ...outcomesQuery.data,
          sites: sitesQuery.data?.sites.map(findSiteUrl),
          organisation: selfQuery.data.self.org,
          isCrossOrg: false,
          featureGates: selfQuery.data.self.org.featureGates,
          refetch: () => questionsQuery.refetch(),
          reloading: questionsQuery.loading,
        };

    return {
      loggedInUser: selfQuery.data.self,
      loggedInTeam: selfQuery.data.self.team,
      loggedInOrg: selfQuery.data.self.org,
      isSuperAdmin: selfQuery.data.self.org.id === DEFAULT_SUPERUSER_ORG_ID,
      currentOrg,
      currentSite: siteOverride ? currentOrg.sites.find((site) => site.id === siteOverride) ?? null : null,
      setCurrentOrg: setOrgOverride,
      setCurrentSite: setSiteOverride,
    };
  }, [
    crossOrgQuery,
    orgOverride,
    siteOverride,
    outcomesQuery.data,
    questionsQuery,
    selfQuery.data,
    sitesQuery.data,
    teamsQuery.data,
  ]);

  useEffect(() => {
    if (payload && siteOverride && !payload.currentSite) {
      console.log("Resetting site override as current selection does not exist in the current org");
      setSiteOverride(null);
    }
  }, [payload, siteOverride]);

  if (
    selfQuery.loading ||
    questionsQuery.loading ||
    teamsQuery.loading ||
    outcomesQuery.loading ||
    sitesQuery.loading ||
    crossOrgQuery.loading
  ) {
    return <Loading authState="loading" />;
  }

  if (selfQuery.error) return <ErrorPage errorId={logErrorWithId(selfQuery.error)} />;
  if (questionsQuery.error) return <ErrorPage errorId={logErrorWithId(questionsQuery.error)} />;
  if (teamsQuery.error) return <ErrorPage errorId={logErrorWithId(teamsQuery.error)} />;
  if (outcomesQuery.error) return <ErrorPage errorId={logErrorWithId(outcomesQuery.error)} />;
  if (sitesQuery.error) return <ErrorPage errorId={logErrorWithId(sitesQuery.error)} />;
  if (crossOrgQuery.error) return <ErrorPage errorId={logErrorWithId(crossOrgQuery.error)} />;

  if (!payload) return <ErrorPage />;

  return <context.Provider value={payload}>{children}</context.Provider>;
}

function findSiteUrl(site: GetOrgSitesQuery["sites"][number]) {
  const host =
    site.urls.find((u) => u.isCanonical) ??
    site.urls.find((u) => u.status === SiteUrlStatus.CorrectlySetUp) ??
    site.urls[0];

  return { ...site, url: host ? `https://${host.host}` : null };
}
