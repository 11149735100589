import classNames from "classnames";
import React, { ChangeEvent, PropsWithChildren, useCallback, useRef } from "react";
import { v4 as uuid } from "uuid";

import Icon, { IconSlug } from "../../../../../shared/components/design-system/Icon";
import InputLabel from "../../../../../shared/components/design-system/InputLabel";
import "./styles.scss";

interface BaseSelectProps<T extends string> {
  id?: string;
  label: string;
  value?: T;
  onChange?: (selection: T) => void;
  horizontal?: boolean;
  size?: "small" | "medium" | "large";
  required?: boolean;
  disabled?: boolean;
  hideLabel?: boolean;
  className?: string;
  icon?: IconSlug;
  variant?: "ghost";
}

export type SelectProps<T extends string> =
  | PropsWithChildren<BaseSelectProps<T> & { options?: undefined; invalidOptionText?: undefined }>
  | (BaseSelectProps<T> & {
      options: Array<{ id: T; label: string }>;
      /** The text to display as the currently selected option if it isn't one of the ones in the list */
      invalidOptionText?: string;
      children?: undefined;
    });

export default function Select<T extends string>({
  children,
  options,
  id = uuid(),
  label,
  onChange,
  value,
  horizontal,
  size = "large",
  required,
  disabled,
  hideLabel,
  className,
  icon,
  variant,
  invalidOptionText = "Please select another option",
}: SelectProps<T>) {
  const ref = useRef<HTMLSelectElement>(null);

  const selectClassName = classNames({
    "c-select": true,
    "has-label": label,
    "is-horizontal": horizontal,
    [`is-${size}`]: size,
    [className ?? ""]: className,
  });

  const handleChange = useCallback(
    (ev: ChangeEvent<HTMLSelectElement>) => {
      if (onChange) onChange(ev.currentTarget.value as T);
    },
    [onChange],
  );

  const invalidOptionSelected = value && options && !options.some(({ id }) => id === value);
  if (invalidOptionSelected) {
    ref.current?.setCustomValidity("Please select another value");
  } else {
    ref.current?.setCustomValidity("");
  }

  return (
    <>
      <div className={selectClassName}>
        <InputLabel className="c-select__label" visuallyHidden={hideLabel} htmlFor={id}>
          {label}
        </InputLabel>

        <div className="c-select__wrapper">
          {icon && <Icon className="c-select__pre-icon" icon={icon} />}
          <select
            id={id}
            className={`c-select__input  ${variant ? "is-" + variant : ""}`}
            value={value ?? ""}
            onChange={handleChange}
            required={required}
            disabled={disabled}
            ref={ref}
          >
            {children ?? (
              <>
                {invalidOptionSelected ? <option id="value">{invalidOptionText}</option> : null}
                {options!.map(({ id, label }) => (
                  <option key={id} value={id}>
                    {label}
                  </option>
                ))}
              </>
            )}
          </select>
          <Icon className="c-select__icon" icon="chevronDown" />
        </div>
      </div>
    </>
  );
}
