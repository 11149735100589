import * as Sentry from "@sentry/browser";
import type {
  DOMConversion,
  DOMConversionMap,
  DOMExportOutput,
  EditorConfig,
  LexicalEditor,
  LexicalNode,
  NodeKey,
  SerializedLexicalNode,
  Spread,
} from "lexical";
import { DecoratorNode } from "lexical";
import * as React from "react";
import { Suspense } from "react";

import { Caption, Image } from "../../../../../../../shared/block-editor-data/types";
import { registerNodeCheck } from "../BlockContainer/Caption/is";
import { createBlankCaption, createCaption } from "../utils";

const ImageComponent = React.lazy(
  // @ts-ignore
  () => import("./ImageComponent"),
);

function convertImageElement(node: HTMLElement): null | DOMConversion {
  if (!(node instanceof HTMLImageElement)) return null;

  return {
    priority: 0,
    conversion: (node) => ({
      node: $createImageNode({
        altText: (node as HTMLImageElement).alt,
        src: (node as HTMLImageElement).src,
        type: "image",
        version: 1,
        indent: 0,
        caption: createBlankCaption(),
      }),
    }),
  };
}

function convertFigureElement(node: HTMLElement): null | DOMConversion {
  if (node.tagName !== "FIGURE") return null;
  if (!node.getAttribute("data-trix-content-type")?.startsWith("image/")) return null;

  try {
    const attributes = JSON.parse(node.getAttribute("data-trix-attributes")!);
    const details = JSON.parse(node.getAttribute("data-trix-attachment")!);

    if (typeof details.href !== "string") return null;

    return {
      priority: 1,
      conversion: (node) => ({
        node: $createImageNode({
          src: details.href,
          altText: "",
          type: "image",
          version: 1,
          indent: 0,
          caption: createCaption(attributes.caption ?? ""),
        }),
      }),
    };
  } catch (e) {
    Sentry.captureException(e);
    return null;
  }
}

export type SerializedImageNode = Spread<Image, SerializedLexicalNode>;

export class ImageNode extends DecoratorNode<JSX.Element> {
  __src: string;
  __altText: string;
  __caption: Caption;

  static getType(): string {
    return "image";
  }

  static clone(node: ImageNode): ImageNode {
    return new ImageNode(node.__src, node.__altText, node.__caption, node.__status);
  }

  static importJSON(serializedNode: SerializedImageNode): ImageNode {
    const { altText, src, caption } = serializedNode;

    const node = $createImageNode({
      altText,
      src,
      type: "image",
      version: 1,
      indent: 0,
      caption: caption,
    });

    return node;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement("img");
    element.setAttribute("src", this.__src);
    element.setAttribute("alt", this.__altText);
    return { element };
  }

  static importDOM(): DOMConversionMap | null {
    return {
      img: convertImageElement,
      figure: convertFigureElement,
    };
  }

  constructor(src: string, altText: string, caption: Caption, key?: NodeKey) {
    super(key);
    this.__src = src;
    this.__altText = altText;
    this.__caption = caption;
  }

  exportJSON(): SerializedImageNode {
    return {
      altText: this.getAltText(),
      src: this.getSrc(),
      type: "image",
      version: 1,
      indent: 0,
      caption: this.__caption,
    };
  }

  // View

  createDOM(config: EditorConfig): HTMLElement {
    const span = document.createElement("span");
    const theme = config.theme;
    const className = theme.image;
    if (className !== undefined) {
      span.className = className;
    }
    return span;
  }

  updateCaptionJSON(json: string): void {
    const self = this.getWritable();
    self.__caption = { ...self.__caption, json };
  }
  updateImageNode(srcURL: string, alt: string, key?: string): void {
    const self = this.getWritable();
    self.__altText = alt;
    self.__src = srcURL;
  }
  updateDOM(): false {
    return false;
  }

  getSrc(): string {
    return this.__src;
  }

  getAltText(): string {
    return this.__altText;
  }

  isInline() {
    return false;
  }

  decorate(_: LexicalEditor, config: EditorConfig): JSX.Element {
    return (
      <Suspense>
        <ImageComponent
          src={this.__src}
          altText={this.__altText}
          caption={this.__caption}
          nodeKey={this.getKey()}
          theme={config.theme}
        />
      </Suspense>
    );
  }
}

export function $createImageNode({ altText, src, caption }: Image): ImageNode {
  return new ImageNode(src, altText, caption);
}

export function $isImageNode(node: LexicalNode | null | undefined): node is ImageNode {
  return node instanceof ImageNode;
}

registerNodeCheck($isImageNode);

export function isInAttachmentBucket(src: string) {
  // I'm not sure we currently know the URLs that files actually get uploaded to — generally we ask S3 to upload a file and it tells us the URL of that one file. They all end up on the same domain, but we don't have a config key for what that domain is. We could add one, but they're all unmasked CloudFront URLs, so it seems safe to assume that *any* image pasted from such a URL is one of ours, as very few of the images people are pasting in will come from sites with that particular, quite unusual setup. We also treat images on localhost as in buckets, since that's how images uploaded to the Localstack S3 implementation appear (and in any case if this comes up in production the server won't be able to download them anyway).
  return /^https?:\/\/([^.]+\.cloudfront\.net\/|localhost(:\d+))\//.test(src);
}
