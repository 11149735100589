import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import { COMMAND_PRIORITY_EDITOR, createCommand, LexicalCommand } from "lexical";
import { useEffect } from "react";
import * as React from "react";

import { Image } from "../../../../../../../shared/block-editor-data/types";
import { NestedContext, NestedEditorConfig } from "../NestedContext";
import $insertBlockNode from "../block-nodes/insert-block-node";
import { $createImageNode, ImageNode } from "./ImageNode";

export type InsertImagePayload = Readonly<Image>;

export const INSERT_IMAGE_COMMAND: LexicalCommand<InsertImagePayload> = createCommand();

export default function ImagesPlugin({
  nestedEditorConfig,
  children,
}: {
  nestedEditorConfig: NestedEditorConfig;
  children: JSX.Element | Array<JSX.Element>;
}): JSX.Element | null {
  const [editor] = useLexicalComposerContext();
  const nestedContext = React.useContext(NestedContext);
  useEffect(() => {
    if (!editor.hasNodes([ImageNode])) {
      throw new Error("ImagesPlugin: ImageNode not registered on editor");
    }
    nestedContext.set(nestedEditorConfig, children);
    return mergeRegister(
      editor.registerCommand<InsertImagePayload>(
        INSERT_IMAGE_COMMAND,
        (payload) => {
          $insertBlockNode($createImageNode(payload));
          return true;
        },
        COMMAND_PRIORITY_EDITOR,
      ),
    );
  }, [children, editor, nestedContext, nestedEditorConfig]);

  return null;
}
