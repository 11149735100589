import { v4 as uuid } from "uuid";

import {
  CustomGroupConfiguration,
  CustomGroupSet,
  MergedOptionSet,
  OptionGroup,
} from "../../../../../../../shared/components/charts/types";

export function isChecked(checked: string[], { ids }: MergedOptionSet) {
  return checked.some((id) => ids.includes(id));
}

export function applyGroup(
  checked: string[],
  existingGroupId: string | null,
  ungroup: boolean,
  existingGroups: CustomGroupSet,
): CustomGroupConfiguration {
  if (!checked.length) throw new Error("You must check items before using this control");
  if (!existingGroups) throw new Error("No groups exist");
  if (existingGroupId && ungroup) throw new Error("Invalid options");

  // These variables, in order, are what we're going to return.
  // newGroup isn't used if we're in 'ungroup' mode, but create it anyway for convenience.
  const newGroups: OptionGroup[] = [];
  const newGroup = existingGroupId
    ? (existingGroups.find((group) => (group as OptionGroup).id === existingGroupId) as OptionGroup | undefined)
    : { id: uuid(), label: "", members: [] };
  const newUngroupedItems: MergedOptionSet[] = [];

  if (!newGroup) throw new Error("Invalid group specified");

  for (const item of existingGroups) {
    // Each item here might be a single option or a group of options.
    if ("ids" in item) {
      // The current item is a single option. Put it either in the new group or in the set of ungrouped items
      if (!ungroup && isChecked(checked, item)) newGroup.members.push(item);
      else newUngroupedItems.push(item);
      continue;
    }

    // Otherwise, the item is a group.
    if (item.id === existingGroupId) {
      // If it's the group we're adding to, no need to do anything — all its members are already in the right place. Add it to the list and move on.
      newGroups.push(item);
      continue;
    }

    // Loop through its members and add them to the new membership list for this group, the new group, or the ungrouped item list, depending on whether they're checked and what we're doing.
    const newMembers: MergedOptionSet[] = [];
    for (const child of item.members) {
      if (!isChecked(checked, child)) newMembers.push(child);
      else if (ungroup) newUngroupedItems.push(child);
      else newGroup.members.push(child);
    }

    // Work out if the group still has enough members to be worth keeping around. If not, add its remaining members back to the "ungrouped" category.
    if (newMembers.length < 2) newUngroupedItems.push(...newMembers);
    else newGroups.push({ ...item, members: newMembers });
  }

  if (!ungroup && newGroup.members.length <= 1) throw new Error("You must select at least two options to group");

  return {
    basis: "CUSTOM",
    groups:
      ungroup || existingGroupId
        ? [...newGroups, ...newUngroupedItems]
        : [...newGroups, newGroup, ...newUngroupedItems],
  };
}
