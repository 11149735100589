import { GetDocumentQuery, GetSiteContentQuery, PublicationMode } from "../../../../../__generated__/graphql";
import React from "../../../../../shared/block-editor-data/react";
import { ExternalLink } from "../../../../../shared/components/design-system/Button/Link";
import Badge from "../../design-system/Badge";
import CopyButton from "../../design-system/Button/specifics/CopyButton";
import useSiteData from "../useSiteData";
import styles from "./styles.module.scss";

export default function DocumentStatus({
  document,
}: {
  document: GetSiteContentQuery["site"]["documents"][number] | GetDocumentQuery["document"] | undefined;
}) {
  if (!document) {
    return <Badge variant="info" className="ds-ml-auto" label="Not saved" />;
  }

  if (document.externalLinkUrl) {
    return <Badge variant="success" className="ds-ml-auto" label="Link" />;
  }

  if (!document.published) {
    return <Badge variant="info" className="ds-ml-auto" label="Draft" />;
  }

  if (document.published.publicationMode === PublicationMode.PrivateLink) {
    return <Badge variant="warning" className="ds-ml-auto" label="Published with private link" />;
  }

  if (document.currentDraft!.id !== document.published.publishedVersion!.id) {
    return <Badge variant="warning" className="ds-ml-auto" label="Unpublished changes" />;
  }

  return <Badge variant="success" className="ds-ml-auto" label="Published to site" />;
}

export function DocumentLink({ document }: { document: GetDocumentQuery["document"] | undefined }) {
  const site = useSiteData();
  if (!document?.published) return null;
  const { path } = document.published;

  const url = `https://${site.urls[0].host}${path}`;

  return (
    <div className={styles.linkContainer}>
      <span className={styles.websiteLink}>
        <ExternalLink to={url}>{url}</ExternalLink>
      </span>
      <CopyButton tracker={"Copied report url"} text={url} iconButton size="xsmall" />
    </div>
  );
}
