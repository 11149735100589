import { Crop } from "react-image-crop";

export interface Transform {
  rotate: number;
  flipH: boolean;
  flipV: boolean;
}

export const imageFromFile = (rawImage: File): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const objectUrl = URL.createObjectURL(rawImage);
    const img = document.createElement("img");
    img.onload = () => {
      resolve(img);
    };
    img.onerror = (err) => {
      reject(err);
      URL.revokeObjectURL(objectUrl);
    };
    img.src = objectUrl;
  });

export function applyTransformations(image: HTMLImageElement, transform: Transform): HTMLCanvasElement {
  const canvas = document.createElement("canvas");
  canvas.width = transform.rotate === 0 || transform.rotate === 180 ? image.width : image.height;
  canvas.height = transform.rotate === 0 || transform.rotate === 180 ? image.height : image.width;
  const ctx = canvas.getContext("2d")!;
  ctx.fillStyle = "#FFFFFF";
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.translate(canvas.width / 2, canvas.height / 2);
  ctx.rotate((transform.rotate * Math.PI) / 180);
  ctx.translate(-image.width / 2, -image.height / 2);
  ctx.scale(transform.flipH ? -1 : 1, transform.flipV ? -1 : 1);
  ctx.translate(transform.flipH ? -image.width : 0, transform.flipV ? -image.height : 0);
  ctx.drawImage(image, 0, 0);
  return canvas;
}

export function createCroppedAndTransformedImage(
  image: HTMLImageElement,
  height: number | undefined,
  width: number | undefined,
  crop: Crop,
  transform: Transform,
): Promise<Blob> {
  const canvas = document.createElement("canvas");
  const sourceWidth = transform.rotate === 0 || transform.rotate === 180 ? image.width : image.height;
  const sourceHeight = transform.rotate === 0 || transform.rotate === 180 ? image.height : image.width;
  const outputWidth = width ?? sourceWidth * (crop.width! / 100);
  const outputHeight = height ?? sourceHeight * (crop.height! / 100);
  canvas.width = outputWidth;
  canvas.height = outputHeight;
  const ctx = canvas.getContext("2d")!;
  ctx.drawImage(
    applyTransformations(image, transform),
    (crop.x! / 100) * sourceWidth,
    (crop.y! / 100) * sourceHeight,
    (crop.width! / 100) * sourceWidth,
    (crop.height! / 100) * sourceHeight,
    0,
    0,
    outputWidth,
    outputHeight,
  );
  return new Promise<Blob>((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (!blob) {
          reject();
          return;
        }
        resolve(blob);
      },
      "image/jpeg",
      0.9,
    );
  });
}
