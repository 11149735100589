import { format } from "date-fns";
import mixpanel from "mixpanel-browser";
import React, { useCallback, useMemo, useRef } from "react";

import { DateRange, ReportSearch } from "../../../../../__generated__/graphql";
import { STATUS_NAMES } from "../../../../../shared/translation/untranslated";
import { isViewFiltered } from "../../../hooks/filtering/helpers";
import { FilterSettings } from "../../../hooks/filtering/useFilterFromUrlParams";
import { OrgDataQuestion } from "../../../hooks/useOrg/types";
import useOrgData from "../../../hooks/useOrgData";
import useReportFields from "../../../hooks/useReportFields";
import Button from "../Button";
import Chip, { ChipRow } from "../Chip";
import Tooltip from "../Tooltip";
import "./styles.scss";

export default function FilterChips({
  reports,
  className,
  settings: { filter, defaults, setFilter, clearFilters, forbiddenFilters },
}: {
  reports?: number;
  className?: string;
  settings: FilterSettings;
}) {
  const { teams, outcomes, sites } = useOrgData();
  const orderedFilters = useReportFields();
  const viewIsFiltered = useMemo(() => isViewFiltered(filter, defaults), [filter, defaults]);
  const forms = useMemo(() => sites.flatMap((site) => site.forms), [sites]);
  const users = useMemo(() => teams.flatMap((site) => site.members), [teams]);

  if (!viewIsFiltered) return null;

  return (
    <div className={`filter-chips ${className ?? ""}`}>
      {reports === undefined ? null : (
        <span className="filter-chips__label">{`Showing ${reports} ${reports === 1 ? "report" : "reports"}`}</span>
      )}
      <ChipRow className="filter-chips__chips">
        {viewIsFiltered.excludeSpam && <SpamExcludedChip onRemove={setFilter} filter={filter} />}
        {
          // Loop over columns to make sure the order is consistent between renders and with other components
          orderedFilters.map(({ type, name, question }) => {
            switch (type) {
              case "createdAt":
                return (
                  viewIsFiltered.createdAt &&
                  !forbiddenFilters.createdAt && (
                    <DateRangeChip
                      name={name}
                      key={name}
                      value={filter.createdAt}
                      onRemove={setFilter}
                      type={type}
                      filter={filter}
                    />
                  )
                );
              case "form":
                return (
                  viewIsFiltered.form &&
                  !forbiddenFilters.form && (
                    <MultipleSelectChip
                      name={name}
                      key={type}
                      values={filter.form?.map((form) =>
                        form ? forms.find(({ id }) => id === form)?.name ?? form : "Open",
                      )}
                      onRemove={setFilter}
                      type={type}
                      filter={filter}
                    />
                  )
                );
              case "status":
                return (
                  viewIsFiltered.status &&
                  !forbiddenFilters.status && (
                    <MultipleSelectChip
                      name={name}
                      key={name}
                      values={filter.status?.map((status) => STATUS_NAMES[status] ?? status)}
                      onRemove={setFilter}
                      type={type}
                      filter={filter}
                    />
                  )
                );
              case "assignedTo":
                return (
                  viewIsFiltered.assignedTo &&
                  !forbiddenFilters.assignedTo && (
                    <MultipleSelectChip
                      name={name}
                      key={name}
                      values={
                        filter.assignedTo?.map(
                          (id) =>
                            teams.find((team) => team.id === id)?.name ??
                            users.find((user) => user.id === id)?.name ??
                            id,
                        ) ?? []
                      }
                      onRemove={setFilter}
                      type={type}
                      filter={filter}
                    />
                  )
                );
              case "outcome":
                return (
                  viewIsFiltered.outcome &&
                  !forbiddenFilters.outcome && (
                    <MultipleSelectChip
                      name={name}
                      key={name}
                      values={filter.outcome?.map((outcome) =>
                        outcome ? outcomes.find(({ id }) => id === outcome)?.name ?? outcome : "Open",
                      )}
                      onRemove={setFilter}
                      type="outcome"
                      filter={filter}
                    />
                  )
                );
              case "updatedAt":
                return (
                  viewIsFiltered.updatedAt &&
                  !forbiddenFilters.updatedAt && (
                    <DateRangeChip
                      name={name}
                      key={name}
                      value={filter.updatedAt}
                      onRemove={setFilter}
                      type={type}
                      filter={filter}
                    />
                  )
                );
              case "question": {
                return (
                  viewIsFiltered.questions &&
                  viewIsFiltered.questions[question?.id ?? ""] &&
                  !(forbiddenFilters.questions && forbiddenFilters.questions[question?.id ?? ""]) && (
                    <MultipleSelectChip
                      name={name}
                      key={name}
                      values={filter.questions
                        ?.find(({ questionId }) => questionId === question?.id)
                        ?.value.map(getOption(question))}
                      onRemove={setFilter}
                      type={type}
                      filter={filter}
                      questionId={question?.id}
                    />
                  )
                );
              }
              default:
                return null;
            }
          })
        }
        <Button variant="secondary" onClick={clearFilters} size="small" icon="xmark" iconSide="right">
          Reset filters
        </Button>
      </ChipRow>
    </div>
  );
}

const getOption = (question?: OrgDataQuestion) => (id: string) => {
  if (!question) return id;
  for (const group of question.optionGroups ?? []) {
    for (const option of group.options) {
      if (option.id === id) {
        return option.value;
      }
    }
  }
  for (const option of question.deletedOptions ?? []) {
    if (option.id === id) {
      return option.value;
    }
  }
  return id;
};

function MultipleSelectChip({
  name,
  values,
  onRemove,
  filter,
  type,
  questionId,
}: {
  name: string;
  values: string[] | null | undefined;
  onRemove: (search: ReportSearch) => void;
  filter: ReportSearch;
  type: keyof ReportSearch | "question";
  questionId?: string;
}) {
  const handleOnRemove = useCallback(() => {
    mixpanel.track("Removed a filter chip", { chip: name });
    const newFilter = { ...filter };
    if (type === "question") {
      newFilter.questions = newFilter.questions?.filter((filter) => filter.questionId !== questionId);
    } else {
      delete newFilter[type];
    }
    onRemove(newFilter);
  }, [name, filter, type, onRemove, questionId]);

  const ref = useRef<HTMLElement>(null);

  if (!values || values.length < 1) return null;

  if (values.length === 1) {
    return (
      <Chip removeButtonLabel="Clear filter" onRemove={handleOnRemove} ref={ref}>
        {name}: {values[0]}
      </Chip>
    );
  }

  return (
    <>
      <Chip removeButtonLabel="Clear filter" onRemove={handleOnRemove} ref={ref}>
        {name}: {values.length} options
      </Chip>
      <Tooltip anchorRef={ref}>
        {values.map((v, i) => (
          <p key={i}>{v}</p>
        ))}
      </Tooltip>
    </>
  );
}

function SpamExcludedChip({ onRemove, filter }: { onRemove: (search: ReportSearch) => void; filter: ReportSearch }) {
  const handleOnRemove = useCallback(() => {
    mixpanel.track("Removed a filter chip", { chip: "Exclude spam" });
    onRemove({ ...filter, excludeSpam: false });
  }, [filter, onRemove]);

  if (!filter.excludeSpam) return null;

  return (
    <Chip removeButtonLabel="Remove filter" onRemove={handleOnRemove}>
      Spam reports hidden
    </Chip>
  );
}

function DateRangeChip({
  name,
  value,
  onRemove,
  filter,
  type,
}: {
  name: string;
  value: DateRange | undefined | null;
  onRemove: (search: ReportSearch) => void;
  filter: ReportSearch;
  type: keyof ReportSearch;
}) {
  const handleOnRemove = useCallback(() => {
    mixpanel.track("Removed a filter chip", { chip: name });
    const newFilter = { ...filter };
    delete newFilter[type];
    onRemove(newFilter);
  }, [name, filter, type, onRemove]);

  if (!value) return null;

  const { start, end } = value;

  if (!start && !end) return null;

  return (
    <Chip removeButtonLabel="Clear filter" onRemove={handleOnRemove}>
      {name}:{" "}
      {start && end
        ? `${formatDate(start)} – ${formatDate(end)}`
        : start
        ? `After ${formatDate(start)}`
        : `Before ${formatDate(end!)}`}
    </Chip>
  );
}

function formatDate(date: string) {
  return format(new Date(date), "d LLL, y");
}
