import { EditorThemeClasses, Klass, LexicalEditor, LexicalNode } from "lexical";
import { createContext, useState, useMemo } from "react";
import * as React from "react";

export type NestedContextShape = {
  nestedEditorConfig: null | NestedEditorConfig;
  nestedEditorPlugins: null | JSX.Element | Array<JSX.Element>;
  set: (
    nestedEditorConfig: null | NestedEditorConfig,
    nestedEditorPlugins: null | JSX.Element | Array<JSX.Element>,
  ) => void;
};

export type NestedEditorConfig = Readonly<{
  namespace: string;
  nodes?: ReadonlyArray<Klass<LexicalNode>>;
  onError: (error: Error, editor: LexicalEditor) => void;
  readOnly?: boolean;
  theme?: EditorThemeClasses;
}>;
// @ts-ignore
export const NestedContext: React.Context<NestedContextShape> = createContext({
  nestedEditorConfig: null,
  nestedEditorPlugins: null,
  set: () => {
    // Empty
  },
});

export function NestedEditorContext({ children }: { children: JSX.Element }) {
  const [contextValue, setContextValue] = useState<{
    nestedEditorConfig: null | NestedEditorConfig;
    nestedEditorPlugins: null | JSX.Element | Array<JSX.Element>;
  }>({
    nestedEditorConfig: null,
    nestedEditorPlugins: null,
  });
  return (
    <NestedContext.Provider
      value={useMemo(
        () => ({
          nestedEditorConfig: contextValue.nestedEditorConfig,
          nestedEditorPlugins: contextValue.nestedEditorPlugins,
          set: (nestedEditorConfig, nestedEditorPlugins) => {
            setContextValue({ nestedEditorConfig, nestedEditorPlugins });
          },
        }),
        [contextValue.nestedEditorConfig, contextValue.nestedEditorPlugins],
      )}
    >
      {children}
    </NestedContext.Provider>
  );
}
