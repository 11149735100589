import { add, endOfMonth, formatISO, parseISO, startOfDay } from "date-fns";
import mixpanel from "mixpanel-browser";
import { useEffect, useMemo } from "react";

import { FormCategory, ReportSearch } from "../../../../../__generated__/graphql";
import { OverTimeData } from "../../../../../shared/components/charts/types";
import { mixpanelFilterSummary } from "../../../hooks/filtering/helpers";
import { FilterSettings } from "../../../hooks/filtering/useFilterFromUrlParams";
import useOrgData from "../../../hooks/useOrgData";
import useAnalyticsQuery, { AnalyticsQueryProps } from "../useAnalyticsQuery";
import { OverTimeYearSettings } from "./useUrlYearSettings";

export type LoadingData<T> =
  | { loading: true; error: Error | null; data: T | null }
  | { loading: boolean; error: Error; data: null }
  | { loading: boolean; error: Error | null; data: T };

export default function useOverTimeQuery(
  { year, comparisonYear, month, duration: durationString }: OverTimeYearSettings,
  { filter, defaults }: FilterSettings,
  skip?: boolean,
): LoadingData<OverTimeData> {
  const { questions, organisation } = useOrgData();
  const duration = parseInt(durationString, 10);

  useEffect(() => {
    mixpanel.track("Made 'over time' query", {
      year,
      compareTo: comparisonYear,
      month,
      ...mixpanelFilterSummary(filter, defaults, questions),
    });
  }, [comparisonYear, defaults, filter, month, questions, year]);

  const queries = useMemo(() => {
    const queries: AnalyticsQueryProps[] = [];
    addYearToQuery(queries, organisation.id, filter, year, month, duration);
    if (comparisonYear) addYearToQuery(queries, organisation.id, filter, comparisonYear, month, duration);
    return queries;
  }, [organisation.id, filter, year, month, comparisonYear, duration]);
  const { loading, data, error } = useAnalyticsQuery(queries, skip);

  return useMemo((): LoadingData<OverTimeData> => {
    if (!data) {
      if (loading) {
        return { loading, data: null, error };
      }
      return {
        loading: false,
        data: null,
        error: error ?? new Error("Unknown error"),
      };
    }

    const series: OverTimeData = { years: [{ months: [] }] };
    if (comparisonYear) series.years.push({ months: [] });

    for (let seriesMonth = 0; seriesMonth < duration; ++seriesMonth) {
      series.years[0].months.push({ count: data[seriesMonth]?.data[0].value ?? 0 });
      if (comparisonYear) series.years[1].months.push({ count: data[seriesMonth + duration]?.data[0].value ?? 0 });
    }

    return { loading, data: series, error };
  }, [comparisonYear, data, error, loading, duration]);
}

function addYearToQuery(
  queries: AnalyticsQueryProps[],
  organisationId: string,
  filter: ReportSearch,
  year: string,
  month: string,
  duration: number,
) {
  const startDate = parseISO(`${year}-${month}-01`);

  for (let months = 0; months < duration; ++months) {
    const date = startOfDay(add(startDate, { months }));
    queries.push({
      organisationId,
      search: {
        ...filter,
        category: FormCategory.Reporting,
        createdAt: {
          start: formatISO(date),
          end: formatISO(endOfMonth(date)),
        },
      },
    });
  }
}
