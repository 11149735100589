import classNames from "classnames";
import React, { MouseEventHandler, useCallback } from "react";
import { NavLink } from "react-router-dom";

import "./styles.scss";

export interface TabItem {
  label: string;
  link?: string;
  isActive?: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

export interface TabBarProps {
  items: TabItem[];
  sticky?: boolean;
  className?: string;
}

/** @deprecated Tabbar needs redesigning so don't use this until then */
function TabBar({ sticky, items, className }: TabBarProps) {
  const classes = classNames({ tabbar: true, "tabbar--sticky": sticky });

  return (
    <div className={`${classes} ${className ? className : ""}`}>
      <ul className="tabbar__list">
        {items.map((item) => (
          <Tab key={item.label} item={item} />
        ))}
      </ul>
    </div>
  );
}

function Tab({ item: { label, isActive, link, onClick } }: { item: TabItem }) {
  const clickHandler = useCallback<MouseEventHandler<HTMLAnchorElement>>(
    (e) => {
      if (!link) e.preventDefault();
      onClick?.(e);
    },
    [link, onClick],
  );

  return (
    <li key={label} className={`tabbar__item`}>
      <NavLink
        className={({ isActive: domIsActive }) => `tabbar__link ${isActive ?? domIsActive ? "is-active" : ""}`}
        to={link ?? "#"}
        onClick={clickHandler}
        aria-current={isActive ?? true}
      >
        {label}
      </NavLink>
    </li>
  );
}

export default TabBar;
