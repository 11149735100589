import { SearchOptions } from "minisearch";
import React, { ChangeEvent, KeyboardEvent, useCallback, useEffect, useState } from "react";

import TextInput from "../../../../../shared/components/design-system/TextInput";
import { SearchableList } from "./index";
import "./styles.scss";

interface Item {
  label: string;
  id: string;
}

export default function SearchableSelect<T extends Item>({
  label,
  loading,
  items,
  fields = ["label"],
  value,
  onSelect: setValue,
  className,
  searchOptions,
  required,
  autoFocus,
}: {
  label?: string;
  loading?: boolean;
  className?: string;
  items: T[];
  fields?: Array<keyof T>;
  value: string | null;
  onSelect: (value: string | null) => void;
  searchOptions?: SearchOptions & { initialsMatch?: boolean };
  required?: string;
  autoFocus?: boolean;
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [visibleItems, setVisibleItems] = useState<string[]>([]);
  const [highlight, setHighlight] = useState<string | null>(null);
  const [isInvalid, setIsInvalid] = useState(!!required);

  // When value is set, update the search box to show its label (unless the user is still typing).
  useEffect(() => {
    if (value) {
      const item = items.find((item) => item.id === value);
      setSearchTerm(item ? item.label : "");
    }
  }, [items, value]);

  // A wrapper for setVisibleItems that also updates the highlight.
  const updateVisibleItems = useCallback(
    (items: string[]) => {
      setVisibleItems(items);
      if (!highlight || !items.includes(highlight)) {
        setHighlight(items[0] ?? null);
      }
    },
    [highlight],
  );

  // Update the validity information when the value changes.
  useEffect(() => {
    if (required) setIsInvalid(value === null);
  }, [required, value]);

  // Handler for user input in the text box.
  const changeSearchBox = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(ev.target.value);
      setOpen(true);
      if (value !== null) setValue(null);
    },
    [setValue, value],
  );

  // Close the menu and commit the highlighted option (if there is one) on TAB.
  const onBlur = useCallback(() => {
    const item = items.find((item) => item.id === highlight);
    if (item) setValue(item?.id ?? null);
    setOpen(false);
  }, [highlight, items, setValue]);

  // Handler for clicking an item in the dropdown
  const onClickItem = (item: Item) => () => {
    setOpen(false);
    setValue(item.id);
  };

  // Handler for arrow-key nav.
  const onKeyDown = useCallback(
    (ev: KeyboardEvent<HTMLInputElement>) => {
      const i = highlight ? visibleItems.indexOf(highlight) : -1;
      switch (ev.key) {
        case "ArrowDown":
          ev.preventDefault();
          setHighlight(visibleItems[(i + 1) % visibleItems.length]);
          break;
        case "ArrowUp":
          ev.preventDefault();
          setHighlight(visibleItems[i > 0 ? i - 1 : visibleItems.length - 1]);
          break;
        case "Enter":
          ev.preventDefault();
          setOpen(false);
          setValue(items.find((item) => item.id === highlight)?.id ?? null);
          break;
        case "Escape":
          ev.preventDefault();
          setOpen(false);
          break;
      }
    },
    [highlight, items, setValue, visibleItems],
  );

  return (
    <div className={`searchable-select-menu select-menu ${searchTerm ? "is-open" : ""} ${className}`}>
      <TextInput
        type="text"
        label={label}
        placeholder=""
        autoFocus={autoFocus}
        required={!!required}
        value={loading ? "Loading options..." : searchTerm}
        onChange={changeSearchBox}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        customValidity={isInvalid ? required : undefined}
        disabled={loading}
      />
      {open ? (
        <div className="select-menu__menu searchable-select-menu__menu">
          <ul className="select-menu__menu-list">
            {loading ? (
              <li className="select-menu__item select-menu__null-item">Loading...</li>
            ) : (
              <SearchableList
                items={items}
                fields={fields}
                searchOptions={searchOptions}
                searchText={searchTerm}
                emptyWithNoSearchTerm
                component={(item) => (
                  <li
                    className={`select-menu__item ${highlight === item.id ? "is-highlighted" : ""}`}
                    onClick={onClickItem(item)}
                    onMouseMove={() => setHighlight(item.id)}
                  >
                    {item.label}
                  </li>
                )}
                emptyComponent={<li className="select-menu__item select-menu__null-item">No items found</li>}
                onFilterUpdate={updateVisibleItems}
              />
            )}
          </ul>
        </div>
      ) : null}
    </div>
  );
}
