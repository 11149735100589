import mixpanel from "mixpanel-browser";
import React, { useEffect } from "react";

import { AnalyticsCode, QuestionType } from "../../../../../../__generated__/graphql";
import { NO_TITLE } from "../../../../../../shared/components/charts/ChartLegend";
import "../../../../../../shared/components/charts/ChartLegend/styles.css";
import InDetailBarChart from "../../../../../../shared/components/charts/InDetailBarChart";
import "../../../../../../shared/components/charts/InDetailBarChart/styles.css";
import InDetailTable, { GetLinkFunction } from "../../../../../../shared/components/charts/InDetailTable";
import { InDetailData } from "../../../../../../shared/components/charts/types";
import { useTranslate } from "../../../../../../shared/components/translation";
import { importTranslationsAsync } from "../../../../../../shared/translation/initialisation";
import MagnifyingGlass from "../../../../assets/images/EmptyStateAndUpsell/magnifying-glass.svg";
import { OrgDataQuestion } from "../../../../hooks/useOrg/types";
import useOrgData from "../../../../hooks/useOrgData";
import { TimePeriod } from "../../../../timePeriods";
import EmptyStateBanner from "../../../design-system/EmptyStateBanner";
import tableStyles from "../../tables.module.scss";
import { ChartTypeSettings, InDetailChartSettings } from "../chart-options";
import { SplitSettings } from "../useUrlSplitDimension";
import english from "./translations/en-GB.json";

void importTranslationsAsync("AnalyticsInDetail", { "en-GB": english });

export default function AnalysisChartPanel({
  firstSplit,
  secondSplit,
  timePeriod,
  chartTypeSettings: { chartType },
  chartSettings,
  data,
  getLink,
}: {
  firstSplit: SplitSettings;
  secondSplit: SplitSettings;
  timePeriod: TimePeriod;
  chartTypeSettings: ChartTypeSettings;
  chartSettings: InDetailChartSettings;
  data: InDetailData;
  getLink?: GetLinkFunction;
}) {
  const orgData = useOrgData();
  const translate = useTranslate();

  // TODO: this feels like a legacy thing, check if it is
  useEffect(() => {
    mixpanel.track("Selected in detail chart", { chart: firstSplit.analyticsCode });
  }, [firstSplit.analyticsCode]);

  if (data.categories.length === 0) {
    return (
      <EmptyStateBanner
        direction="vertical"
        title={translate("AnalyticsInDetail:noDataTitle")}
        body={translate("AnalyticsInDetail:noDataBody")}
        graphic={MagnifyingGlass}
      />
    );
  }

  const showMultipleAnswerWarning =
    hasMultipleAnswers(orgData.questions, firstSplit) || hasMultipleAnswers(orgData.questions, secondSplit);

  if (chartType === "TABLE") {
    return (
      <div className={tableStyles.Container}>
        <InDetailTable
          data={data}
          options={chartSettings.chartOptions}
          getLink={getLink}
          className={`${tableStyles.Table} ${chartSettings.chartOptions.showHeatMap ? tableStyles.Heatmap : ""}`}
        />
      </div>
    );
  }

  return (
    <InDetailBarChart
      data={data}
      options={chartSettings.chartOptions}
      legendTitle={chartSettings.legendTitle ?? NO_TITLE}
      showMultipleAnswerWarning={showMultipleAnswerWarning}
      getLink={getLink}
    />
  );
}

export function hasMultipleAnswers(
  questions: OrgDataQuestion[],
  split: Pick<SplitSettings, "analyticsCode" | "questionId">,
) {
  switch (split.analyticsCode) {
    case null:
      return false;
    case AnalyticsCode.Outcome:
      return true;
    case AnalyticsCode.Form:
    case AnalyticsCode.Status:
      return false;
    default: {
      const matchingQuestions = questions.filter((q) =>
        // @ts-ignore — AnalyticsCode and QuestionAnalyticsCode are basically the same thing but Apollo and TypeScript do not handle it well together
        split.analyticsCode ? q.analyticsCode === split.analyticsCode : q.id === split.questionId,
      );
      if (matchingQuestions.length === 0) return false;
      if (matchingQuestions.length > 1) return true;
      if (matchingQuestions[0].type === QuestionType.Checklist) return true;
      return false;
    }
  }
}
