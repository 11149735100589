import React from "../../../../../shared/block-editor-data/react";
import useAsyncFunctionWithLoading from "../../../hooks/useAsyncFunctionWithLoading";
import ConfirmDeleteButton from "../../design-system/Button/specifics/ConfirmDeleteButton";
import { usePersistence } from "./persistence";

export default function DeleteButton() {
  const { savedDocument: doc, del, loading } = usePersistence();
  const [wrappedDelete, deleting] = useAsyncFunctionWithLoading(del);

  if (!doc) return null;

  return (
    <ConfirmDeleteButton
      title="Delete document?"
      variant="action"
      track="Insight reports"
      loading={deleting}
      disabled={loading}
      type="button"
      onConfirm={wrappedDelete}
      confirmBody="This will permanently delete this document, including any published version on your reporting site or
    private link."
      confirmText="Delete"
      icon="trashXmark"
    >
      Delete
    </ConfirmDeleteButton>
  );
}
