import React from "react";

import Alert from "../../../../../shared/components/design-system/Alert";
import { ExternalLink } from "../../../../../shared/components/design-system/Button/Link";
import { TextElement, Translation } from "../../../../../shared/components/translation";
import { importTranslationsBackground } from "../../../../../shared/translation/initialisation";
import { CopyableText } from "../Button/specifics/CopyButton";
import english from "./translations/en-GB.json";

importTranslationsBackground("ExportDownloads", { "en-GB": english });

export function ExportDownloads({
  password,
  showZipInstructions,
}: {
  password: string;
  showZipInstructions?: boolean;
}) {
  return (
    <>
      <TextElement tag="p" t="ExportDownloads:prePassword" />
      <CopyableText text={password} className="ds-hstack" />
      <Alert variant="warning" className="ds-mt-5" message="ExportDownloads:alertText" />
      {showZipInstructions ? (
        <p className="ds-mt-5">
          <Translation t="ExportDownloads:zip.preLink" />
          <ExternalLink to="https://www.7-zip.org/" label="ExportDownloads:zip.link" />
          <Translation t="ExportDownloads:zip.postLink" />
          <ExternalLink
            to="https://drive.google.com/file/d/1d9gRVA6l6dr3Bk875M3J235r6-q5WK0_/view"
            label="ExportDownloads:zip.video"
          />
        </p>
      ) : null}
    </>
  );
}
