import { useMutation } from "@apollo/client";
import mixpanel from "mixpanel-browser";
import React, { RefObject, createContext, useCallback, useContext, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  CreateDocumentMutation,
  CreateDocumentMutationVariables,
  DeleteDocumentMutation,
  DeleteDocumentMutationVariables,
  DocumentType,
  GetDocumentQuery,
  PublicationMode,
  PublishDocumentMutation,
  PublishDocumentMutationVariables,
  UnpublishDocumentMutation,
  UnpublishDocumentMutationVariables,
  UpdateDocumentMutation,
  UpdateDocumentMutationVariables,
} from "../../../../../__generated__/graphql";
import { EmptyDocument, RichTextDocument } from "../../../../../shared/block-editor-data/types";
import { useToast } from "../../../../../shared/components/design-system/Toaster/context";
import { useTranslate } from "../../../../../shared/components/translation";
import { URL_PARTS } from "../../../../../shared/db/documents";
import { importTranslationsBackground } from "../../../../../shared/translation/initialisation";
import useOrgData from "../../../hooks/useOrgData";
import useWarnAboutUnsavedChanges from "../../../hooks/useWarnAboutUnsavedChanges";
import useSiteData from "../useSiteData";
import { bestPracticeContents } from "./Contents";
import InsightsReportBuilder from "./InsightsReportBuilder";
import InsightsReportExternalLink from "./InsightsReportExternalLink";
import { CREATE_DOCUMENT, DELETE_DOCUMENT, PUBLISH_DOCUMENT, UNPUBLISH_DOCUMENT, UPDATE_DOCUMENT } from "./queries";
import englishTranslations from "./translations/en-GB.json";

export type ToastKey = keyof typeof englishTranslations;

importTranslationsBackground("documentEditorToasts", { "en-GB": englishTranslations });

type Document = GetDocumentQuery["document"];

export interface DocumentVariables {
  savedDocument?: Document;
  documentType: DocumentType;
}

interface Payload extends DocumentVariables {
  name: string;
  contents: RichTextDocument;
  setName: (name: string) => void;
  setContents: (contents: RichTextDocument) => void;
  externalLinkUrl: string | null;
  setExternalLinkUrl: (externalLinkUrl: string | null) => void;
  isDirty: boolean;
  hasUnpublishedChanges: boolean;
  loading: boolean;
  save: (options?: { makePreviewable?: boolean }, toastKey?: ToastKey) => Promise<Document | null>;
  saveFormRef: RefObject<HTMLFormElement>;
  publish: (mode: PublicationMode, toastKey?: ToastKey) => Promise<void>;
  unpublish: (toastKey?: ToastKey) => Promise<void>;
  del: (toastKey?: ToastKey) => Promise<void>;
  checkDocumentHasName: () => boolean;
}

// @ts-ignore
const context = createContext<Payload>();

export function usePersistence() {
  return useContext(context);
}

export function DocumentEditorWithPersistence({
  savedDocument: doc,
  documentType,
  template,
  externalLink,
  onClose,
}: DocumentVariables & {
  template?: string | null;
  externalLink?: boolean;
  onClose?: () => void;
}) {
  const navigate = useNavigate();
  const toast = useToast();

  const site = useSiteData();
  const {
    organisation: { id: organisationId },
    isCrossOrg,
  } = useOrgData();

  const saveFormRef = useRef<HTMLFormElement>(null);

  const [name, setName] = useState(doc?.name ?? "");
  const [externalLinkUrl, setExternalLinkUrl] = useState(doc?.externalLinkUrl ?? null);
  const [contents, setContents] = useState(
    doc?.currentDraft?.body
      ? JSON.parse(doc.currentDraft.body)
      : template === "bestPractice"
      ? bestPracticeContents
      : EmptyDocument,
  );

  const { markClean, isDirty } = useWarnAboutUnsavedChanges({ name, contents });
  const [createDocument, createRequest] = useMutation<CreateDocumentMutation, CreateDocumentMutationVariables>(
    CREATE_DOCUMENT,
  );
  const [updateDocument, updateRequest] = useMutation<UpdateDocumentMutation, UpdateDocumentMutationVariables>(
    UPDATE_DOCUMENT,
  );
  const [publish, publishRequest] = useMutation<PublishDocumentMutation, PublishDocumentMutationVariables>(
    PUBLISH_DOCUMENT,
  );
  const [unpublish, unpublishRequest] = useMutation<UnpublishDocumentMutation, UnpublishDocumentMutationVariables>(
    UNPUBLISH_DOCUMENT,
  );
  const [del, deleteRequest] = useMutation<DeleteDocumentMutation, DeleteDocumentMutationVariables>(DELETE_DOCUMENT, {
    // @ts-ignore — just don't call this without a document
    variables: { id: doc?.id },
  });

  const save = useCallback(
    async ({
      force,
      makePreviewable,
      then,
    }: {
      force?: boolean;
      makePreviewable?: boolean;
      then?: (document: Document) => Promise<unknown>;
    } = {}): Promise<Document> => {
      if (doc) {
        let returnValue: Document = doc;
        if (isDirty || force) {
          mixpanel.track(externalLinkUrl ? "Saved link" : "Saved document", {
            documentType,
            saveType: "update",
            previewable: makePreviewable ?? false,
          });
          returnValue = (
            await updateDocument({
              variables: {
                documentId: doc.id,
                name: name,
                body: JSON.stringify(contents),
                makePreviewable: !!makePreviewable,
                updatePublishedVersion: doc.published?.publicationMode === PublicationMode.PrivateLink,
                externalLinkUrl,
              },
            })
          ).data!.updateDocument;
        }
        await then?.(returnValue);
        markClean();
        if (externalLinkUrl && onClose) {
          onClose();
        }
        return returnValue;
      }

      mixpanel.track(externalLinkUrl ? "Saved link" : "Saved document", {
        documentType,
        saveType: "create",
        previewable: makePreviewable ?? false,
      });
      const returnValue = (
        await createDocument({
          variables: {
            siteId: site.id,
            name: name,
            documentType: documentType,
            body: JSON.stringify(contents),
            makePreviewable: !!makePreviewable,
            externalLinkUrl,
          },
        })
      ).data!.createDocument;
      await then?.(returnValue);
      markClean();
      if (externalLinkUrl && onClose) {
        onClose();
      } else {
        navigate(
          `${isCrossOrg ? `/organisations/${organisationId}` : ""}/sites/${site.id}/${URL_PARTS[documentType]}/${
            returnValue.id
          }`,
        );
      }
      return returnValue;
    },
    [
      doc,
      externalLinkUrl,
      documentType,
      createDocument,
      site.id,
      name,
      contents,
      markClean,
      onClose,
      isDirty,
      updateDocument,
      navigate,
      isCrossOrg,
      organisationId,
    ],
  );

  const loading =
    createRequest.loading ||
    updateRequest.loading ||
    publishRequest.loading ||
    unpublishRequest.loading ||
    deleteRequest.loading;

  const translate = useTranslate();
  const toastSuccess = useCallback(
    (toastKey: ToastKey) => {
      const title = translate(`documentEditorToasts:${toastKey}.${documentType}.success`);
      const body = translate(`documentEditorToasts:${toastKey}.${documentType}.successBody`);
      if (body === `${toastKey}.${documentType}.successBody`) {
        // This means no translation existed
        toast.success(title);
      } else {
        toast.success(body, { title });
      }
    },
    [documentType, toast, translate],
  );
  const toastError = useCallback(
    (toastKey: ToastKey, error: unknown) => {
      toast.error(error, { title: `documentEditorToasts:${toastKey}.${documentType}.error` });
    },
    [documentType, toast],
  );

  const payload = useMemo<Payload>(
    () => ({
      savedDocument: doc,
      documentType,

      name,
      setName,
      contents,
      setContents,
      saveFormRef,

      isDirty,
      hasUnpublishedChanges: !!doc?.published && doc.currentDraft?.id !== doc.published.publishedVersion?.id,
      loading,
      externalLinkUrl,
      setExternalLinkUrl,

      checkDocumentHasName() {
        return saveFormRef.current!.checkValidity();
      },

      async save(
        options: { makePreviewable?: boolean; updatePublishedVersion?: boolean } = {},
        toastKey: ToastKey = options.updatePublishedVersion ? "savedWithPrivateLink" : "saved",
      ) {
        try {
          const document = await save({ force: true, ...options });
          toastSuccess(toastKey);
          return document;
        } catch (e) {
          toastError(toastKey, e);
          return null;
        }
      },

      async publish(
        mode: PublicationMode,
        toastKey: ToastKey = mode === PublicationMode.PrivateLink ? "privateLinkCreated" : "published",
      ) {
        try {
          mixpanel.track("Published document", {
            documentType,
            mode,
            toastKey,
          });
          await save({ then: ({ id }) => publish({ variables: { id, mode } }) });
          toastSuccess(toastKey);
        } catch (e) {
          toastError(toastKey, e);
        }
      },

      async unpublish(toastKey: ToastKey = "unpublished") {
        try {
          mixpanel.track("Unpublished document", { documentType, toastKey });
          await save({ then: ({ id }) => unpublish({ variables: { id } }) });
          toastSuccess(toastKey);
        } catch (e) {
          toastError(toastKey, e);
        }
      },

      async del(toastKey: ToastKey = "deleted") {
        try {
          markClean();
          mixpanel.track(externalLinkUrl ? "Deleted Link" : "Deleted document", { documentType, toastKey });
          await del();
          if (externalLinkUrl && onClose) {
            onClose();
          } else {
            navigate(`${isCrossOrg ? `/organisations/${organisationId}` : ""}/sites/${site.id}/insights`);
          }
          toastSuccess(toastKey);
        } catch (e) {
          toastError(toastKey, e);
        }
      },
    }),
    [
      doc,
      documentType,
      name,
      contents,
      isDirty,
      loading,
      externalLinkUrl,
      save,
      toastSuccess,
      toastError,
      publish,
      unpublish,
      markClean,
      del,
      onClose,
      navigate,
      isCrossOrg,
      organisationId,
      site.id,
    ],
  );

  const handleClose = useCallback(() => {
    onClose && onClose();
    markClean();
  }, [markClean, onClose]);
  if (externalLink && onClose) {
    return doc ? (
      <context.Provider value={payload}>
        <InsightsReportExternalLink onClose={handleClose} />
      </context.Provider>
    ) : (
      <context.Provider value={payload}>
        <InsightsReportExternalLink onClose={onClose} />
      </context.Provider>
    );
  }

  return (
    <context.Provider value={payload}>
      <InsightsReportBuilder />
    </context.Provider>
  );
}
